import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { colors } from '../../utils/theme';
import IdentityStatus from '../Common/IdentityStatus';
import { radius } from '../Wallet/DepositPage';
import PrimaryButton from '../Common/Buttons/PrimaryButton/PrimaryButton';
import { AppContext } from '../../AppContext';
import { VerificationStatuses } from '../../models/account';
import KycWidget from './KycWidget';
import InlineLoader from '../Common/InlineLoader/InlineLoader';
import { GetVerificationStatusQuery } from '../../queries/profile';
import { handleError } from '../../utils/ui';
import { useQuery, useQueryClient } from 'react-query';
import { Query } from '../../enums/RequestEnums';

const VerificationPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [openKycWidget, setOpenKycWidget] = useState(false);
  const statusQuery = useQuery({
    queryKey: [Query.VerificationStatus],
    queryFn: GetVerificationStatusQuery,
    onError: ({ response }) => handleError(dispatch, response)
  });

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([Query.VerificationStatus]);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', textAlign: 'left', flexFlow: 'column', mb: '10px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            width: { xs: 'auto', md: '430px' },
            background: colors.background.blue,
            border: `2px solid ${colors.info.main}`,
            padding: '14px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: radius
          }}
        >
          <Box sx={{ flexGrow: 1, fontSize: '16px', fontWeight: 500, lineHeight: '22px' }}>
            {t('myAccount.verificationPage.statusTitle')}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {statusQuery.isLoading || statusQuery.isFetching ? (
              <InlineLoader />
            ) : (
              <IdentityStatus status={statusQuery.data?.data as VerificationStatuses} />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: { xs: '32px', md: '48px' },
          background: colors.background.blue,
          borderRadius: radius,
          padding: { xs: '24px', md: '56px' }
        }}
      >
        <Box sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '34px' }}>
          {t('myAccount.verificationPage.title')}
        </Box>
        <Box sx={{ marginTop: '4px', fontSize: '16px', lineHeight: '22px' }}>
          {t('myAccount.verificationPage.description')}
        </Box>
      </Box>
      <PrimaryButton text="start verification" onClick={() => setOpenKycWidget(true)} />
      {openKycWidget && <KycWidget handleClose={() => setOpenKycWidget(false)} />}
    </Box>
  );
};

export default VerificationPage;
