import React from 'react';
import { Autorenew, GppMaybe, RemoveModerator, VerifiedUser } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { colors } from '../../utils/theme';
import { useTranslation } from 'react-i18next';
import { VerificationStatuses } from '../../models/account';

interface IProps {
  status: VerificationStatuses;
  iconStyle?: SxProps;
  iconBoxStyle?: SxProps;
  textStyle?: SxProps;
  wrapperStyle?: SxProps;
}

const IdentityStatus: React.FunctionComponent<IProps> = ({
  status,
  iconStyle,
  iconBoxStyle,
  textStyle,
  wrapperStyle
}: IProps) => {
  const { t } = useTranslation();
  const getColor = () => {
    if (status === VerificationStatuses.Verified) {
      return colors.statuses.success;
    } else if (status === VerificationStatuses.Declined) {
      return colors.statuses.failed;
    } else {
      return colors.statuses.pending;
    }
  };

  const getIcon = () => {
    const iconStyleProps: SxProps = {
      color: getColor(),
      ...iconStyle
    };

    if (status === VerificationStatuses.Verified) {
      return <VerifiedUser sx={iconStyleProps} />;
    } else if (status === VerificationStatuses.Declined) {
      return <RemoveModerator sx={iconStyleProps} />;
    } else if (status === VerificationStatuses.Pending) {
      return <Autorenew sx={iconStyleProps} />;
    } else {
      return <GppMaybe sx={iconStyleProps} />;
    }
  };

  const getText = () => {
    return t(`myAccount.verificationPage.statuses.${status}`);
    // if (status === 'verified') {
    //   return t('myAccount.verificationPage.statuses.verified');
    // } else if (status === 'declined') {
    //   return t('myAccount.verificationPage.statuses.declined');
    // } else if (status === 'pending') {
    //   return t('myAccount.verificationPage.statuses.pending');
    // } else {
    //   return t('myAccount.verificationPage.statuses.notVerified');
    // }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        ...wrapperStyle
      }}
    >
      <Box
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '100px',
          color: getColor(),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px solid',
          ...iconBoxStyle
        }}
      >
        {getIcon()}
      </Box>
      <Box sx={{ fontSize: '16px', lineHeight: '22px', ...textStyle }}>{getText()}</Box>
    </Box>
  );
};

export default IdentityStatus;
