import React, { useContext, useEffect, useState } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import bolt from '../../assets/wheel/bolt.svg';
import bomb from '../../assets/wheel/bomb.svg';
import coins from '../../assets/wheel/coins.svg';
import coinsSmall from '../../assets/wheel/coinsSmall.svg';
import gift from '../../assets/wheel/gift.svg';
import giftSmall from '../../assets/wheel/giftSmall.svg';
import money from '../../assets/wheel/money.svg';
import moneySmall from '../../assets/wheel/moneySmall.svg';
import rudder from '../../assets/wheel/rudder.svg';
import rudderSmall from '../../assets/wheel/rudderSmall.svg';
import prize from '../../assets/wheel/prize.svg';
import { AppContext } from '../../AppContext';
import { colors } from '../../utils/theme';
import PrimaryButton from './Buttons/PrimaryButton/PrimaryButton';
import CancelButton from './Buttons/CancelButton';
import { Actions } from '../../enums/ActionEnums';
import { Close } from '@mui/icons-material';
import { Transition } from './Dialogs/DialogBox';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes, PublicRoutes } from '../../enums/RouteEnums';
import { useQuery } from 'react-query';
import { GetWheelSpin, WheelElementsQuery } from '../../queries/wheel';
import Loader from './Loader';
import { WheelElement, WheelElementType, Spin } from '../../models/wheel';
import { handleError } from '../../utils/ui';
import { Query } from '../../enums/RequestEnums';

const duration = 4;
const spins = 5;

const Wheel: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const isSignedIn = !!state.user.token;
  const [items, setItems] = useState<WheelElement[]>([]);
  const [rotation, setRotation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const elementsQuery = useQuery(Query.AllElements, WheelElementsQuery, {
    onSuccess: ({ data }) => {
      setItems(data);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const { data, refetch, isFetching, isFetched } = useQuery(Query.WheelSpin, GetWheelSpin, {
    enabled: false,
    onError: ({ response }) => handleError(response, dispatch)
  });

  useEffect(() => {
    if (data && !isFetching && isFetched) {
      handleSpin({ elementId: data?.data.elementId, spinsLeft: data?.data.spinsLeft });
    }
  }, [data]);

  useEffect(() => {
    if (isFetching) {
      setRotation(true);
    }
  }, [isFetching]);

  const piece = 360 / items.length;
  const getItemTransform = (index: number) => {
    return `rotate(${index * piece - piece / 2}deg) skewY(${piece - 90}deg)`;
  };

  const handleSpinRequest = async () => {
    await refetch();
  };

  const handleSpin = ({ elementId, spinsLeft }: Spin) => {
    if (isSignedIn) {
      if (state.user.data && state.user.data.wheelSpins > 0) {
        const randomItem = elementId;
        const leftWheelSpins = spinsLeft;
        const itemWon = items.filter((item) => item.id === randomItem);
        const isWin = itemWon[0]?.type !== WheelElementType.Lose; //Lose
        const indexOfSelectedItem = items.findIndex((item) => item.id === randomItem);

        setSelectedItem(indexOfSelectedItem);

        dispatch({
          type: Actions.UpdateUserWheelSpins,
          payload: {
            wheelSpins: leftWheelSpins
          }
        });

        if (itemWon[0].type === WheelElementType.LoyaltyPoints) {
          dispatch({
            type: Actions.UpdateUserLoyaltyPoints,
            payload: {
              loyaltyPoints: (state.user.data?.loyaltyPoints ?? 0) + itemWon[0].amount
            }
          });
        }

        setTimeout(() => {
          setRotation(false);
          dispatch({
            type: Actions.ShowPopup,
            payload: {
              isCongrat: isWin,
              state:
                itemWon[0].type !== WheelElementType.Lose
                  ? `${t('wheel.winLabel')} ${itemWon[0].amount} ${getBonusLabel(itemWon[0].type)}`
                  : t('wheel.lossLabel'),
              redirectUrl: itemWon[0].type === WheelElementType.BonusMoney ? AuthRoutes.Bonuses : ''
            }
          });
        }, duration * 1000);
      } else {
        dispatch({
          type: Actions.ShowPopup,
          payload: {
            state: t('wheel.popUpMessage')
          }
        });
      }
    } else {
      dispatch({
        type: Actions.ShowLoginPopup,
        payload: {}
      });
    }
  };

  const getBonusIcon = (type: WheelElementType, isSmall?: boolean) => {
    switch (type) {
      case WheelElementType.FreeSpins:
        return isSmall ? rudderSmall : rudder;
      case WheelElementType.LoyaltyPoints:
        return isSmall ? coinsSmall : coins;
      case WheelElementType.BonusMoney:
        return isSmall ? moneySmall : money;
      case WheelElementType.Lose:
        return bomb;
      case WheelElementType.WheelSpins:
        return isSmall ? giftSmall : gift;
    }
  };

  const getBonusLabel = (type: WheelElementType) => {
    switch (type) {
      case WheelElementType.FreeSpins:
        return t('wheel.bonusTypes.freeSpins');
      case WheelElementType.LoyaltyPoints:
        return t('wheel.bonusTypes.coins');
      case WheelElementType.BonusMoney:
        return t('wheel.bonusTypes.bonusMoney');
      case WheelElementType.Lose:
        return t('wheel.bonusTypes.lose');
      case WheelElementType.WheelSpins:
        return t('wheel.bonusTypes.wheelSpins');
    }
  };

  return (
    <Dialog
      open={state.wheelPopup.open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          margin: 0,
          borderRadius: '8px',
          background: colors.primary.second,
          boxShadow: '0px 10px 30px rgba(3, 30, 68, 0.1)',
          overflowX: 'hidden'
        }
      }}
      sx={{
        '.MuiDialog-container': {
          alignItems: 'center'
        }
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: '14px 24px 14px 24px',
          display: 'flex',
          flexFlow: 'column',
          gap: '24px'
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '27px', right: '27px', padding: 0 }}
          onClick={() => dispatch({ type: Actions.HideWheelPopup })}
          disabled={rotation}
        >
          <Close sx={{ width: '16px', height: '16px', color: colors.grey[50] }} />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            gap: '8px',
            alignItems: 'center',
            color: 'common.white',
            marginBottom: '15px'
          }}
        >
          <Box sx={{ fontSize: '24px', fontWeight: 600 }}>{t('wheel.header')}</Box>
          <Box
            sx={{
              fontSize: '14px',
              paddingX: '32px',
              textAlign: 'center',
              color: colors.grey.placeholder
            }}
          >
            {t('wheel.subHeader')}
          </Box>
          <Box
            sx={{
              fontSize: '14px',
              paddingX: '32px',
              textAlign: 'center',
              color: colors.grey.placeholder
            }}
          >
            {state.user.data?.wheelSpins ?? 0} {t('wheel.spinsLeft')}
          </Box>
        </Box>
        <Box
          sx={{
            background:
              'radial-gradient(50% 50% at 50% 50%, #F27121 89.58%, #E94057 94.27%, #8A2387 100%)',
            padding: '10px',
            borderRadius: '50%',
            position: 'relative',
            width: '330px',
            color: 'common.white'
          }}
        >
          <Box
            component="img"
            src={bolt}
            sx={{
              position: 'absolute',
              top: '-34px',
              left: '-12px',
              width: '354px',
              height: '376px',
              zIndex: 2
            }}
          />

          <Box
            component="ul"
            sx={{
              width: '310px',
              height: '310px',
              position: 'relative',
              padding: 0,
              margin: 0,
              borderRadius: '50%',
              listStyle: 'none',
              overflow: 'hidden',
              transform: `rotate(-${selectedItem * piece}deg)`,
              ...(rotation && {
                transition: `transform ${duration}s`,
                transform: `rotate(calc(${spins} * 360deg + (-360deg * ${selectedItem} / ${items.length})))`
              }),
              webkitBackfaceVisibility: 'hidden',
              MozBackfaceVisibility: 'hidden',
              webkitTransform: 'translate3d(0, 0, 0)',
              mozTransform: 'translate3d(0, 0, 0)',
              willChange: 'transform'
            }}
          >
            {items.map((item, index) => (
              <Box
                key={index}
                component="li"
                sx={{
                  border: `2px solid ${
                    index % 2 === 0 ? 'rgba(106, 17, 203, 1)' : 'rgba(37, 139, 252, 1)'
                  }`,
                  background:
                    index % 2 === 0
                      ? 'radial-gradient(50% 50% at 50% 57%, #2575FC 0%, #6A11CB 98.96%)'
                      : 'radial-gradient(50% 57.56% at 50% 50%, #94FFFD 0%, #258BFC 100%)',
                  transform: getItemTransform(index),
                  overflow: 'hidden',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: '50%',
                  height: '50%',
                  transformOrigin: '0% 100%'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    left: '-100%',
                    width: '200%',
                    height: '200%',
                    textAlign: 'center',
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                    transform: `skewY(${90 - piece}deg) rotate(${piece / 2}deg)`,
                    paddingTop: '5px',
                    fontSize: '10px',
                    lineHeight: '12px',
                    fontWeight: 600
                  }}
                >
                  <Box
                    component="img"
                    src={getBonusIcon(item.type)}
                    sx={{ width: '32px', height: '32px', marginBottom: '2px' }}
                  />
                  {item.amount}
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    left: '-50%',
                    width: '98%',
                    textAlign: 'right',
                    display: 'block',
                    transform: `skewY(${90 - piece}deg) rotate(${piece / 2 - 90}deg)`,
                    paddingTop: '32%',
                    paddingRight: '90px',
                    lineHeight: '12px',
                    fontSize: '10px',
                    fontWeight: 600
                  }}
                >
                  {getBonusLabel(item.type)}
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              position: 'absolute',
              top: '125px',
              left: '125px',
              width: '80px',
              height: '80px',
              background: 'linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%)',
              boxShadow: '0px 0px 5px 5px rgba(26, 7, 120, 0.2)',
              borderRadius: '50%',
              zIndex: 3
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '133px',
              left: '133px',
              width: '64px',
              height: '64px',
              background:
                'linear-gradient(90deg, #00F260 0%, #01DA7A 23%, #039DBB 71%, #0575E6 100%)',
              boxShadow: '0px 0px 4px 2px rgba(0, 85, 137, 0.3)',
              borderRadius: '50%',
              cursor: 'pointer',
              zIndex: 4
            }}
            onClick={() => {
              if (!rotation && !isFetching) {
                handleSpinRequest();
              }
            }}
          />
        </Box>
        <Box
          component="img"
          sx={{ width: '100%', marginTop: '-12px', display: { xs: 'none', md: 'block' } }}
          src={prize}
        />
        <Box
          sx={{
            display: { xs: 'none', md: 'grid' },
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            gap: '8px 40px',
            color: 'common.white'
          }}
        >
          {[...new Map(items.map((item) => [item.type, item])).values()]
            .filter((i) => i.type !== WheelElementType.Lose) // Lose
            .map((item, index) => (
              <Box key={index} sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Box
                  component="img"
                  sx={{ width: '24px', height: '24px' }}
                  src={getBonusIcon(item.type, true)}
                />
                <Box sx={{ fontSize: '16px', lineHeight: '22px' }}>{`${items
                  .filter((i) => i.type === item.type)
                  .map((i) => i.amount)
                  .reduce((partialSum, a) => partialSum + a, 0)} ${getBonusLabel(item.type)}`}</Box>
              </Box>
            ))}
        </Box>
        <Box sx={{ display: 'flex', flexFlow: 'row', gap: '16px', width: '100%' }}>
          <PrimaryButton
            onClick={handleSpinRequest}
            text={t('wheel.spinNowButton')}
            disabled={rotation || isFetching || state?.user?.data?.wheelSpins === 0}
          />
          <CancelButton
            onClick={() => {
              dispatch({ type: Actions.HideWheelPopup });
              navigate(PublicRoutes.Shop);
            }}
            text={t('wheel.buySpinsButton') as string}
            additionalStyle={{ color: 'common.white' }}
            disabled={rotation}
          />
        </Box>
      </Box>
      <Loader loading={elementsQuery.isLoading} />
    </Dialog>
  );
};

export default Wheel;
