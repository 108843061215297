import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* Styles
import './CustomChip.scss';

interface IProps {
  type?: string;
  label: string;
}

const CustomChip = ({ type = 'default', label }: IProps) => {
  return <Box className={`custom-chip custom-chip--${type}`}>{label}</Box>;
};

export default CustomChip;
