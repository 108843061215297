import { Actions } from '../enums/ActionEnums';
import { ActionType, PopupType } from '../models/appcontext';

export const wheelPopupReducer = (state: PopupType, action: ActionType): PopupType => {
  switch (action.type) {
    case Actions.ShowWheelPopup:
      return {
        open: true,
        direction: null,
        state: null,
        isCongrat: false
      };
    case Actions.HideWheelPopup:
      return { ...state, open: false, direction: null, state: null };
    default:
      return state;
  }
};
