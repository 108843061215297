import React, { useContext } from 'react';
import { Box } from '@mui/material';
import {
  buttonContainerProps,
  childContainerProps,
  contentContainerProps,
  imgProps,
  parentContainerProps,
  titleProps
} from './ChallengesBanner';
import CancelButton from '../../Common/Buttons/CancelButton';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import wheel from '../../../assets/homePage/banners/wheel.svg';
import money from '../../../assets/wheel/moneySmall.svg';
import rubber from '../../../assets/wheel/rudderSmall.svg';
import coins from '../../../assets/wheel/coinsSmall.svg';
import gift from '../../../assets/wheel/giftSmall.svg';
import { HomeBanner } from '../../../models/banner';

const bonuses = [
  { icon: money, text: '100 Bonus Money' },
  { icon: rubber, text: '120 Free Spins' },
  { icon: coins, text: '200 Coins' },
  { icon: gift, text: '100 Wheel Spins' }
];

interface IProps {
  banner: HomeBanner;
}

const LuckyWheelBanner: React.FunctionComponent<IProps> = ({ banner }) => {
  const { dispatch } = useContext(AppContext);
  const { i18n } = useTranslation();
  const translation = banner.translations.find(
    (tr) => tr.language.toUpperCase() === i18n.language.toUpperCase()
  );

  return (
    <Box
      sx={{
        ...parentContainerProps,
        background: 'linear-gradient(180deg, #2575FC 0%, #2575FC 0.01%, #6A11CB 100%), #0D1365'
      }}
      className="promotionBanner"
    >
      <Box sx={childContainerProps}>
        <Box sx={contentContainerProps}>
          <Box sx={titleProps}>{translation?.title}</Box>
          <Box
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '22px',
              display: '-webkit-box',
              '-webkit-line-clamp': '2',
              '-webkit-box-orient': 'vertical',
              overflow: 'hidden'
            }}
          >
            {translation?.body}
          </Box>
          {bonuses.map((bonus, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                marginTop: i === 0 ? '16px' : 0
              }}
            >
              <Box component="img" sx={{ width: '24px', height: '24px' }} src={bonus.icon} />
              <Box sx={{ fontSize: '16px', lineHeight: '22px' }}>{bonus.text}</Box>
            </Box>
          ))}
        </Box>
        <CancelButton
          text={translation?.buttonText as string}
          onClick={() => dispatch({ type: Actions.ShowWheelPopup })}
          additionalStyle={buttonContainerProps}
        />
      </Box>
      <Box
        component="img"
        src={wheel}
        sx={{
          ...imgProps,
          top: '15px',
          right: 0
        }}
      />
    </Box>
  );
};

export default LuckyWheelBanner;
