import React, { useContext, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import DialogBox from '../Common/Dialogs/DialogBox';
import { useTranslation } from 'react-i18next';
import { Close, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { isPasswordValid } from '../../utils/validators';
import InputField from '../Common/Fields/InputField';
import { getIconColor } from '../Account/SignupPage';
import PrimaryButton from '../Common/Buttons/PrimaryButton/PrimaryButton';
import { ChangePasswordQuery } from '../../queries/profile';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { ChangePasswordModel } from '../../models/profile';
import { handleError } from '../../utils/ui';

interface IProps {
  handleClose: () => void;
}

type IErrors = {
  oldPassword: string | null;
  password: string | null;
  confirmPassword: string | null;
};

const defaultErrorsObj: IErrors = {
  oldPassword: null,
  password: null,
  confirmPassword: null
};

const PasswordChange: React.FunctionComponent<IProps> = ({ handleClose }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [visibleOldPass, setVisibleOldPass] = useState(false);
  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleConfirmPass, setVisibleConfirmPass] = useState(false);
  const changePassword = useMutation(ChangePasswordQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('myAccount.verificationPage.passwordChangedSuccessfully')
        }
      });
      handleClose();
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('ChangePasswordQuery');
    }
  });

  const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({ ...formErrors, oldPassword: t('errors.signup.password') });
    } else {
      let errors = { ...formErrors };
      errors = { ...errors, oldPassword: null };
      if (password) {
        if (value === password) {
          errors = { ...errors, password: t('errors.newPassSameAsOld') };
        } else {
          errors = { ...errors, password: null };
        }
      }
      setFormErrors(errors);
    }
    setOldPassword(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({ ...formErrors, password: t('errors.signup.password') });
    } else {
      let errors = { ...formErrors };
      errors = { ...errors, password: null };
      if (confirmPassword) {
        if (value !== confirmPassword) {
          errors = { ...errors, confirmPassword: t('errors.signup.passwordNotTheSame') };
        } else {
          errors = { ...errors, confirmPassword: null };
        }
      }
      if (oldPassword) {
        if (value === oldPassword) {
          errors = { ...errors, password: t('errors.newPassSameAsOld') };
        } else {
          errors = { ...errors, password: null };
        }
      }
      setFormErrors(errors);
    }
    setPassword(value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({ ...formErrors, confirmPassword: t('errors.signup.confirmPassword') });
    } else {
      if (value !== password) {
        setFormErrors({ ...formErrors, confirmPassword: t('errors.signup.passwordNotTheSame') });
      } else {
        setFormErrors({ ...formErrors, confirmPassword: null });
      }
    }
    setConfirmPassword(value);
  };

  const isFormValid = () => {
    if (
      !isPasswordValid(oldPassword) ||
      !isPasswordValid(password) ||
      !isPasswordValid(confirmPassword) ||
      oldPassword === password ||
      password !== confirmPassword
    ) {
      return false;
    }

    return true;
  };

  const handleSave = () => {
    const model: ChangePasswordModel = {
      oldPassword,
      password,
      confirmPassword
    };
    changePassword.mutate(model);
  };

  return (
    <DialogBox>
      <Box
        sx={{
          width: '562px',
          padding: '24px',
          display: 'flex',
          flexFlow: 'column',
          gap: '24px'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          <Box sx={{ flexGrow: 1, fontSize: '18px', fontWeight: 600, lineHeight: '25px' }}>
            {t('myAccount.accountPage.passwordPage.title')}
          </Box>
          <IconButton sx={{ flexGrow: 0, padding: 0 }} onClick={() => handleClose()}>
            <Close sx={{ width: '16px', height: '16px' }} />
          </IconButton>
        </Box>
        <InputField
          id="oldPassword"
          value={oldPassword}
          onChange={handleOldPasswordChange}
          label={t('myAccount.accountPage.passwordPage.currentPassLabel') as string}
          isLabelMainColor={true}
          errorMsg={formErrors.oldPassword}
          placeholder={t('myAccount.accountPage.passwordPage.passPlaceholder') as string}
          type={visibleOldPass ? 'text' : 'password'}
          startIcon={
            <Lock sx={{ fill: getIconColor(oldPassword, formErrors.oldPassword), zIndex: 2 }} />
          }
          endIcon={
            <IconButton
              aria-label="toggle current password visibility"
              onClick={() => setVisibleOldPass(!visibleOldPass)}
            >
              {visibleOldPass ? (
                <VisibilityOff
                  sx={{ fill: getIconColor(oldPassword, formErrors.oldPassword), zIndex: 2 }}
                />
              ) : (
                <Visibility
                  sx={{ fill: getIconColor(oldPassword, formErrors.oldPassword), zIndex: 2 }}
                />
              )}
            </IconButton>
          }
        />
        <InputField
          id="newPassword"
          value={password}
          onChange={handlePasswordChange}
          label={t('myAccount.accountPage.passwordPage.newPassLabel') as string}
          isLabelMainColor={true}
          errorMsg={formErrors.password}
          placeholder={t('myAccount.accountPage.passwordPage.passPlaceholder') as string}
          type={visiblePass ? 'text' : 'password'}
          startIcon={<Lock sx={{ fill: getIconColor(password, formErrors.password), zIndex: 2 }} />}
          endIcon={
            <IconButton
              aria-label="toggle new password visibility"
              onClick={() => setVisiblePass(!visiblePass)}
            >
              {visiblePass ? (
                <VisibilityOff
                  sx={{ fill: getIconColor(password, formErrors.password), zIndex: 2 }}
                />
              ) : (
                <Visibility sx={{ fill: getIconColor(password, formErrors.password), zIndex: 2 }} />
              )}
            </IconButton>
          }
        />
        <InputField
          id="confirmNewPassword"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          label={t('myAccount.accountPage.passwordPage.newPassConfirm') as string}
          isLabelMainColor={true}
          errorMsg={formErrors.confirmPassword}
          placeholder={t('myAccount.accountPage.passwordPage.passPlaceholder') as string}
          type={visibleConfirmPass ? 'text' : 'password'}
          startIcon={
            <Lock
              sx={{ fill: getIconColor(confirmPassword, formErrors.confirmPassword), zIndex: 2 }}
            />
          }
          endIcon={
            <IconButton
              aria-label="toggle confirm new password visibility"
              onClick={() => setVisibleConfirmPass(!visibleConfirmPass)}
            >
              {visiblePass ? (
                <VisibilityOff
                  sx={{
                    fill: getIconColor(confirmPassword, formErrors.confirmPassword),
                    zIndex: 2
                  }}
                />
              ) : (
                <Visibility
                  sx={{
                    fill: getIconColor(confirmPassword, formErrors.confirmPassword),
                    zIndex: 2
                  }}
                />
              )}
            </IconButton>
          }
        />
        <Box>
          <PrimaryButton
            text={t('myAccount.accountPage.passwordPage.actionButton')}
            onClick={() => handleSave()}
            disabled={!isFormValid()}
          />
        </Box>
      </Box>
    </DialogBox>
  );
};

export default PasswordChange;
