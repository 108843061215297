import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Slide, MenuItem, Checkbox, ListItemText } from '@mui/material';
//* Icons
// import { Close } from '@mui/icons-material';
//* Components
import InputField from '../Fields/InputField';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
// import CustomIconButton from '../Buttons/CustomIconButton/CustomIconButton';
//* Styles
import './FullscreenList.scss';

interface IActions {
  label: string;
  variant?: string;
  icon?: JSX.Element;
  action: () => void;
  disabled?: boolean;
}
interface IOption {
  label: string;
  imageUrl: string;
}

interface IFullscreenList {
  title?: string;
  isOpen: boolean;
  options: IOption[];
  actions?: IActions[];
  searchable?: boolean;
  handleClose: () => void;
  selectedOptions?: string[];
  extraControls?: JSX.Element;
  getSelectedOptions?: (selectedOptions: string) => void;
}

const FullscreenList = ({
  title,
  isOpen,
  options,
  actions,
  searchable,
  // handleClose,
  extraControls,
  selectedOptions = [],
  getSelectedOptions = () => {
    return;
  }
}: IFullscreenList) => {
  const [searchValue, setSearchValue] = useState('');
  const [listOptions, setListOptions] = useState(options);
  const { t } = useTranslation();

  useEffect(() => {
    filterOptionItems(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const filterOptionItems = (filterString: string) => {
    const filteredOptionItems = options.filter((option) =>
      option.label.toLocaleLowerCase().includes(filterString.toLocaleLowerCase())
    );

    setListOptions(filteredOptionItems);
  };

  const renderOptionItems = () =>
    listOptions.map((option) => {
      const isSelected = selectedOptions.indexOf(option.label) > -1 || false;
      const menuItemValue = `${option.label.charAt(0).toUpperCase()}${option.label.slice(1)}`;

      return (
        <MenuItem
          key={option.label}
          value={option.label}
          onClick={() => getSelectedOptions(option.label)}
          sx={{ '&::after': { backgroundImage: `url(${option.imageUrl})` } }}
          className={`fullscreen-list__item ${isSelected ? 'fullscreen-list__item--selected' : ''}`}
        >
          <Checkbox
            className={`fullscreen-list__item__checkbox  ${
              isSelected ? 'fullscreen-list__item__checkbox--checked' : ''
            }`}
            checked={selectedOptions.indexOf(option.label) > -1}
          />
          <ListItemText primary={menuItemValue} className="fullscreen-list__item__text" />
        </MenuItem>
      );
    });

  const renderActions = () => {
    const extraActionsArray = actions?.map((action) => {
      return (
        <PrimaryButton
          key={action.label}
          icon={action.icon}
          text={action.label}
          onClick={action.action}
          disabled={action.disabled}
          buttonVariant={action.variant}
        />
      );
    });

    return extraActionsArray;
  };

  return (
    <Slide direction="left" in>
      <section className="fullscreen-list">
        <div className="fullscreen-list__row fullscreen-list__row--title">
          <span className="fullscreen-list__title">{title}</span>
          {/* <CustomIconButton icon={<Close />} onClick={handleClose} /> */}
        </div>
        <div className="fullscreen-list__row fullscreen-list__row--extra-controls">
          {extraControls}
        </div>
        {searchable && (
          <div className="fullscreen-list__row fullscreen-list__row--search">
            <InputField
              label="Providers"
              value={searchValue}
              id="searchProviders"
              isSecondaryStyle={false}
              className="fullscreen-list__custom-search"
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder={t('gamesPage.searchPlaceholderForProvider') as string}
            />
          </div>
        )}
        <div className="fullscreen-list__items">{renderOptionItems()}</div>
        <div className="fullscreen-list__actions">{renderActions()}</div>
      </section>
    </Slide>
  );
};

export default FullscreenList;
