import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { AppContext } from '../../../AppContext';
import { PublicRoutes } from '../../../enums/RouteEnums';
import { colors } from '../../../utils/theme';
import { useQuery } from 'react-query';
//* MUI
import { Close } from '@mui/icons-material';
import { Box, Drawer, IconButton } from '@mui/material';
import { handleError, mobileNavigationItems } from '../../../utils/ui';
//* Icons
import logo from '../../../assets/logo.svg';
import menu from '../../../assets/burger_menu.svg';
//* Components
import MenuItem from './MenuItem';
import Badge from '../../Challenges/Badge';
import CancelButton from '../../Common/Buttons/CancelButton';
import { AllChallengesQuery } from '../../../queries/challenges';
import LevelInformation from './LevelInformation/LevelInformation';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
//* Models
import { ChallengeGroup, ChallengeGroupInfo, ChallengeModel } from '../../../models/challenges';
//* Enums
import { Query } from '../../../enums/RequestEnums';
//* Styles
import './MobileNavigation.scss';

const MobileNavigation: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mobileNavigationHeight, setMobileNavigationHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setMobileNavigationHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const challengesQuery = useQuery(Query.AllChallenges, AllChallengesQuery, {
    onSuccess: ({ data }) => {
      const { groupData, playerData } = data as ChallengeGroupInfo;

      return { groupData, playerData };
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const isSignedIn = !!state.user.token;
  const groupData = challengesQuery?.data?.data.groupData;
  const playerData = challengesQuery?.data?.data.playerData;
  const challengeLevelId = state.user.data?.challengeLevel?.id;
  const currentGroupData = groupData?.find(
    (group: ChallengeGroup) => group.id === challengeLevelId
  );
  const completedChallenges = currentGroupData?.challenges.filter(
    (groupChallenge: ChallengeModel) => playerData?.completedChallenges.includes(groupChallenge.id)
  );
  const completedChallengesPercentage =
    (100 * completedChallenges?.length) / currentGroupData?.amountToComplete;

  const getLogo = () => {
    return (
      <img
        src={logo}
        alt="logo"
        onClick={() => {
          navigate(PublicRoutes.Home);
          setIsMenuOpen(false);
        }}
        className="mobile-menu-logo"
      />
    );
  };

  const itemClicked = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'flex', lg: 'none' },
        height: isMenuOpen ? mobileNavigationHeight : 'auto'
      }}
    >
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        color="inherit"
        sx={{ padding: 0, marginRight: '31px' }}
      >
        <img src={menu} alt="menu" className="mobile-menu-burger" />
      </IconButton>
      {getLogo()}
      <Drawer
        anchor={'left'}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          sx: {
            background: colors.primary.second,
            borderRadius: '0 5px 5px 0'
          }
        }}
      >
        <Box className="mobile-menu-container">
          <Box>
            <Box className="mobile-menu-container__logo-row">
              <IconButton onClick={() => setIsMenuOpen(false)} sx={{ padding: 0 }}>
                <Close sx={{ color: 'common.white' }} />
              </IconButton>
              {getLogo()}
            </Box>
            {mobileNavigationItems.map((navItem, index) => (
              <MenuItem key={`mobile-menu-item-${index}`} item={navItem} onClick={itemClicked} />
            ))}
          </Box>
          <Box>
            {isSignedIn ? (
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '16px',
                  paddingX: '12px',
                  marginBottom: '4px',
                  width: '100%'
                }}
              >
                <Badge
                  showName
                  withBackground
                  name={state.user.data?.challengeLevel?.name}
                  rank={state.user.data?.challengeLevel?.rank ?? 0}
                />
                <LevelInformation
                  onLinkClick={itemClicked}
                  loyaltyPoints={state.user.data?.loyaltyPoints ?? 0}
                  completedChallengesPercentage={completedChallengesPercentage}
                />
              </Box>
            ) : (
              <Box className="mobile-menu-container__actions">
                <CancelButton
                  text="Log In"
                  onClick={() => navigate(PublicRoutes.Signin)}
                  additionalStyle={{ color: 'white' }}
                />
                <PrimaryButton
                  text={t('account.signUp')}
                  onClick={() => navigate(PublicRoutes.Signup)}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileNavigation;
