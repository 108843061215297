import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { PlayerFreeSpins } from '../../queries/bonus';
import Loader from '../Common/Loader';
import { colors } from '../../utils/theme';
import { AppContext } from '../../AppContext';
import CancelButton from '../Common/Buttons/CancelButton';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../Common/Buttons/PrimaryButton/PrimaryButton';
import { IPlayerFreeSpins } from '../../models/shop';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../utils/ui';

const FreeSpins: React.FunctionComponent = () => {
  const itemPerPage = 16;
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [freeSpins, setFreeSpins] = useState<IPlayerFreeSpins[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const freeSpinsQuery = useMutation(PlayerFreeSpins, {
    onSuccess: ({ data }) => {
      setFreeSpins(data);
      setTotalPages(parseInt((data.length / itemPerPage).toString()));
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('PlayerFreeSpins');
    }
  });

  useEffect(() => {
    freeSpinsQuery.mutate();
  }, []);

  const handleGameLinkClicked = (gameData: IPlayerFreeSpins) => {
    const game = state.games.find((g) => g.id === gameData.gameId);
    if (game) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      navigate(`/games/${game.gameId}-${game.name.replaceAll(' ', '')}`);
    }
  };

  const size = '96px';
  return (
    <Box sx={{ py: '50px' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(1 minmax(0, 1fr))',
            sm: 'repeat(1, minmax(0, 1fr))',
            md: 'repeat(2, minmax(0, 1fr))'
          },
          gap: { xs: '32px', md: '16px' },
          background: colors.background.blue,
          padding: { xs: '24px', md: '32px' },
          borderRadius: '16px'
        }}
      >
        {freeSpins.slice(page * itemPerPage, page * itemPerPage + itemPerPage).map((fs, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              gap: '16px',
              border: '1px solid white',
              borderRadius: '8px'
            }}
          >
            <Box
              component="img"
              src={`${
                state.games.find((g) => g.id === fs.gameId)?.thumbnailSmall
              }?${new Date().getTime()}`}
              sx={{ borderRadius: '8px', width: size, height: '100%' }}
            />
            <Box sx={{ textAlign: 'left' }}>
              <Typography
                onClick={() => handleGameLinkClicked(fs)}
                sx={{
                  fontWeight: 600,
                  backgroundcolor: 'primary',
                  backgroundImage: `linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%)`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'repeat',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  cursor: 'pointer'
                }}
              >
                {fs.gameName}
              </Typography>
              <Box>
                `${t('gamesPage.initialSpins')}:` {fs.initialSpinsAmount}
              </Box>
              <Box>
                `${t('gamesPage.leftSpins')}:` {fs.spinsLeft}
              </Box>
              <Box>
                `${t('gamesPage.bet')}:` {fs.betValue.toFixed(2)}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {freeSpins.length > itemPerPage && (
        <Box sx={{ marginTop: '40px', display: 'flex', gap: '16px', justifyContent: 'center' }}>
          {page > 0 && (
            <CancelButton
              text={t('gamesPage.viewLessButton') as string}
              onClick={() => setPage(page - 1)}
              additionalStyle={{ color: 'common.white', width: '206px' }}
            />
          )}
          {page < totalPages && (
            <PrimaryButton
              text={t('gamesPage.viewMoreButton')}
              onClick={() => setPage(page + 1)}
              additionalStyle={{ width: '206px' }}
            />
          )}
        </Box>
      )}
      <Loader loading={freeSpinsQuery.isLoading} />
    </Box>
  );
};

export default FreeSpins;
