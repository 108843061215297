import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { CircleLoader } from 'react-spinners';
import { colors } from '../../utils/theme';
import { AppContext } from '../../AppContext';
import { getUserCurrencySymbol } from '../../utils/ui';

interface IProps {
  title: string;
  value: number | string;
  icon?: JSX.Element;
  hideCurrency?: boolean;
  loading?: boolean;
  isNav?: boolean;
}

const BalanceBox: React.FunctionComponent<IProps> = ({
  title,
  value,
  icon,
  hideCurrency = false,
  loading = false,
  isNav = false
}: IProps) => {
  const { state } = useContext(AppContext);
  return (
    <Box
      sx={{
        flex: 1,
        background: isNav ? colors.background.blueSecondary : colors.background.blue,
        border: `2px solid ${isNav ? colors.white : colors.info.main}`,
        borderRadius: '8px',
        padding: isNav ? '6px 10px' : '14px',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'start',
        color: 'common.white'
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ fontSize: isNav ? '14px' : '16px', lineHeight: isNav ? '17px' : '22px' }}>
          {title}
        </Box>
        {/* {loading ? (
          <Box sx={{ marginTop: '8px' }}>
            <CircleLoader loading color="white" size={35} />
          </Box>
        ) : hideCurrency ? (
          <Box sx={{ marginTop: '8px', fontSize: '24px', lineHeight: '34px' }}>{value}</Box>
        ) : (
          <Box
            sx={{ marginTop: '8px', fontSize: '24px', lineHeight: '34px' }}
          >{`\u20AC${value}`}</Box>
        )} */}

        <Box
          sx={{
            marginTop: isNav ? '4px' : '8px',
            fontSize: isNav ? '18px' : '24px',
            lineHeight: isNav ? '25px' : '34px',
            fontWeight: 600
          }}
        >
          {loading ? (
            <CircleLoader loading color="white" size={35} />
          ) : (
            `${!hideCurrency ? getUserCurrencySymbol(state.user.data?.currency) : ''}${value}`
          )}
        </Box>
      </Box>
      {icon && <Box sx={{ flexGrow: 0 }}>{icon}</Box>}
    </Box>
  );
};

export default BalanceBox;
