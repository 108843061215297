import React, { useContext } from 'react';
import { oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import { getUserCurrencySymbol } from '../../../utils/ui';
//* Components
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
//* MUI
import StarsIcon from '@mui/icons-material/Stars';
//* Styles
import './ExchangeSliderSummary.scss';

interface IProps {
  type?: string;
  amount: number;
  cost: number;
  onSubmit: () => void;
  className?: string;
}

const ExchangeSliderSummary: React.FunctionComponent<IProps> = ({
  type,
  amount,
  cost,
  onSubmit,
  className
}: IProps) => {
  const { t } = useTranslation();
  const { state } = useContext(AppContext);

  const getTypeTitle = () => {
    switch (type) {
      case 'wheel':
        return t('shop.navigation.wheel');
      case 'bonus':
        return t('shop.navigation.bonus');
      default:
        return '';
    }
  };

  const getAmountText = () => {
    switch (type) {
      case 'wheel':
        return amount;
      case 'bonus':
        return `${getUserCurrencySymbol(state.user.data?.currency)} ${amount}`;
      default:
        return '';
    }
  };

  return (
    <div className={`summary ${className}`}>
      <span className="summary__title">{t('shop.titles.summary')}</span>
      <div className="summary__row">
        <span className="summary__type">{getTypeTitle()}</span>
        <div className="summary__amount">{getAmountText()}</div>
      </div>
      <div className="summary__row">
        <span className="summary__total">{t('shop.titles.total')}</span>
        <div className="summary__price">
          {cost}
          <StarsIcon className="summary__price-icon" />
        </div>
      </div>
      <div className="summary__action">
        <PrimaryButton
          text={t('common.button.exchange')}
          disabled={amount === 0}
          className="summary__action-button"
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

ExchangeSliderSummary.propTypes = {
  type: oneOf(['wheel', 'bonus'])
};

ExchangeSliderSummary.defaultProps = {
  type: 'wheel'
};

export default ExchangeSliderSummary;
