import React, { useContext, useState } from 'react';
import { Box, Grow } from '@mui/material';
import ExchangeSlider from './ExchangeSlider/ExchangeSlider';
import { AppContext } from '../../AppContext';
import { calculateExchangeMax } from '../../utils/validators';
import ExchangeSliderSummary from './ExchangeSliderSummary/ExchangeSliderSummary';
import { useMutation } from 'react-query';
import { PostExchange } from '../../queries/shop';
import { Actions } from '../../enums/ActionEnums';
import { handleError } from '../../utils/ui';
import { PublicRoutes } from '../../enums/RouteEnums';
import { useTranslation } from 'react-i18next';
import Loader from '../Common/Loader';

interface IProps {
  rate: number;
}
const WheelShop: React.FunctionComponent<IProps> = ({ rate }: IProps) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const [exchangeValue, setExchangeValue] = useState(0);
  const max = calculateExchangeMax(state.user.data?.loyaltyPoints, rate);

  const { mutate, isLoading } = useMutation(PostExchange, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowPopup,
        payload: {
          isCongrat: true,
          state: t('shop.titles.responses.successWheelSpins', { amount: exchangeValue })
        }
      });
      dispatch({
        type: Actions.AddUserWheelSpins,
        payload: {
          amount: exchangeValue
        }
      });
      dispatch({
        type: Actions.UpdateUserLoyaltyPoints,
        payload: {
          loyaltyPoints: data as number
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => setExchangeValue(0)
  });

  const handleExchange = () => {
    if (state.user.data) {
      mutate({
        type: 'WheelSpins',
        amount: exchangeValue
      });
    } else {
      dispatch({
        type: Actions.ShowLoginPopup,
        payload: {
          state: { path: PublicRoutes.Shop }
        }
      });
    }
  };

  return (
    <Grow in timeout={1000}>
      <Box className="shop-page__row">
        <ExchangeSlider
          value={exchangeValue}
          setValue={setExchangeValue}
          rate={rate}
          max={max}
          className="shop-page__row-item--wide"
        />
        <ExchangeSliderSummary
          amount={exchangeValue}
          cost={exchangeValue * rate}
          onSubmit={handleExchange}
          className="shop-page__row-item--narrow"
        />
        <Loader loading={isLoading} />
      </Box>
    </Grow>
  );
};

export default WheelShop;
