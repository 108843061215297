import React from 'react';
import { Box } from '@mui/material';
import { IBonus } from '../../../models/bonus';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
import CancelButton from '../../Common/Buttons/CancelButton';
import { colors } from '../../../utils/theme';
import './SuggestedBonus.scss';

interface IProps {
  bonus: IBonus;
  disableClaim: boolean;
  handleClaim: (input: IBonus) => void;
  handleDecline: (input: IBonus) => void;
}

const SuggestedBonus: React.FunctionComponent<IProps> = ({
  bonus,
  disableClaim,
  handleClaim,
  handleDecline
}: IProps) => {
  const { i18n, t } = useTranslation();

  return (
    <Box className="suggested-bonus">
      <Box className="suggested-bonus__description" sx={{ padding: { xs: '10px', md: '14px' } }}>
        <Box className="suggested-bonus__description__code">{bonus.code}</Box>
        <Box className="suggested-bonus__description__text">
          {bonus.promoDetails.find((d) => d.language.toUpperCase() === i18n.language.toUpperCase())
            ?.description ?? bonus.promoDetails[0]?.description}
        </Box>
      </Box>
      <Box>
        <PrimaryButton
          onClick={() => handleClaim(bonus)}
          text={t('myAccount.bonusPage.claimBonus')}
          additionalStyle={{ mb: '8px' }}
          disabled={disableClaim}
        />
        <CancelButton
          onClick={() => handleDecline(bonus)}
          text={t('myAccount.bonusPage.declineBonus') as string}
          additionalStyle={{ color: colors.white }}
        />
      </Box>
    </Box>
  );
};

export default SuggestedBonus;
