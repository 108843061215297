import HomePage from './components/Home/HomePage';
import SigninPage from './components/Account/SigninPage';
import { AuthRoutes, PublicRoutes, RouteNames } from './enums/RouteEnums';
import SignupPage from './components/Account/SignupPage';
import ResetPass from './components/Account/ResetPassword';
import EmailConfirm from './components/Account/ConfirmEmail';
import ContactUs from './components/Footer/ContactUs';
import WalletOverview from './components/Wallet/WalletOverview';
import MyAccount from './components/Profile/MyAccountOverview';
import GamesPage from './components/Games/GamesPage';
import GameWindow from './components/Games/GameWindow';
import NotFound from './components/Common/NotFound';
import PromotionPage from './components/Promotions/PromotionsPage';
import TournamentsPage from './components/Tournaments/TournamentsPage';
import ChallengesPage from './components/Challenges/ChallengesPage';
import Shop from './components/Shop/ShopPage';
import Bonuses from './components/Bonuses/BonusOverview';

export interface IRouteModel {
  id?: number;
  name: string;
  path: string;
  Component: React.FunctionComponent;
  requiredRole?: string | null;
  isPublic: boolean;
}

const Routes: IRouteModel[] = [
  {
    name: RouteNames.Home,
    path: PublicRoutes.Home,
    Component: HomePage,
    isPublic: true
  },
  {
    name: RouteNames.Signin,
    path: PublicRoutes.Signin,
    Component: SigninPage,
    isPublic: true
  },
  {
    name: RouteNames.Signup,
    path: PublicRoutes.Signup,
    Component: SignupPage,
    isPublic: true
  },
  {
    name: RouteNames.ResetPass,
    path: PublicRoutes.ResetPass,
    Component: ResetPass,
    isPublic: true
  },
  {
    name: RouteNames.ConfirmEmail,
    path: PublicRoutes.ConfirmEmail,
    Component: EmailConfirm,
    isPublic: true
  },
  {
    name: RouteNames.ContactUs,
    path: PublicRoutes.ContactUs,
    Component: ContactUs,
    isPublic: true
  },
  {
    name: RouteNames.Wallet,
    path: AuthRoutes.Wallet,
    Component: WalletOverview,
    isPublic: false
  },
  {
    name: RouteNames.MyAccount,
    path: AuthRoutes.MyAccount,
    Component: MyAccount,
    isPublic: false
  },
  {
    name: RouteNames.Games,
    path: PublicRoutes.Games,
    Component: GamesPage,
    isPublic: true
  },
  {
    name: RouteNames.Game,
    path: AuthRoutes.Game,
    Component: GameWindow,
    isPublic: false
  },
  {
    name: RouteNames.Bonuses,
    path: AuthRoutes.Bonuses,
    Component: Bonuses,
    isPublic: false
  },
  {
    name: RouteNames.NotFound,
    path: PublicRoutes.NotFound,
    Component: NotFound,
    isPublic: true
  },
  {
    name: RouteNames.Promotions,
    path: PublicRoutes.Promotions,
    Component: PromotionPage,
    isPublic: true
  },
  {
    name: RouteNames.Tournaments,
    path: PublicRoutes.Tournaments,
    Component: TournamentsPage,
    isPublic: true
  },
  {
    name: RouteNames.Challenges,
    path: PublicRoutes.Challenges,
    Component: ChallengesPage,
    isPublic: true
  },
  {
    name: RouteNames.Shop,
    path: PublicRoutes.Shop,
    Component: Shop,
    isPublic: true
  }
];

export const getRoutes = (): IRouteModel[] => {
  let index = 0;
  const result: IRouteModel[] = [];
  Routes.forEach((r: IRouteModel) => {
    result.push({ id: index++, ...r });
  });
  return result;
};
