import React from 'react';
import { Typography } from '@mui/material';

interface IProps {
  text: string | undefined;
}
const WrapperTitle: React.FunctionComponent<IProps> = ({ text }: IProps) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: {
          xs: '28px',
          sm: '28px',
          md: '40px'
        },
        textAlign: 'left'
      }}
    >
      {text}
    </Typography>
  );
};

export default WrapperTitle;
