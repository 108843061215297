import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Tooltip
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backGround from '../../assets/login.svg';
import logo from '../../assets/logo.svg';
import LockIcon from '@mui/icons-material/Lock';
import CSS from 'csstype';
import { colors } from '../../utils/theme';
import { useMutation, useQueryClient } from 'react-query';
import { ResetPassQuery } from '../../queries/account';
import { useNavigate, useLocation } from 'react-router-dom';
import { PublicRoutes } from '../../enums/RouteEnums';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import queryString from 'query-string';
import { isPasswordValid, isValidEmail } from '../../utils/validators';
import { handleError } from '../../utils/ui';

const labelStyle: CSS.Properties = {
  top: '10px',
  color: colors.primary.main,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Poppins'
};

type IErrors = {
  password: string | null;
  confirmPassword: string | null;
  passwordsMismatch: string | null;
};

const defaultErrorsObj: IErrors = {
  password: null,
  confirmPassword: null,
  passwordsMismatch: null
};

const ResetPassword: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleConfirmPass, setVisibleConfirmPass] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const { mutate, isLoading } = useMutation(ResetPassQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('account.passChanged')
        }
      });
      navigate(PublicRoutes.Signin);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('ResetPasswordQuery');
    }
  });

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, password: t('errors.shortPass'), passwordsMismatch: null });
    } else {
      setFormErrors({ ...formErrors, password: null, passwordsMismatch: null });
    }
    setPassword(value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({
        ...formErrors,
        confirmPassword: t('errors.shortConfirmPass'),
        passwordsMismatch: null
      });
    } else {
      setFormErrors({ ...formErrors, confirmPassword: null, passwordsMismatch: null });
    }
    setConfirmPass(value);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSignin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let hasErrors = false;
    const errors: IErrors = {
      password: null,
      confirmPassword: null,
      passwordsMismatch: null
    };

    if (!isPasswordValid(password)) {
      hasErrors = true;
      errors.password = t('errors.invalidPass');
    }
    if (!isPasswordValid(confirmPass)) {
      hasErrors = true;
      errors.confirmPassword = t('errors.invalidConfirmPass');
    }
    if (!hasErrors && password !== confirmPass) {
      hasErrors = true;
      errors.passwordsMismatch = t('errors.passwordsMismatch');
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      const parsed = queryString.parse(location.search);
      if (
        parsed.token &&
        parsed.token.length > 0 &&
        parsed.email &&
        isValidEmail(parsed.email as string)
      ) {
        mutate({
          token: parsed.token as string,
          email: parsed.email as string,
          password,
          confirmPassword: confirmPass
        });
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={8} component={Paper} square>
        <Box
          sx={{
            mt: {
              xs: '16px',
              sm: '16px',
              md: '45px'
            },
            ml: {
              xs: '16px',
              sm: '16px',
              md: '45px'
            },
            fontFamily: 'Poppins'
          }}
        >
          <img
            src={logo}
            alt="logo"
            onClick={() => navigate(PublicRoutes.Home)}
            style={{ cursor: 'pointer' }}
          />
        </Box>
        <Box
          sx={{
            my: {
              xs: 4,
              sm: 4,
              md: 8
            },
            mx: {
              xs: 2,
              sm: 2,
              md: 4
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              color: 'primary.main',
              fontWeight: 700,
              fontStyle: 'normal',
              fontFamily: 'Poppins',
              fontSize: {
                xs: '28px',
                sm: '28px',
                md: '40px'
              }
            }}
          >
            {t('account.resetPasswordTitle')}
          </Box>
          <Box
            sx={{
              color: 'red',
              fontFamily: 'Poppins'
            }}
          >
            {formErrors.passwordsMismatch ? `${formErrors.passwordsMismatch}` : ''}
          </Box>
          <Box component="form" onSubmit={handleSignin} sx={{ mt: 1, maxWidth: 548 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  mt: {
                    xs: formErrors.passwordsMismatch ? '-8px' : '16px',
                    sm: formErrors.passwordsMismatch ? '-8px' : '16px',
                    md: formErrors.passwordsMismatch ? '8px' : '32px'
                  },
                  height: 66
                }}
              >
                <InputLabel htmlFor="password" style={labelStyle}>
                  {t('account.password')}
                </InputLabel>
                <Tooltip title={t('account.passwordRequirements')} placement="top">
                  <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    type={visiblePass ? 'text' : 'password'}
                    id="password"
                    color="info"
                    placeholder={t('account.passPlaceholder') as string}
                    value={password}
                    onChange={handlePasswordChange}
                    error={formErrors.password !== null}
                    helperText={formErrors.password ? formErrors.password : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setVisiblePass(!visiblePass)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {visiblePass ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 50, height: 66, marginBottom: 50 }}>
                <InputLabel htmlFor="confirm-password" style={labelStyle}>
                  {t('account.confirmPassword')}
                </InputLabel>
                <Tooltip title={t('account.passwordRequirements')} placement="top">
                  <TextField
                    margin="normal"
                    fullWidth
                    name="confirm-password"
                    type={visibleConfirmPass ? 'text' : 'password'}
                    id="confirm-password"
                    color="info"
                    placeholder={t('account.confirmPassPlaceholder') as string}
                    value={confirmPass}
                    onChange={handleConfirmPasswordChange}
                    error={formErrors.confirmPassword !== null}
                    helperText={formErrors.confirmPassword ? formErrors.confirmPassword : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setVisibleConfirmPass(!visibleConfirmPass)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {visibleConfirmPass ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <Button
              type="submit"
              className="custom-button"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{
                mt: 3,
                mb: 2,
                background: 'linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%)',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                '&:hover': {
                  background: (t) => t.palette.common.white,
                  boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
                },
                color: 'primary.main'
              }}
            >
              {t('account.send')}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={4}
        sx={{
          backgroundImage: `url(${backGround})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => t.palette.primary.main,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
    </Grid>
  );
};

export default ResetPassword;
