import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  buttonContainerProps,
  childContainerProps,
  contentContainerProps,
  imgProps,
  parentContainerProps,
  titleProps
} from './ChallengesBanner';
import { PublicRoutes } from '../../../enums/RouteEnums';
import CancelButton from '../../Common/Buttons/CancelButton';
import tournament from '../../../assets/homePage/banners/tournament.svg';
import { HomeBanner } from '../../../models/banner';

interface IProps {
  banner: HomeBanner;
}

const TournamentsBanner: React.FunctionComponent<IProps> = ({ banner }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const translation = banner.translations.find(
    (tr) => tr.language.toUpperCase() === i18n.language.toUpperCase()
  );

  return (
    <Box
      sx={{
        ...parentContainerProps,
        background: 'linear-gradient(180deg, #2575FC 0%, #25FCFC 0.01%, #530D65 100%), #0D1365'
      }}
      className="promotionBanner"
    >
      <Box sx={childContainerProps}>
        <Box sx={contentContainerProps}>
          <Box sx={titleProps}>{translation?.title}</Box>
          <Box>{translation?.body}</Box>
        </Box>
        <CancelButton
          text={translation?.buttonText as string}
          onClick={() => navigate(PublicRoutes.Tournaments)}
          additionalStyle={buttonContainerProps}
        />
      </Box>
      <Box
        component="img"
        src={tournament}
        sx={{
          ...imgProps,
          top: 0,
          right: 0
        }}
      />
    </Box>
  );
};

export default TournamentsBanner;
