import React, { useState, useEffect, useRef, useContext } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppContext } from '../../AppContext';
import axios from 'axios';
//* MUI
import { Box } from '@mui/material';
//* Models
import { IGameDto } from '../../models/games';
import { ITournament, ITournamentRanking } from '../../models/tournaments';
//* Queries
import { AllGamesQuery } from '../../queries/games';
import { AllTournamentsQuery } from '../../queries/tournaments';
//* Enums
import { Actions } from '../../enums/ActionEnums';
//* Components
import Loader from '../Common/Loader';
import TournamentCard from './TournamentCard/TournamentCard';
//* Utils
import { colors } from '../../utils/theme';
import { handleError } from '../../utils/ui';
import { Query } from '../../enums/RequestEnums';

const UpcomingTournaments: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(AppContext);
  const [tournamentRanking, setTournamentRanking] = useState<ITournamentRanking[]>([]);
  const [rankingConnection, setRankingConnection] = useState<HubConnection | null>(null);

  const tournamentsQuery = useQuery(Query.AllTournaments, AllTournamentsQuery, {
    onSuccess: ({ data }) => {
      const tournamentsData = data as ITournament[];

      return tournamentsData;
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const tournamentsData = tournamentsQuery.data?.data.tournaments
    ? [...tournamentsQuery?.data?.data.tournaments]
    : [];
  const filteredTournamentsData = tournamentsData.filter((tournament) => !tournament.isActive);

  const allGames = useMutation(AllGamesQuery, {
    onSuccess: ({ data }) => {
      const currentGames = data as IGameDto[];
      dispatch({
        type: Actions.SetGames,
        payload: currentGames
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('GetGames');
    }
  });

  const latestRanking = useRef<ITournamentRanking[]>([]);
  latestRanking.current = tournamentRanking;

  useEffect(() => {
    if (state.games.length === 0) {
      allGames.mutate();
    }
  }, []);

  useEffect(() => {
    axios
      .get(process.env.TOURNAMENTS_ADDRESS as string)
      .then((res) => {
        if (res.status === 200) {
          const newConn = new HubConnectionBuilder()
            .withUrl(res.data.url, {
              accessTokenFactory: () => res.data.accessToken
            })
            .withAutomaticReconnect()
            .build();
          setRankingConnection(newConn);
        }
      })
      .catch((e) => console.log(e));

    return () => {
      if (rankingConnection) {
        rankingConnection.stop();
        setRankingConnection(null);
      }
    };
  }, []);

  useEffect(() => {
    if (rankingConnection) {
      rankingConnection
        .start()
        .then(() => {
          console.log('rankingConnection Connected!');
          rankingConnection.on('notify', (data: unknown) => {
            console.log('received updated ranking: ', data);
            const newData = data as ITournamentRanking[];
            setTournamentRanking(newData);
          });
        })
        .catch((e) => console.log(e));
    }
  }, [rankingConnection]);

  return (
    <Box sx={{ paddingX: { xs: '2%', md: '5%', xl: '7.5%' } }}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          gap: '16px',
          background: colors.background.blue,
          alignItems: 'center'
        }}
      >
        {filteredTournamentsData.map((t, i) => (
          <TournamentCard
            key={i}
            index={i}
            tournament={t}
            ranking={
              tournamentRanking.length > 0 && tournamentRanking[0].tournamentId === t.id
                ? tournamentRanking
                : undefined
            }
          />
        ))}
      </Box>
      <Loader loading={tournamentsQuery.isLoading} />
    </Box>
  );
};

export default UpcomingTournaments;
