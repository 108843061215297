import React from 'react';
import { Box } from '@mui/material';
import {
  buttonContainerProps,
  childContainerProps,
  contentContainerProps,
  imgProps,
  parentContainerProps,
  titleProps
} from './ChallengesBanner';
import CancelButton from '../../Common/Buttons/CancelButton';
import { PublicRoutes } from '../../../enums/RouteEnums';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import shop from '../../../assets/homePage/banners/shop.svg';
import { HomeBanner } from '../../../models/banner';

interface IProps {
  banner: HomeBanner;
}

const ShopBanner: React.FunctionComponent<IProps> = ({ banner }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const translation = banner.translations.find(
    (tr) => tr.language.toUpperCase() === i18n.language.toUpperCase()
  );

  return (
    <Box
      sx={{
        ...parentContainerProps,
        background:
          'linear-gradient(360deg, #010043 -0.13%, #881EDC 99.87%), linear-gradient(360deg, #046ADB -0.13%, #1EDCDC 99.87%)'
      }}
      className="promotionBanner"
    >
      <Box sx={childContainerProps}>
        <Box sx={contentContainerProps}>
          <Box sx={titleProps}>{translation?.title}</Box>
          <Box>{translation?.body}</Box>
        </Box>
        <CancelButton
          text={translation?.buttonText as string}
          onClick={() => navigate(PublicRoutes.Shop)}
          additionalStyle={buttonContainerProps}
        />
      </Box>
      <Box
        component="img"
        src={shop}
        sx={{
          ...imgProps,
          top: '24px',
          right: 0
        }}
      />
    </Box>
  );
};

export default ShopBanner;
