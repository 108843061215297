import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { countries } from '../../utils/countries';
import AccessDenied from './AccessDenied/AccessDenied';

interface IProps {
  children: React.ReactNode;
}

const restrictedCountries = ['Bulgaria'];

const Locator: React.FunctionComponent<IProps> = ({ children }: IProps) => {
  const [userCountry, setUserCountry] = useState('');
  const isDev = process.env.ENVIRONMENT === 'develop';

  useEffect(() => {
    const checkAndRedirect = async () => {
      try {
        if (!userCountry || userCountry.length === 0) {
          const response = await axios.get('https://ipapi.co/json/');
          const { data } = response;
          const country = countries.find((c) => c.code === data.country)?.label ?? '';

          setUserCountry(country);
        }
      } catch (error) {
        console.error('Error fetching geo info:', error);
      }
    };

    checkAndRedirect();
  }, []);

  const shouldRestrict = () => {
    return !isDev && userCountry.length > 0 && restrictedCountries.includes(userCountry);
  };
  return <Fragment>{shouldRestrict() ? <AccessDenied /> : children}</Fragment>;
};

export default Locator;
