import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Paragraph } from './FooterPage';
import { Box } from '@mui/system';
import { Divider } from '@mui/material';

interface IProps {
  questions: Paragraph[];
}

const radius = '8px';

const FooterQuestion: React.FunctionComponent<IProps> = ({ questions }) => {
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange = (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ mt: '32px' }}>
      {questions.map((question, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
          style={{
            backgroundColor: 'transparent',
            color: 'white'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
            style={{
              border: '1px solid white',
              borderBottom: expanded === index ? 'none' : '1px solid white',
              borderTopLeftRadius: radius,
              borderTopRightRadius: radius,
              borderBottomLeftRadius: expanded === index ? '0px' : radius,
              borderBottomRightRadius: expanded === index ? '0px' : radius,
              marginBottom: '0px',
              marginTop: '16px',
              textAlign: 'left'
            }}
          >
            <Typography
              sx={{
                width: '33%',
                flexShrink: 0,
                fontSize: '18px',
                fontFamily: 'Poppins',
                fontWeight: 600
              }}
            >
              {question.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              border: '1px solid white',
              borderTop: 'none',
              borderBottomLeftRadius: radius,
              borderBottomRightRadius: radius
            }}
          >
            <Divider style={{ background: 'white', marginTop: '-15px', marginBottom: 12 }} />
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 400,
                fontSize: '16px',
                textAlign: 'justify',
                textJustify: 'inter-word'
              }}
            >
              {question.body}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FooterQuestion;
