import React from 'react';
import { Box } from '@mui/material';

interface IProps {
  fullWidth?: boolean;
  children: React.ReactNode;
}

const Wrapper: React.FunctionComponent<IProps> = ({ fullWidth = false, children }) => {
  return (
    <Box
      sx={{
        paddingTop: '120px',
        paddingBottom: '120px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          background: 'rgba(13, 19, 101, 0.8)',
          width: fullWidth
            ? '100%'
            : {
                xs: '90%',
                sm: '90%',
                md: '69%'
              },
          padding: {
            xs: '24px',
            sm: '24px',
            md: '56px'
          },
          borderRadius: '8px'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Wrapper;
