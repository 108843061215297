import React, { useState } from 'react';
//* MUI
import { Chip, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
//* Components
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';
//* Styles
import './Multiselect.scss';
import { useTranslation } from 'react-i18next';

interface IExtraActions {
  label: string;
  icon?: JSX.Element;
  action: () => void;
  variant?: string;
  disabled?: boolean;
}

interface IOptions {
  label: string;
  imageUrl: string;
}

interface IMultiselect {
  options: IOptions[];
  extraActions?: IExtraActions[];
  selectedOptions: string[];
  getSelectedOptions: (selectedOptions: string[]) => void;
}

const Multiselect = ({
  options,
  extraActions,
  selectedOptions,
  getSelectedOptions
}: IMultiselect) => {
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const { t } = useTranslation();

  const renderValue = (selected: string[]) => {
    let value = (
      <span className="multiselect__input__placeholder">{t('gamesPage.providerName')}</span>
    );

    if (selected.length > 0) {
      value = (
        <>
          <span className="multiselect__input__placeholder">{t('gamesPage.Providers')}</span>
          <Chip className="multiselect__input__chip" label={selected.length} size="small" />
        </>
      );
    }

    return value;
  };

  const renderMenuItems = () => {
    const menuItemsArray = options.map((option) => {
      const isSelected = selectedOptions.indexOf(option.label) > -1;
      const menuItemValue = `${option.label.charAt(0).toUpperCase()}${option.label.slice(1)}`;

      return (
        <MenuItem
          key={option.label}
          value={option.label}
          sx={{ '&::after': { backgroundImage: `url(${option.imageUrl})` } }}
          className={`multiselect__list__item ${
            isSelected ? 'multiselect__list__item--selected' : ''
          }`}
        >
          <Checkbox
            className={`multiselect__list__item__checkbox  ${
              isSelected ? 'multiselect__list__item__checkbox--checked' : ''
            }`}
            checked={selectedOptions.indexOf(option.label) > -1}
          />
          <ListItemText primary={menuItemValue} className="multiselect__list__item__text" />
        </MenuItem>
      );
    });

    return menuItemsArray;
  };

  const renderExtraActions = () => {
    const extraActionsArray = extraActions?.map((extraAction) => {
      return (
        <PrimaryButton
          key={extraAction.label}
          icon={extraAction.icon}
          text={extraAction.label}
          onClick={extraAction.action}
          disabled={extraAction.disabled}
          buttonVariant={extraAction.variant}
        />
      );
    });

    return extraActionsArray;
  };

  return (
    <Select
      multiple
      displayEmpty
      className={`multiselect ${selectIsOpen ? 'multiselect--is-open' : ''} `}
      value={selectedOptions}
      renderValue={renderValue}
      onOpen={() => setSelectIsOpen(true)}
      onClose={() => setSelectIsOpen(false)}
      onChange={(event) => getSelectedOptions([...event.target.value])}
      sx={{
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: 'rgba(255, 255, 255, 0.15)'
        }
      }}
      inputProps={{
        'aria-label': 'Without label',
        className: `multiselect__input ${
          selectedOptions.length > 0 ? 'multiselect__input--has-selected-options' : ''
        }`
      }}
      MenuProps={{
        PaperProps: {
          className: 'multiselect__list'
        }
      }}
    >
      {renderMenuItems()}
      <div className="multiselect__list__extra-actions">{renderExtraActions()}</div>
    </Select>
  );
};

export default Multiselect;
