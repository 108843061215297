import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccountPage from './AccountPage';
import VerificationPage from './VerificationPage';
import ButtonTabs from '../Common/Buttons/ButtonTabs';

const MyAccount: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  return (
    <Box sx={{ paddingTop: '112px' }}>
      <Box sx={{ width: { xs: '91%', md: '875px' }, margin: 'auto', textAlign: 'center' }}>
        <Box sx={{ fontSize: '64px', lineHeight: '90px', fontWeight: 700 }}>
          {t('myAccount.title')}
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          <ButtonTabs
            selected={page}
            handleChange={setPage}
            options={[
              { label: t('myAccount.navigation.account') },
              { label: t('myAccount.navigation.verification') }
            ]}
          />
        </Box>
        <Box sx={{ marginTop: '48px' }}>
          {page === 0 && <AccountPage />}
          {page === 1 && <VerificationPage />}
        </Box>
      </Box>
    </Box>
  );
};

export default MyAccount;
