import { ArrowBack, ArrowForward, Autorenew, Check, Close, GppMaybe } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { ITransaction } from '../../models/wallet';
import { CancelTransaction } from '../../queries/wallet';
import { colors } from '../../utils/theme';
import Loader from '../Common/Loader';
import CustomPagination from '../Common/Pagination';
import { getUserCurrencySymbol, handleError } from '../../utils/ui';

interface IProps {
  data: ITransaction[];
  handleRemove: (id: number) => void;
}

const headerStyle: SxProps = {
  padding: '4px 16px',
  color: colors.grey.placeholder,
  textAlign: 'left',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px'
};

const getStatusColor = (status: string) => {
  const statusToLowerCase = status.toLocaleLowerCase();
  return statusToLowerCase === 'pending'
    ? colors.statuses.pending
    : statusToLowerCase === 'completed'
    ? colors.statuses.success
    : colors.statuses.failed;
};

const itemsPerPage = 10;

const TransactionTable: React.FunctionComponent<IProps> = ({ data, handleRemove }: IProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { dispatch, state } = useContext(AppContext);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(CancelTransaction, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.UpdateBalance,
        payload: data.balance
      });
      handleRemove(data.id);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('wallet.historyPage.cancelWithdrawMessage')
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('CancelTransaction');
    }
  });

  const getVisibleData = () =>
    data.slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage);

  // const getNumberOfPages = () => Math.trunc(data.length / itemsPerPage) + 1;

  const getCancelButton = (id: number) => (
    <Box
      sx={{
        color: colors.statuses.failed,
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '20px',
        cursor: 'pointer'
      }}
      onClick={() => mutate(id)}
    >
      {t('wallet.historyPage.cancelWithdraw')}
    </Box>
  );
  return (
    <Box>
      <Box
        sx={{
          padding: '8px 0',
          border: `1px solid ${colors.white}`,
          borderRadius: '8px',
          background: colors.background.grey,
          width: '100%',
          height: '44px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box sx={{ ...headerStyle, width: '336px' }}>{t('wallet.historyPage.typeHeader')}</Box>
        <Box sx={{ ...headerStyle, width: '160px' }}>{t('wallet.historyPage.dateHeader')}</Box>
        <Box sx={{ ...headerStyle, width: '120px' }}>{t('wallet.historyPage.amountHeader')}</Box>
        <Box sx={{ ...headerStyle, width: '120px' }}>{t('wallet.historyPage.statusHeader')}</Box>
      </Box>
      {getVisibleData().map((t, index) => (
        <Box
          key={index}
          sx={{
            border: `1px solid ${colors.white}`,
            borderRadius: '8px',
            background: colors.background.grey,
            width: '100%',
            // height: '53px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '8px',
            textAlign: 'left'
          }}
        >
          <Box
            sx={{
              width: { md: '336px', xs: '62px' },
              padding: '7px 15px',
              display: 'flex',
              alignItems: 'center',
              flexFlow: { xs: 'column', md: 'row' }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '32px',
                height: '32px',
                borderRadius: '100px',
                background:
                  t.type.toLowerCase() === 'deposit'
                    ? colors.statuses.success
                    : colors.statuses.pending,
                marginRight: '8px'
              }}
            >
              {t.type.toLowerCase() === 'deposit' ? (
                <ArrowForward sx={{ width: '20px', height: '20px' }} />
              ) : (
                <ArrowBack sx={{ width: '20px', height: '20px' }} />
              )}
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              {t.type.toLowerCase() === 'withdrawal' &&
                t.status.toLowerCase() === 'pending' &&
                getCancelButton(t.id)}
            </Box>
            <Box sx={{ display: { md: 'block', xs: 'none' } }}>
              <Box sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}>{t.type}</Box>
              <Box
                sx={{
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '17px',
                  color: colors.grey.placeholder
                }}
              >
                {t.id}
              </Box>
              {t.type.toLowerCase() === 'withdrawal' &&
                t.status.toLowerCase() === 'pending' &&
                getCancelButton(t.id)}
            </Box>
          </Box>
          <Box
            sx={{
              width: '160px',
              padding: '17px 16px',
              fontSize: '12px',
              lineHeight: '17px',
              color: colors.grey.placeholder
            }}
          >
            {moment(t.date).format('DD/MM/YYYY HH:mm:ss')}
          </Box>
          <Box
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sx={{
              width: '120px',
              padding: '17.5px 16px',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '16px',
              background: colors.gradients.yellow,
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >{`${getUserCurrencySymbol(state.user.data?.currency)}${t.amount}`}</Box>
          <Box
            sx={{
              width: '120px',
              padding: '13.5px 16px',
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
              lineHeight: '17px'
            }}
          >
            <Box
              sx={{
                minWidth: '24px',
                height: '24px',
                borderRadius: '100px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: getStatusColor(t.status),
                marginRight: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {t.status.toLowerCase() === 'pending' && (
                <Autorenew
                  sx={{ color: getStatusColor(t.status), width: '16px', height: '16px' }}
                />
              )}
              {t.status.toLowerCase() === 'completed' && (
                <Check sx={{ color: getStatusColor(t.status), width: '16px', height: '16px' }} />
              )}
              {t.status.toLowerCase() === 'failed' && (
                <Close sx={{ color: getStatusColor(t.status), width: '16px', height: '16px' }} />
              )}
              {t.status.toLowerCase() === 'declined' && (
                <GppMaybe sx={{ color: getStatusColor(t.status), width: '16px', height: '16px' }} />
              )}
            </Box>
            <Box component="span" sx={{ display: { md: 'flex', xs: 'none' } }}>
              {t.status}
            </Box>
          </Box>
        </Box>
      ))}
      <CustomPagination
        itemsPerPage={itemsPerPage}
        totalItems={data.length}
        page={page}
        setPage={setPage}
        sx={{ marginTop: '8px' }}
      />
      <Loader loading={isLoading} />
    </Box>
  );
};

export default TransactionTable;
