import React, { useContext, useEffect, useState } from 'react';
import { Box, CardMedia, IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ISession, IGameDto, IGameKey } from '../../models/games';
import { colors } from '../../utils/theme';
import { Favorite, FavoriteBorder, Fullscreen } from '@mui/icons-material';
// import PrimaryButton from '../Common/Buttons/PrimaryButton';
// import { t } from 'i18next';
// import CancelButton from '../Common/Buttons/CancelButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PublicRoutes } from '../../enums/RouteEnums';
import Loader from '../Common/Loader';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../AppContext';
import {
  AddFavoriteGameQuery,
  GameQuery,
  RemoveFavoriteGameQuery,
  StartDemoQuery,
  StartGameQuery
} from '../../queries/games';
import { Actions } from '../../enums/ActionEnums';
import { AppConsts } from '../../enums/AppConsts';
import { decodeToken, handleError } from '../../utils/ui';
import { UserModel } from '../../models/account';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
// type Props = {
//   handleClose: () => void;
//   handleFavorite: (id: string) => void;
//   gameSessionData: IDemoSession;
//   gameData: IGameDto;
//   isFavorite: boolean;
// };

const GameWindow: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { state, dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [gameData, setGameData] = useState<IGameDto | null>(null);
  const [session, setSession] = useState<ISession | null>(null);
  const [mobileGame, setMobileGame] = useState(false);
  const [desktopGame, setDesktopGame] = useState(false);
  const [isDemoSession, setIsDemoSession] = useState(true);
  const theme = useTheme<Theme>();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Update the viewport height when the window is resized
    const handleResize = () => {
      setIframeHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const gameQuery = useMutation(GameQuery, {
    onSuccess: ({ data }) => {
      const result = data as IGameDto;
      if (result.realGameUrl?.includes('vivogaming') && isIOS) {
        window.location.href = result.realGameUrl;
      } else {
        setGameData(result);
        startGame(false, !isMobileScreen, result);
      }
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('getGame');
    }
  });

  const startDemoMutation = useMutation(StartDemoQuery, {
    onSuccess: ({ data }) => {
      setSession(data.result as ISession);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('StartDemo');
    }
  });

  const startGameMutation = useMutation(StartGameQuery, {
    onSuccess: ({ data }) => {
      setSession(data.result as ISession);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('StartGame');
    }
  });

  const updateUserInState = (token: string) => {
    const decoded = decodeToken(token);
    dispatch({
      type: Actions.SetUser,
      payload: {
        data: {
          ...(state.user.data as UserModel),
          favGames:
            decoded.groupsid && decoded.groupsid.length
              ? decoded.groupsid.map((g) => parseInt(g))
              : []
        },
        token: token
      }
    });
    localStorage.setItem(AppConsts.AccessToken, token);
  };

  const addToFavGameQuery = useMutation(AddFavoriteGameQuery, {
    onSuccess: ({ data }) => {
      const token = data.accessToken as string;
      updateUserInState(token);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.data} ${t('gamesPage.addToFavoritesPayloadText')}`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('AddFavoriteGameQuery');
    }
  });

  const removeFromFavGameQuery = useMutation(RemoveFavoriteGameQuery, {
    onSuccess: ({ data }) => {
      const token = data.accessToken as string;
      updateUserInState(token);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.data} ${t('gamesPage.removeFromFavoritesPayloadText')}`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('RemoveFavoriteGameQuery');
    }
  });

  useEffect(() => {
    gameQuery.mutate(id as string);
  }, []);

  const handleFullscreen = () => {
    const doc = window.document;
    const docEl = doc.getElementById('game-iframe') as HTMLElement;
    const requestFullScreen =
      docEl.requestFullscreen ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      docEl.mozRequestFullScreen ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      docEl.webkitRequestFullScreen ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      docEl.msRequestFullscreen;
    const cancelFullScreen =
      doc.exitFullscreen ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      doc.mozCancelFullScreen ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      doc.webkitExitFullscreen ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      !doc.mozFullScreenElement &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      !doc.webkitFullscreenElement &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  };

  const handleFavoriteClick = (id: number, gameId: string, name: string) => {
    const game = { gameId, name } as IGameKey;
    if (state.user.data?.favGames.includes(id)) {
      removeFromFavGameQuery.mutate(game);
    } else {
      addToFavGameQuery.mutate(game);
    }
  };

  const handleClose = () => {
    navigate(location.state?.path ?? PublicRoutes.Games, {
      state: { pageNumber: location.state?.pageNumber ?? 1 }
    });
  };

  const startGame = (isDemo: boolean, isDesktop: boolean, data: IGameDto) => {
    if (isDesktop) {
      setDesktopGame(true);
    } else {
      setMobileGame(true);
    }
    const { gameProvider } = data;
    if (isDemo) {
      setIsDemoSession(true);
      if (gameProvider === 'casino25') {
        startDemoMutation.mutate(id as string);
      } else {
        const url = data.demoUrl as string;
        const demo = {
          sessionId: '0',
          sessionUrl: url
        } as ISession;
        setSession(demo);
      }
    } else {
      setIsDemoSession(false);
      if (gameProvider === 'casino25') {
        startGameMutation.mutate(id as string);
      } else {
        const session = {
          sessionId: '0',
          sessionUrl: data.realGameUrl as string
        } as ISession;
        setSession(session);
      }
    }
  };

  return (
    <Box sx={{ paddingTop: '112px', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '1100px', padding: { xs: '16px' } }}>
        {gameData && (
          <Box>
            <Box sx={{ display: 'flex', flexFlow: { xs: 'column', md: 'row' } }}>
              <Box
                sx={{
                  flexGrow: 1,
                  fontWeight: 600,
                  fontSize: '40px',
                  lineHeight: '56px',
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'start' }
                }}
              >
                {gameData.name}
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '16px'
                }}
              >
                {/* <Box
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'grid',
                      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                      gap: '16px'
                    }
                  }}
                >
                  {session === null && (
                    <CancelButton
                      onClick={() => startGame(true, true)}
                      text={t('gamesPage.demoLabel') as string}
                      additionalStyle={{ color: 'common.white' }}
                    />
                  )}
                  {(session === null || isDemoSession) && (
                    <PrimaryButton
                      onClick={() => startGame(false, true)}
                      text={t('gamesPage.playRealButton')}
                    />
                  )}
                </Box> */}
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'start' }}>
                  <IconButton
                    onClick={() => handleFavoriteClick(gameData.id, gameData.gameId, gameData.name)}
                    sx={{
                      p: 0,
                      width: '40px',
                      height: '40px',
                      border: `2px solid ${colors.gradients.yellowRgb}`
                    }}
                  >
                    {state.user.data?.favGames.includes(gameData.id) ? (
                      <Favorite style={{ color: colors.white }} />
                    ) : (
                      <FavoriteBorder style={{ color: colors.white }} />
                    )}
                  </IconButton>
                </Box>
                {session && (
                  <Box sx={{ flexGrow: { xs: 0, md: 1 } }}>
                    <IconButton
                      onClick={() => handleFullscreen()}
                      sx={{
                        p: 0,
                        width: '40px',
                        height: '40px',
                        border: `2px solid ${colors.gradients.yellowRgb}`
                      }}
                    >
                      <Fullscreen style={{ color: colors.white }} />
                    </IconButton>
                  </Box>
                )}
                <Box sx={{ flexGrow: { xs: 0, md: 1 } }}>
                  <IconButton
                    onClick={handleClose}
                    sx={{
                      p: 0,
                      width: '40px',
                      height: '40px',
                      border: `2px solid ${colors.gradients.yellowRgb}`
                    }}
                  >
                    <CloseIcon style={{ color: colors.white }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: { xs: '257px', md: '600px' },
                marginTop: '32px'
              }}
            >
              {desktopGame && session ? (
                <CardMedia
                  id="game-iframe"
                  component="iframe"
                  src={session.sessionUrl}
                  allowfullscreen="allowfullscreen"
                  sx={{ border: 'none' }}
                />
              ) : (
                <Box
                  component="img"
                  src={gameData.thumbnailLarge}
                  sx={{ width: '100%', height: '100%', objectFit: 'fill' }}
                />
              )}
            </Box>
            {/* <Box sx={{ display: { xs: 'flex', md: 'none' }, marginTop: '24px' }}>
              <Box sx={{ flexGrow: 0, width: '160px' }}>
                <PrimaryButton
                  onClick={() => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    startGame(false, false);
                  }}
                  text={t('gamesPage.playRealButton')}
                  // additionalStyle={{ paddingX: '30px' }}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Box sx={{ flexGrow: 0, width: '160px' }}>
                <CancelButton
                  onClick={() => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    startGame(true, false);
                  }}
                  text={t('gamesPage.demoLabel') as string}
                  additionalStyle={{ color: 'common.white' }}
                />
              </Box>
            </Box> */}
            {mobileGame && (
              <Box
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                  width: '100vw',
                  height: '100vh'
                }}
              >
                {session && (
                  <CardMedia
                    id="game-iframe-mobile"
                    component="iframe"
                    src={session.sessionUrl}
                    sx={{ border: 'none', height: iframeHeight }}
                    allowfullscreen="allowfullscreen"
                  />
                )}
              </Box>
            )}
          </Box>
        )}
        <Loader
          loading={
            gameQuery.isLoading || startDemoMutation.isLoading || startGameMutation.isLoading
          }
        />
      </Box>
    </Box>
  );
};

export default GameWindow;
