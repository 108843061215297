export enum Query {
  AllGames = 'AllGames',
  PassedTournaments = 'PassedTournaments',
  AllTournaments = 'AllTournaments',
  FreeSpins = 'FreeSpins',
  AllChallenges = 'AllChallenges',
  WheelSpin = 'WheelSpin',
  KycToken = 'KycToken',
  Languages = 'Languages',
  AllElements = 'AllElements',
  VerificationStatus = 'VerificationStatus'
}

export enum Payment {
  Payneteasy = 'Payneteasy',
  Gumball = 'GumballPay',
  Amald = 'Amald',
  SkyChain = 'SkyChain',
  Finateco = 'Finateco'
}
