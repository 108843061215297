import { AccountCircle, Close, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Dialog, IconButton, Link } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { AppConsts } from '../../enums/AppConsts';
import { PublicRoutes } from '../../enums/RouteEnums';
import { LoginQuery } from '../../queries/account';
import ResetPasswordPage from '../Account/ForgerPasswordPage';
import { getIconColor } from '../Account/SignupPage';
import PrimaryButton from './Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from './Buttons/SecondaryButton';
import { Transition } from './Dialogs/DialogBox';
import InputField from './Fields/InputField';
import Loader from './Loader';
import { handleError } from '../../utils/ui';

type IErrors = {
  username: string | null;
  password: string | null;
};

const defaultErrorsObj: IErrors = {
  username: null,
  password: null
};

const LoginPopup: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(AppContext);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [visiblePass, setVisiblePass] = useState(false);
  const { mutate, isLoading } = useMutation(LoginQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.SetUser,
        payload: {
          token: data.accessToken,
          data
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      dispatch({ type: Actions.HideLoginPopup });
      if (state.loginPopup.direction) {
        navigate(state.loginPopup.direction, { state: state.loginPopup.state });
      }
      resetForm();
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries();
    }
  });

  const handleUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setFormErrors({ ...formErrors, username: value.length < 4 ? t('errors.shortUsername') : null });
    setUsername(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setFormErrors({ ...formErrors, password: value.length < 4 ? t('errors.shortPass') : null });
    setPassword(value);
  };

  const handleSignin = () => {
    let hasErrors = false;
    const errors: IErrors = {
      username: null,
      password: null
    };

    if (username.length < 4) {
      hasErrors = true;
      errors.username = t('errors.shortUsername');
    }
    if (password.length < 4) {
      hasErrors = true;
      errors.password = t('errors.shortPass');
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      mutate({ username, password });
    }
  };

  const resetForm = () => {
    setUsername('');
    setPassword('');
    setFormErrors(defaultErrorsObj);
  };

  const keyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSignin();
    }
  };

  return (
    <Dialog
      open={state.loginPopup.open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          margin: 0,
          width: '450px',
          padding: '24px',
          display: 'flex',
          flexFlow: 'column',
          gap: '24px'
        }
      }}
      sx={{
        '.MuiDialog-container': {
          alignItems: 'center'
        }
      }}
      onKeyDown={keyPress}
    >
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            color: 'primary.main',
            fontWeight: 700,
            fontSize: '40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {t('account.signIn')}
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch({ type: Actions.HideLoginPopup });
            resetForm();
          }}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <InputField
        id="username"
        value={username}
        onChange={handleUsernameChange}
        label={t('account.username') as string}
        isLabelMainColor={true}
        errorMsg={formErrors.username}
        placeholder={t('account.usernamePlaceHolder') as string}
        startIcon={
          <AccountCircle sx={{ fill: getIconColor(username, formErrors.username), zIndex: 2 }} />
        }
      />
      <InputField
        id="password"
        value={password}
        onChange={handlePasswordChange}
        label={t('account.password') as string}
        isLabelMainColor={true}
        errorMsg={formErrors.password}
        placeholder={t('account.passPlaceholder') as string}
        type={visiblePass ? 'text' : 'password'}
        startIcon={<Lock sx={{ fill: getIconColor(password, formErrors.password), zIndex: 2 }} />}
        endIcon={
          <IconButton
            aria-label="toggle new password visibility"
            onClick={() => setVisiblePass(!visiblePass)}
          >
            {visiblePass ? (
              <VisibilityOff
                sx={{ fill: getIconColor(password, formErrors.password), zIndex: 2 }}
              />
            ) : (
              <Visibility sx={{ fill: getIconColor(password, formErrors.password), zIndex: 2 }} />
            )}
          </IconButton>
        }
      />
      <Box sx={{ justifyContent: 'end', display: 'flex' }}>
        <Link
          onClick={() => setOpenResetPass(true)}
          variant="body2"
          color="info.main"
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: '16px'
          }}
        >
          {t('account.forgotPass')}
        </Link>
      </Box>
      <PrimaryButton text={t('account.signIn')} onClick={handleSignin} />
      <SecondaryButton
        text={t('account.createAccount')}
        onClick={() => {
          dispatch({ type: Actions.HideLoginPopup });
          navigate(PublicRoutes.Signup);
          resetForm();
        }}
      />
      {openResetPass && <ResetPasswordPage handleClose={() => setOpenResetPass(false)} />}
      <Loader loading={isLoading} />
    </Dialog>
  );
};

export default LoginPopup;
