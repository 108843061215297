import React from 'react';
//* MUI
import { SxProps } from '@mui/system';
import { Button } from '@mui/material';
//* Utils
import { colors } from '../../../../utils/theme';
//* Styles
import './PrimaryButton.scss';

interface IProps {
  text: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  additionalStyle?: SxProps;
  icon?: JSX.Element;
  buttonVariant?: string;
  className?: string;
}

const outlinedStyles = {
  color: '#fff !important',
  height: '46px',
  border: '2px solid #fff',
  background: 'transparent',
  '&:hover': {
    color: `${colors.primary.main} !important`
  }
};

const PrimaryButton: React.FunctionComponent<IProps> = ({
  icon,
  text,
  onClick,
  disabled,
  buttonVariant,
  className,
  additionalStyle
}: IProps) => {
  return (
    <Button
      className={`primary-button ${className}`}
      fullWidth
      variant="contained"
      sx={
        buttonVariant === 'outlined'
          ? { ...outlinedStyles }
          : {
              height: '46px',
              background: colors.gradients.yellow,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              '&:hover': {
                background: (t) => t.palette.common.white,
                boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
              },
              color: 'primary.main',
              ...additionalStyle
            }
      }
      onClick={onClick}
      disabled={disabled ?? false}
      startIcon={icon}
    >
      {text}
    </Button>
  );
};

export default PrimaryButton;
