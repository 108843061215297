import React from 'react';
import { Box } from '@mui/system';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import visaLogo from '../../assets/payments/visa.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import paysafeLogo from '../../assets/payments/paysafe.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jcbLogo from '../../assets/payments/jcb.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import skrillLogo from '../../assets/payments/skrill.svg';

type ExternalLogoType = {
  [key: string]: {
    src: string;
    link: string;
    alt: string;
  };
};

const externalLinks: ExternalLogoType = {
  visa: {
    src: visaLogo,
    link: 'https://usa.visa.com/',
    alt: 'visa-log'
  },
  paysafe: {
    src: paysafeLogo,
    link: 'https://www.paysafe.com/',
    alt: 'paysafe-logo'
  },
  jcb: {
    src: jcbLogo,
    link: 'https://www.global.jcb/',
    alt: 'jcb-logo'
  },
  skrill: {
    src: skrillLogo,
    link: 'https://www.skrill.com/',
    alt: 'skrill-logo'
  }
};

interface IProps {
  name: 'visa' | 'paysafe' | 'jcb' | 'skrill';
}

const ExternalLogo: React.FunctionComponent<IProps> = ({ name }: IProps) => {
  return (
    <Box sx={{ width: '67.37px', height: '32px' }}>
      <a href={externalLinks[name].link} target="_blank" style={{ textDecoration: 'none' }}>
        <img src={externalLinks[name].src} alt={externalLinks[name].alt} />
      </a>
    </Box>
  );
};

export default ExternalLogo;
