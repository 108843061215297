import React from 'react';
import { Box } from '@mui/material';
import { CircleLoader } from 'react-spinners';

const InnerLoader: React.FunctionComponent = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircleLoader loading color="white" size={150} />
    </Box>
  );
};

export default InnerLoader;
