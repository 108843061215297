import React, { useEffect, useState } from 'react';
import FooterParagraph from './FooterParagraph';
import Wrapper from './Wrapper';
import WrapperTitle from './WrapperTitle';
import FooterQuestion from './FooterQuestion';
import { useTranslation } from 'react-i18next';
import { RouteNames } from '../../enums/RouteEnums';
import { useMutation, useQueryClient } from 'react-query';
import { FooterPageQuery } from '../../queries/footer';
import Loader from '../Common/Loader';
import { addPageTranslation } from '../../i18n/config';
import { PageModel } from '../../models/footerPage';

type IProps = {
  title: string;
};

export type Paragraph = {
  title: string;
  body: string;
};

const FooterPage: React.FunctionComponent<IProps> = ({ title }) => {
  const { i18n, t } = useTranslation();
  const queryClient = useQueryClient();
  const [page, setPage] = useState<PageModel | null>(null);

  const faq = [
    {
      title: t('helpCenterPage.faq.gettingStarted.title'),
      body: t('helpCenterPage.faq.gettingStarted.body')
    },
    {
      title: t('helpCenterPage.faq.accountSettings.title'),
      body: t('helpCenterPage.faq.accountSettings.body')
    },
    {
      title: t('helpCenterPage.faq.bonus.title'),
      body: t('helpCenterPage.faq.bonus.body')
    },
    {
      title: t('helpCenterPage.faq.payments.title'),
      body: t('helpCenterPage.faq.payments.body')
    },
    {
      title: t('helpCenterPage.faq.freeSpins.title'),
      body: t('helpCenterPage.faq.freeSpins.body')
    }
  ];

  const footerPage = useMutation(FooterPageQuery, {
    onSuccess: ({ data }) => {
      if (data) {
        addPageTranslation(data as PageModel);
      }
      setPage(data);
    },
    onError: ({ response }) => {},
    onSettled: () => {
      queryClient.invalidateQueries('FooterPageQuery');
    }
  });

  useEffect(() => {
    footerPage.mutate(title);
  }, [title]);

  return (
    <Wrapper>
      <WrapperTitle text={t(`footer.${title}`) as string} />
      <FooterParagraph
        content={{
          title:
            page?.translations.find((t) => t.language.toUpperCase() === i18n.language.toUpperCase())
              ?.title ?? '',
          body:
            page?.translations.find((t) => t.language.toUpperCase() === i18n.language.toUpperCase())
              ?.body ?? ''
        }}
      />
      {title === RouteNames.HelpCenter && <FooterQuestion questions={faq} />}
      <Loader loading={footerPage.isLoading} />
    </Wrapper>
  );
};

export default FooterPage;
