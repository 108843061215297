import React from 'react';
import { Box, SxProps } from '@mui/material';
import { colors } from '../../../utils/theme';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

interface IProps {
  isRight?: boolean;
  sx?: SxProps;
  handleClick: () => void;
}

const ArrowButton: React.FunctionComponent<IProps> = ({ isRight = false, sx, handleClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.primary.second,
        boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.3)',
        border: `2px solid ${colors.gradients.yellowRgb}`,
        padding: '6px',
        borderRadius: '50px',
        cursor: 'pointer',
        ...sx
      }}
      onClick={() => handleClick()}
    >
      {isRight ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
    </Box>
  );
};

export default ArrowButton;
