import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TooltipComponent } from '../Common/TooltipComponent';
import shield from '../../assets/tournaments/homePage/shield.svg';
import avatar from '../../assets/tournaments/homePage/avatar.svg';
import alarmClock from '../../assets/tournaments/alarmClock.svg';
import singleCoin from '../../assets/tournaments/singleCoin.svg';
import { DATE_FORMAT, radius } from '../../utils/tournaments';
import { colors } from '../../utils/theme';
import PrimaryButton from '../Common/Buttons/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../enums/RouteEnums';
import { gameContainerProps } from './HomeGameSection';
import { getUserCurrencySymbol, handleError } from '../../utils/ui';
import { useMutation, useQueryClient } from 'react-query';
import { AllTournamentsQuery } from '../../queries/tournaments';
import { ITournament } from '../../models/tournaments';
import { AppContext } from '../../AppContext';

const TournamentBanner: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const { dispatch, state } = useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [tournament, setTournament] = useState<ITournament | null>(null);
  const tournamentsQuery = useMutation(AllTournamentsQuery, {
    onSuccess: ({ data }) => {
      const tournamentsData = data as ITournament[];
      if (tournamentsData.length) {
        setTournament(tournamentsData[0]);
      }
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('GetTournaments');
    }
  });

  useEffect(() => {
    tournamentsQuery.mutate();
  }, []);

  return (
    <>
      {tournament && (
        <Box sx={gameContainerProps()}>
          <Box
            sx={{
              display: 'flex',
              flexFlow: { xs: 'column', md: 'row' },
              borderRadius: '8px',
              background:
                'linear-gradient(180deg, #00DFDF 0.01%, #2575FC 38.54%, #620779 100%), #0D1365'
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexFlow: { xs: 'column', md: 'row' },
                gap: { xs: 0, md: '10px' }
              }}
            >
              <Box
                sx={{
                  flexGrow: 0,
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: { xs: '24px', md: '32px 16px' }
                }}
              >
                <Box sx={{ fontSize: '40px', fontWeight: 600, lineHeight: '56px' }}>
                  {t('navigation.tournaments')}
                </Box>
                <Box
                  component="img"
                  src={shield}
                  sx={{ width: { xs: '229px', md: '307px' }, height: { xs: '240px', md: '323px' } }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '32px',
                  alignItems: { xs: 'center', md: 'start' },
                  padding: { xs: '24px', md: '32px' }
                }}
              >
                <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'start' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexFlow: 'row',
                      gap: '8px',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '34px'
                      }}
                    >
                      {tournament.name}
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                      <Tooltip
                        title={
                          tournament.descriptions.find(
                            (d) => d.language.toUpperCase() === i18n.language.toUpperCase()
                          )?.text ?? tournament.descriptions[0].text
                        }
                        placement="top-start"
                      >
                        <TooltipComponent />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <Box component="img" src={alarmClock} sx={{ width: '16px', height: '16px' }} />
                    <Box
                      sx={{ fontSize: '12px', lineHeight: '17px', color: colors.grey.background }}
                    >{`${moment(tournament.startDate).format(DATE_FORMAT)} - ${moment(
                      tournament.endDate
                    ).format(DATE_FORMAT)}`}</Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: '10px 14px',
                    border: `2px solid ${colors.gradients.yellowRgb}`,
                    borderRadius: radius,
                    background: colors.background.grey,
                    display: 'flex',
                    flexFlow: 'column',
                    gap: '4px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '40px',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'start' }}>
                      <Box sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '25px' }}>
                        {t('tournaments.card.poolPrize')}
                      </Box>
                      <Box
                        sx={{
                          fontSize: '14px',
                          lineHeight: '20px',
                          color: colors.gradients.yellowRgb
                        }}
                      >
                        {tournament.winType}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          color: colors.gradients.yellowRgb,
                          fontSize: '64px',
                          fontWeight: 700,
                          lineHeight: '90px'
                        }}
                      >
                        {tournament.prizePool}
                      </Box>
                      <Box
                        component="img"
                        src={singleCoin}
                        sx={{
                          width: '40px',
                          height: '40px'
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexFlow: 'row',
                      gap: '12px'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexFlow: 'row',
                        gap: '4px',
                        alignItems: 'center'
                      }}
                    >
                      <Box sx={{ fontSize: '14px', lineHeight: '20px', color: colors.grey.main }}>
                        {t('tournaments.card.minBet')}
                      </Box>
                      <Box sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '22px' }}>
                        {getUserCurrencySymbol(state.user.data?.currency)}{' '}
                        {tournament.minBet.toFixed(2)}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        borderRight: '1px solid white'
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexFlow: 'row',
                        gap: '4px',
                        alignItems: 'center'
                      }}
                    >
                      <Box sx={{ fontSize: '14px', lineHeight: '20px', color: colors.grey.main }}>
                        {t('tournaments.card.duration')}
                      </Box>
                      <Box sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '22px' }}>
                        {`${tournament.duration} ${t('tournaments.card.days')}`}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <PrimaryButton
                  text={t('tournaments.card.joinTournamentButton')}
                  onClick={() => navigate(PublicRoutes.Tournaments)}
                  additionalStyle={{ width: '100%' }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                height: { xs: '300px', md: '443px' },
                overflow: 'hidden',
                position: 'relative',
                borderTopRightRadius: { xs: 'none', md: radius },
                borderBottomRightRadius: radius
              }}
            >
              <Box
                component="img"
                src={avatar}
                sx={{
                  width: '100%',
                  height: '100%',
                  visibility: 'hidden'
                }}
              />
              <Box
                component="img"
                src={avatar}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: { xs: '100%', md: 'auto' }
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TournamentBanner;
