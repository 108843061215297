import React, { useEffect } from 'react';
import { Box, Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import { Transition } from './DialogBox';
import CustomIconButton from '../Buttons/CustomIconButton/CustomIconButton';
import { ActionData } from '../../../models/buttons';
import CheckIcon from '@mui/icons-material/Check';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import './InfoBox.scss';

interface IProps {
  title: string;
  subtitle?: string | JSX.Element;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  actionData?: ActionData;
  extraHeight?: boolean;
}

const InfoBox: React.FunctionComponent<IProps> = ({
  title,
  subtitle,
  open,
  onClose,
  children,
  actionData,
  extraHeight
}: IProps) => {
  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden';
    }

    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, [open]);

  const disabled = actionData && (actionData.state === 'Claimed' || actionData.state === 'Locked');
  const getIcon = () => {
    switch (actionData?.state) {
      case 'Claimed':
        return <CheckIcon />;
      case 'Locked':
        return <LockOutlinedIcon />;
      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: `info-box ${extraHeight ? 'info-box--extended-height' : ''}` }}
      TransitionComponent={Transition}
    >
      <Box className="info-box__close-button">
        <CustomIconButton icon={<CloseIcon />} onClick={onClose} />
      </Box>
      <DialogContent className="info-box__content">
        <Box className="info-box__content__title">{title}</Box>
        {subtitle && <Box className="info-box__content__subtitle">{subtitle}</Box>}
        <Box className="info-box__content__children">{children}</Box>
        {actionData && (
          <Box
            className={`info-box__button-container info-box__button-container--${actionData.position}`}
          >
            <PrimaryButton
              className={`info-box__content__button info-box__content__button--${actionData?.state?.toLowerCase()}`}
              text={actionData.text}
              onClick={actionData.onClick}
              disabled={disabled}
              icon={getIcon()}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InfoBox;
