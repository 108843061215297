import React from 'react';
import { InputLabel } from '@mui/material';
import { colors } from '../../../utils/theme';

interface IProps {
  id: string;
  label: string;
  isMainColor?: boolean;
}

const Label: React.FunctionComponent<IProps> = ({ id, label, isMainColor }: IProps) => {
  return (
    <InputLabel
      htmlFor={id}
      sx={{
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '14px',
        textTransform: 'uppercase',
        letterSpacing: '0.05em',
        color: isMainColor ? colors.primary.main : 'common.white',
        marginBottom: '6px',
        textAlign: 'left'
      }}
    >
      {label}
    </InputLabel>
  );
};

export default Label;
