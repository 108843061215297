import React from 'react';
import { Box } from '@mui/material';
import { GroupButtonOption } from '../../../models/buttons';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ButtonTabs from '../Buttons/ButtonTabs';
import DropDownSelect from '../Buttons/DropDownSelect';

interface IProps {
  selected: number;
  handleChange: (index: number) => void;
  options: GroupButtonOption[];
}

const SubNavigation: React.FunctionComponent<IProps> = ({
  selected,
  handleChange,
  options
}: IProps) => {
  const theme = useTheme();
  const notMobileResolution = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box>
      {notMobileResolution ? (
        <ButtonTabs selected={selected} options={options} handleChange={handleChange} />
      ) : (
        <DropDownSelect selected={selected} options={options} handleChange={handleChange} />
      )}
    </Box>
  );
};

export default SubNavigation;
