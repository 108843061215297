import React, { useContext, useState } from 'react';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import { IPaymentMethodDto, withdrawPaymentMethods } from '../../utils/paymentConstants';
import { colors } from '../../utils/theme';
import {
  accordionDetailsProps,
  accordionProps,
  containerProps,
  dropDownIconProps,
  getAccordionSummaryProps,
  getCheckIcon,
  getStepStyles,
  radius,
  summaryTitleProps,
  titleLabelProps
} from './DepositPage';
import { Withdraw } from '../../queries/wallet';
import { useMutation, useQueryClient } from 'react-query';
import { Actions } from '../../enums/ActionEnums';
import Loader from '../Common/Loader';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes, PublicRoutes } from '../../enums/RouteEnums';
import InputField from '../Common/Fields/InputField';
import IdentityStatus from '../Common/IdentityStatus';
import { VerificationStatuses } from '../../models/account';
import CancelButton from '../Common/Buttons/CancelButton';
import { getUserCurrencySymbol, handleError } from '../../utils/ui';
import { IWithdrawModel } from '../../models/wallet';

type IErrors = {
  iban: string | null;
  bitcoinWallet: string | null;
  amount: string | null;
};

const defaultErrorsObj: IErrors = {
  iban: null,
  bitcoinWallet: null,
  amount: null
};

const WithdrawPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [expanded, setExpanded] = useState(0);
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [selectedPayment, setSelectedPayment] = useState<IPaymentMethodDto | null>(null);
  const [iban, setIban] = useState('');
  const [bitcoinWallet, setBitcoinWallet] = useState('');
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const userCurrency = getUserCurrencySymbol(state.user.data?.currency);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(Withdraw, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('wallet.withdrawPage.successfull')
        }
      });
      dispatch({
        type: Actions.UpdateBalance,
        payload: data
      });
      navigate(PublicRoutes.Home);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('withdraw');
    }
  });

  const handleChange = (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : -1);
  };

  const isPaymentMethodValid = () => {
    if (selectedPayment) {
      if (selectedPayment.name === 'Bitcoin' && isBitcoinWalletValid()) {
        return true;
      } else {
        if (isIbanValid()) {
          return true;
        }
      }
    }
    return false;
  };

  const isIbanValid = () => iban.length > 10;
  const isBitcoinWalletValid = () => bitcoinWallet.length > 10;

  const isAmountValid = (value: number | null) =>
    value !== null &&
    value > 0 &&
    value <= (state.user.data?.realBalance as number) &&
    value >= 200 &&
    value <= 5000;

  const makeWithdraw = () => {
    const model: IWithdrawModel = {
      amount: selectedAmount as number,
      iban: iban as string,
      bitcoinWallet: bitcoinWallet as string
    };
    mutate(model);
  };

  const isPaymentPermitted = (p: IPaymentMethodDto) =>
    p.permissions === undefined ||
    !p.permissions.length ||
    (p.permissions.length &&
      state.user.data &&
      p.permissions.includes(state.user.data.verificationStatus));

  const getBoxProps = (p: IPaymentMethodDto) => {
    let result = {
      borderRadius: radius,
      width: { md: '227px', xs: '131.5px' },
      height: '80px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `2px solid ${colors.white}`,
      background: colors.grey.disabled,
      '& img': {
        filter: 'invert(0.5)'
      },
      '&:hover': {
        background: colors.grey.disabled,
        '& img': {
          filter: 'invert(0.5)'
        }
      }
    };

    if (isPaymentPermitted(p)) {
      result = {
        ...result,
        ...{
          cursor: 'pointer',
          background: selectedPayment === p ? colors.white : colors.background.grey,
          '&:hover': {
            background: `${colors.white}`,
            '& img': {
              filter: 'invert(100%)'
            }
          },
          '& img': {
            filter: selectedPayment === p ? 'invert(100%)' : 'invert(0)'
          }
        }
      };
    }
    return result;
  };

  const handleIbanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      iban: value.length > 10 ? null : t('wallet.withdrawPage.ibanHelperText')
    });
    setIban(value);
    setBitcoinWallet('');
  };

  const handleBitcoinWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      bitcoinWallet: value.length > 10 ? null : t('wallet.withdrawPage.bitcoinHelperText')
    });
    setBitcoinWallet(value);
    setIban('');
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value ? parseInt(event.currentTarget.value) : null;
    setFormErrors({
      ...formErrors,
      amount: isAmountValid(value)
        ? null
        : t('wallet.withdrawPage.amountHelperText').toString().replace(/\€/g, userCurrency)
    });
    setSelectedAmount(value);
  };

  const isUserVerified = () =>
    state.user.data?.verificationStatus === VerificationStatuses.Verified;

  return (
    <Box sx={{ ...containerProps, paddingTop: { xs: '24px', md: '56px' } }}>
      {!isUserVerified() && (
        <Box sx={{ display: 'flex', gap: '10px', flexFlow: 'column', textAlign: 'left' }}>
          <Box
            sx={{
              display: 'flex',
              flexFlow: { xs: 'column-reverse', md: 'row' },
              gap: { xs: '24px', md: '48px' }
            }}
          >
            <Box sx={{ flexGrow: 1, fontSize: '16px', lineHeight: '22px' }}>
              {t('wallet.withdrawPage.accountStatusWarning')}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Box
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  color: colors.grey.placeholder,
                  whiteSpace: 'nowrap',
                  marginBottom: '4px'
                }}
              >
                {t('wallet.withdrawPage.accountStatusLabel')}
              </Box>
              <IdentityStatus
                status={state.user.data?.verificationStatus as VerificationStatuses}
                textStyle={{ fontSize: '14px', lineHeight: '22px' }}
                iconBoxStyle={{ width: '24px', height: '24px' }}
                iconStyle={{ width: '16px', height: '16px' }}
                wrapperStyle={{ justifyContent: { xs: 'start', md: 'end' } }}
              />
            </Box>
          </Box>
          <Box>
            <CancelButton
              text={t('wallet.withdrawPage.verifyAccountButton') as string}
              onClick={() => navigate(AuthRoutes.MyAccount)}
              additionalStyle={{
                color: 'common.white',
                width: 'auto',
                height: 'auto',
                fontSize: '12px',
                fontWeight: 600
              }}
            />
          </Box>
        </Box>
      )}
      <Accordion
        expanded={expanded === 0}
        onChange={handleChange(0)}
        sx={{ ...accordionProps, marginTop: `${isUserVerified() ? '-16px' : '32px'} !important` }}
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharp sx={dropDownIconProps} />}
          aria-controls={`withdraw-panel-payment-method-content`}
          id={`withdraw-panel-payment-method-header`}
          sx={getAccordionSummaryProps(0, expanded)}
        >
          <Box sx={titleLabelProps}>
            {isPaymentMethodValid() ? getCheckIcon() : <Box sx={getStepStyles(0, expanded)}>1</Box>}
            {t('wallet.depositPage.paymentMethod')}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={accordionDetailsProps}>
          <Divider style={{ background: 'white', marginTop: '-8px', marginBottom: 12 }} />
          <Box sx={{ display: 'flex', flexFlow: 'row wrap', gap: { xs: '8px', md: '16px' } }}>
            {withdrawPaymentMethods.map((p, index) => (
              <Box
                key={index}
                sx={getBoxProps(p)}
                onClick={() => {
                  if (isPaymentPermitted(p)) {
                    setSelectedPayment(p);
                  }
                }}
              >
                <Box component="img" src={p.img as string} alt={'logo'} />
              </Box>
            ))}
          </Box>
          {selectedPayment && (
            <Box sx={{ marginTop: '10px' }}>
              <InputField
                id={selectedPayment.name === 'Bitcoin' ? 'bitcoin wallet' : 'iban'}
                value={selectedPayment.name === 'Bitcoin' ? bitcoinWallet : iban}
                onChange={
                  selectedPayment.name === 'Bitcoin' ? handleBitcoinWalletChange : handleIbanChange
                }
                label={
                  selectedPayment.name === 'Bitcoin'
                    ? (t('wallet.withdrawPage.bitcoinLabel') as string)
                    : (t('wallet.withdrawPage.ibanLabel') as string)
                }
                errorMsg={
                  selectedPayment.name === 'Bitcoin' ? formErrors.bitcoinWallet : formErrors.iban
                }
                placeholder={
                  selectedPayment.name === 'Bitcoin'
                    ? (t('wallet.withdrawPage.bitcoinPlaceholder') as string)
                    : (t('wallet.withdrawPage.ibanPlaceholder') as string)
                }
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 1} onChange={handleChange(1)} sx={accordionProps}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharp sx={dropDownIconProps} />}
          aria-controls={`withdraw-panel-payment-amount-content`}
          id={`withdraw-panel-payment-amount-header`}
          sx={getAccordionSummaryProps(1, expanded)}
        >
          <Box sx={titleLabelProps}>
            {isAmountValid(selectedAmount) ? (
              getCheckIcon()
            ) : (
              <Box sx={getStepStyles(1, expanded)}>2</Box>
            )}
            {t('wallet.withdrawPage.paymentAmount')}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={accordionDetailsProps}>
          <Divider style={{ background: 'white', marginTop: '-8px', marginBottom: 12 }} />
          <Box sx={{ marginTop: '10px' }}>
            <InputField
              id="amount"
              type="number"
              value={selectedAmount || ''}
              onChange={handleAmountChange}
              label={t('wallet.withdrawPage.amountLabel') as string}
              errorMsg={formErrors.amount}
              placeholder={t('wallet.depositPage.amountPlaceholder') as string}
            />
          </Box>
          <Box sx={{ ...summaryTitleProps, marginTop: '24px' }}>
            <Button
              fullWidth
              variant="contained"
              disabled={
                !isUserVerified() || !isPaymentMethodValid() || !isAmountValid(selectedAmount)
              }
              onClick={() => makeWithdraw()}
              sx={{
                background: colors.gradients.yellow,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                '&:hover': {
                  background: (t) => t.palette.common.white,
                  boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
                },
                color: 'primary.main'
              }}
            >
              {t('wallet.withdrawPage.withdraw')}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Loader loading={isLoading} />
    </Box>
  );
};

export default WithdrawPage;
