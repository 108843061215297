import Dice from '../assets/gameTypes/Dice.svg';
import Heart from '../assets/gameTypes/Heart.svg';
import Crown from '../assets/gameTypes/Crown.svg';
import Cards from '../assets/gameTypes/Cards.svg';
import Cherry from '../assets/gameTypes/Cherry.svg';
import Roulette from '../assets/gameTypes/Roulette.svg';
//* Reel svg is not yet needed
// import Reel from '../assets/gameTypes/Reel.svg';

import { GameTypesEnum } from '../enums/GamesEnums';
import { IGameDto } from '../models/games';
import { Theme } from '@mui/material';
import { AddFavoriteGameQuery, RemoveFavoriteGameQuery } from '../queries/games';
import { QueryClient, useMutation } from 'react-query';
import { ActionType } from '../models/appcontext';
import { Actions } from '../enums/ActionEnums';
import { AppConsts } from '../enums/AppConsts';
import { UserModel } from '../models/account';
import { decodeToken, handleError } from './ui';
import { InitialStateType } from '../AppContext';
import { NavigateFunction } from 'react-router-dom';

interface IGameType {
  img: unknown;
  type: GameTypesEnum;
  displayText: string;
}

export const gameTypes: IGameType[] = [
  {
    img: Dice,
    type: GameTypesEnum.All,
    displayText: 'gamesPage.gameTypes.all'
  },
  {
    img: Cherry,
    type: GameTypesEnum.Slots,
    displayText: 'gamesPage.gameTypes.slots'
  },
  {
    img: Crown,
    type: GameTypesEnum.Jackpots,
    displayText: 'gamesPage.gameTypes.jackpots'
  },
  {
    img: Cards,
    type: GameTypesEnum.Table,
    displayText: 'gamesPage.gameTypes.table'
  },
  {
    img: Roulette,
    type: GameTypesEnum.Live,
    displayText: 'gamesPage.gameTypes.live'
  },
  {
    img: Heart,
    type: GameTypesEnum.Favorite,
    displayText: 'gamesPage.gameTypes.favorite'
  }
];

export interface IGameProvider {
  provider: string;
  displayName: string;
  games: IGameDto[];
  imageUrl: string;
}

const useNextAvailableSpot = (arr: unknown[][], elementIndex: number) => {
  for (let rowIndex = 0; rowIndex < arr.length; rowIndex++) {
    const row = arr[rowIndex];
    for (let colIndex = 0; colIndex < row.length; colIndex++) {
      const currentCol = row[colIndex];
      const nextCol = row[colIndex + 1];
      if (currentCol === 'X' && colIndex + 1 !== row.length && nextCol === 'X') {
        arr[rowIndex][colIndex] = elementIndex;
        arr[rowIndex][colIndex + 1] = elementIndex;
        arr[rowIndex + 1][colIndex] = elementIndex;
        arr[rowIndex + 1][colIndex + 1] = elementIndex;
        return;
      }
    }
  }
};

export const reOrderGames = (visibleGames: IGameDto[], windowWidth: number, theme: Theme) => {
  let indexToLook = 0;
  if (windowWidth < theme.breakpoints.values.sm) {
    indexToLook = 1;
  } else if (windowWidth < theme.breakpoints.values.md) {
    indexToLook = 2;
  } else if (windowWidth < theme.breakpoints.values.xl) {
    indexToLook = 3;
  } else if (windowWidth >= theme.breakpoints.values.xl) {
    indexToLook = 5;
  }

  const emptyCell = 'X';
  const totalPromoted = visibleGames.filter((v) => v.isPromoted === true).length;
  const totalNumberOfLines =
    Math.ceil((visibleGames.length + totalPromoted * (4 - 1)) / (indexToLook + 1)) + 1;
  const items = [...visibleGames];
  const maze = [];
  for (let i = 0; i < totalNumberOfLines; i++) {
    maze.push(new Array(indexToLook + 1).fill(emptyCell));
  }
  for (let rowIndex = 0; rowIndex < maze.length; rowIndex++) {
    const row = maze[rowIndex];
    for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
      const column = row[columnIndex];
      if (column === emptyCell) {
        const currentElement = items.shift();
        if (currentElement) {
          const elementIndex = visibleGames.findIndex((r) => r.gameId === currentElement.gameId);
          if (currentElement.isPromoted) {
            if (columnIndex + 1 === row.length) {
              if (maze[rowIndex + 1][0] !== emptyCell || maze[rowIndex + 1][1] !== emptyCell) {
                useNextAvailableSpot(maze, elementIndex);
              } else {
                maze[rowIndex + 1][0] = elementIndex;
                maze[rowIndex + 1][1] = elementIndex;
                maze[rowIndex + 2][0] = elementIndex;
                maze[rowIndex + 2][1] = elementIndex;
              }
              columnIndex--;
            } else {
              if (
                maze[rowIndex][columnIndex] !== emptyCell ||
                maze[rowIndex][columnIndex + 1] !== emptyCell
              ) {
                useNextAvailableSpot(maze, elementIndex);
                columnIndex--;
              } else {
                maze[rowIndex][columnIndex] = elementIndex;
                maze[rowIndex][columnIndex + 1] = elementIndex;
                maze[rowIndex + 1][columnIndex] = elementIndex;
                maze[rowIndex + 1][columnIndex + 1] = elementIndex;
              }
            }
          } else {
            maze[rowIndex][columnIndex] = elementIndex;
          }
        }
      }
    }
  }

  const orderedIndexes = [...new Set(maze.flat().filter((n) => n !== emptyCell))];
  const result = orderedIndexes.map((index) => visibleGames[index]);
  return result;
};

export const startGame = (navigate: NavigateFunction, game: IGameDto, options?: unknown) =>
  navigate(`/games/${game.gameId}-${game.name.replaceAll(' ', '')}`, options ?? undefined);

const updateUserInState = (
  state: InitialStateType,
  dispatch: React.Dispatch<ActionType>,
  token: string
) => {
  const decoded = decodeToken(token);
  dispatch({
    type: Actions.SetUser,
    payload: {
      data: {
        ...(state.user.data as UserModel),
        favGames:
          decoded.groupsid && decoded.groupsid.length
            ? decoded.groupsid.map((g) => parseInt(g))
            : []
      },
      token: token
    }
  });
  localStorage.setItem(AppConsts.AccessToken, token);
};

export const getRemoveFromFavGameQuery = (
  state: InitialStateType,
  dispatch: React.Dispatch<ActionType>,
  queryClient: QueryClient
) =>
  useMutation(RemoveFavoriteGameQuery, {
    onSuccess: ({ data }) => {
      const token = data.accessToken as string;
      updateUserInState(state, dispatch, token);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.data} removed from favorites`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('RemoveFavoriteGameQuery');
    }
  });

export const getAddToFavGameQuery = (
  state: InitialStateType,
  dispatch: React.Dispatch<ActionType>,
  queryClient: QueryClient
) =>
  useMutation(AddFavoriteGameQuery, {
    onSuccess: ({ data }) => {
      const token = data.accessToken as string;
      updateUserInState(state, dispatch, token);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.data} added to favorites`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('AddFavoriteGameQuery');
    }
  });
