import ArrowButton from './Buttons/ArrowButton';
import { slideRight } from '../../utils/ui';
import React, { useRef } from 'react';
import { Box, SxProps } from '@mui/material';
//* Styles
import './SingleLineContainer.css';

const scrollShift = 240;

interface IProps {
  children: React.ReactNode;
  additionalStyle?: SxProps;
}

const SingleLineArrowContainer: React.FunctionComponent<IProps> = ({
  children,
  additionalStyle
}: IProps) => {
  const ref = useRef(null);
  const getArrowButtonStyle = (isRight?: boolean) => {
    return {
      position: 'absolute',
      top: { xs: '154px', md: '226px' },
      ...(isRight && { right: { xs: 0, md: '70px' } }),
      ...(!isRight && { left: { xs: 0, md: '70px' } }),
      zIndex: 4,
      display: 'flex'
    } as SxProps;
  };

  return (
    <Box
      className="single-line-container"
      sx={{
        gap: { xs: '7px', md: '10px' },
        ...additionalStyle
      }}
      ref={ref}
    >
      <ArrowButton handleClick={() => slideRight(ref, -scrollShift)} sx={getArrowButtonStyle()} />
      {children}
      <ArrowButton
        isRight
        handleClick={() => slideRight(ref, scrollShift)}
        sx={getArrowButtonStyle(true)}
      />
    </Box>
  );
};

export default SingleLineArrowContainer;
