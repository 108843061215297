import moment from 'moment';
import prizePlace1 from '../assets/tournaments/prizePlaces/01.svg';
import prizePlace2 from '../assets/tournaments/prizePlaces/02.svg';
import prizePlace3 from '../assets/tournaments/prizePlaces/03.svg';
import prizePlace4 from '../assets/tournaments/prizePlaces/04.svg';
import prizePlace5 from '../assets/tournaments/prizePlaces/05.svg';
import prizePlace6 from '../assets/tournaments/prizePlaces/06.svg';
import prizePlace7 from '../assets/tournaments/prizePlaces/07.svg';
import prizePlace8 from '../assets/tournaments/prizePlaces/08.svg';
import prizePlace9 from '../assets/tournaments/prizePlaces/09.svg';
import prizePlace10 from '../assets/tournaments/prizePlaces/10.svg';

export const radius = '8px';
export const DATE_FORMAT = 'DD/MM/YYYY';

export const getPrizeIcon = (place: number) => {
  switch (place) {
    case 1:
      return prizePlace1;
    case 2:
      return prizePlace2;
    case 3:
      return prizePlace3;
    case 4:
      return prizePlace4;
    case 5:
      return prizePlace5;
    case 6:
      return prizePlace6;
    case 7:
      return prizePlace7;
    case 8:
      return prizePlace8;
    case 9:
      return prizePlace9;
    case 10:
      return prizePlace10;
    default:
      return prizePlace10;
  }
};

export const calculateTimeLeft = ({
  isActive,
  endDate,
  startDate
}: {
  isActive: boolean;
  endDate: string | undefined;
  startDate: string | undefined;
}) => {
  const diff = moment.utc(isActive ? endDate : startDate).valueOf() - moment.utc().valueOf();
  const timeLeftCalculated = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  if (diff > 0) {
    timeLeftCalculated.days = Math.floor(diff / (1000 * 60 * 60 * 24));
    timeLeftCalculated.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    timeLeftCalculated.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    timeLeftCalculated.seconds = Math.floor((diff % (1000 * 60)) / 1000);
  }

  return timeLeftCalculated;
};

export const getPrettyNumber = (num: number) => ('0' + num).slice(-2);
