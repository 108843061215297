import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SxProps
} from '@mui/material';
import React from 'react';

export interface RadioGroupOption {
  value: unknown;
  label: string;
}

interface IProps {
  id: string;
  title?: string;
  isDisabled?: boolean;
  value: any;
  handleChange: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  options: RadioGroupOption[];
  containerStyles?: SxProps;
}

const CustomRadioButton: React.FunctionComponent<IProps> = ({
  id,
  title,
  isDisabled,
  value,
  handleChange,
  options,
  containerStyles
}: IProps) => {
  return (
    <Box sx={{ ...containerStyles }}>
      <FormControl disabled={isDisabled ?? false} sx={{ width: '100%', height: '100%' }}>
        {title && <FormLabel id={id}>{title}</FormLabel>}
        <RadioGroup
          aria-labelledby={id}
          name={`controlled-${id}-group`}
          value={value}
          onClick={handleChange}
          row
          sx={{ height: '100%' }}
        >
          {options.map((o, i) => (
            <FormControlLabel
              key={i}
              value={o.value}
              control={
                <Radio
                  color="info"
                  sx={{
                    '&, &.Mui-checked': {
                      color: 'white'
                    }
                  }}
                />
              }
              label={o.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CustomRadioButton;
