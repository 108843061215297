import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Box, Grow, Slide, SxProps } from '@mui/material';
import CancelButton from '../Common/Buttons/CancelButton';
import { IGameDto, IGameKey } from '../../models/games';
import GameCard from '../Games/GameCard';
import { getAddToFavGameQuery, getRemoveFromFavGameQuery, startGame } from '../../utils/games';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { useQueryClient } from 'react-query';
import SingleLineArrowContainer from '../Common/SingleLineCointainer';
//* Styles
import './HomeGameSection.scss';

interface IProps {
  title: string;
  buttonProps?: { title: string; handleClick: () => void };
  isSingleRow?: boolean;
  gameChipsLength: number;
  games: IGameDto[];
  itemsContainerClass?: string;
}

const GameContainerHeader = forwardRef(function GameContainerHeader(
  {
    title,
    buttonProps,
    isSingleRow
  }: {
    title: string;
    buttonProps?: { title: string; handleClick: () => void };
    isSingleRow: boolean;
  },
  ref
) {
  return (
    <Box sx={{ display: 'flex', pl: '0%' }} ref={ref}>
      <Box
        sx={{
          fontSize: { xs: '24px', md: '24px', lg: '36px' },
          fontWeight: 600,
          lineHeight: '34px',
          flexGrow: 1
        }}
      >
        {title}
      </Box>
      {buttonProps && (
        <CancelButton
          onClick={buttonProps.handleClick}
          text={buttonProps.title}
          additionalStyle={{
            color: 'common.white',
            width: 'auto',
            height: '34px',
            fontSize: '12px',
            fontWeight: 600,
            flexGrow: 0,
            mr: isSingleRow ? '5%' : '0%'
          }}
        />
      )}
    </Box>
  );
});

const MultiLineGameContainer = ({
  children,
  itemsContainerClass
}: {
  children: React.ReactNode;
  itemsContainerClass: string | undefined;
}) => {
  return <Box className={`${itemsContainerClass} multiline-container`}>{children}</Box>;
};

const GamesSection = ({
  games,
  isSingleRow,
  gameChipsLength
}: {
  games: IGameDto[];
  isSingleRow: boolean;
  gameChipsLength: number;
}) => {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const addToFavGameQuery = getAddToFavGameQuery(state, dispatch, queryClient);
  const removeFromFavGameQuery = getRemoveFromFavGameQuery(state, dispatch, queryClient);

  const handleFavoriteClick = (id: number, gameId: string, name: string) => {
    const game = { gameId, name } as IGameKey;
    if (state.user.data?.favGames.includes(id)) {
      removeFromFavGameQuery.mutate(game);
    } else {
      addToFavGameQuery.mutate(game);
    }
  };
  return (
    <>
      {games.map((game, index) => (
        <Grow in timeout={gameChipsLength * 200 + index * 100} key={`transition-${index}`}>
          <Box
            key={index}
            className={`${
              isSingleRow ? 'multiline-container__game--single-row' : 'multiline-container__game'
            }`}
          >
            <GameCard
              data={game}
              setGame={() => startGame(navigate, game)}
              isFavorite={state.user.data?.favGames.includes(game.id) ?? false}
              handleFavorite={() => handleFavoriteClick(game.id, game.gameId, game.name)}
              sx={{ width: '100%', height: '100%' }}
              showCount={isSingleRow}
              isSpecial={!isSingleRow && index === 0}
              isHome
            />
          </Box>
        </Grow>
      ))}
    </>
  );
};

export const gameContainerProps = (isSingleRow?: boolean) => {
  return {
    marginBottom: { xs: '60px', md: '80px', lg: '80px' },
    display: 'flex',
    flexFlow: 'column',
    gap: { xs: '24px', md: '32px' },
    position: 'relative',
    pl: '5%',
    ...(!isSingleRow && containerPaddingX)
  } as SxProps;
};

export const containerPaddingX: SxProps = {
  paddingX: '5%'
};

const HomeGameSection: React.FunctionComponent<IProps> = ({
  title,
  buttonProps,
  isSingleRow = false,
  gameChipsLength,
  games,
  itemsContainerClass
}) => {
  const [rowItems, setRowItems] = useState(6);

  const setItemsBasedOnScreenWidth = () => {
    const screenWidth = window.innerWidth;
    let newValue = 6;
    if (screenWidth <= 500) {
      newValue = 3;
    } else if (screenWidth <= 570) {
      newValue = 3;
    } else if (screenWidth <= 920) {
      newValue = 4;
    } else if (screenWidth <= 1230) {
      newValue = 5;
    } else if (screenWidth <= 1475) {
      newValue = 6;
    } else if (screenWidth <= 1660) {
      newValue = 7;
    } else if (screenWidth <= 1975) {
      newValue = 8;
    } else {
      newValue = 9;
    }
    setRowItems(newValue);
  };

  useEffect(() => {
    // Initial height calculation
    setItemsBasedOnScreenWidth();

    // Listen for window resize events and update height
    window.addEventListener('resize', setItemsBasedOnScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setItemsBasedOnScreenWidth);
    };
  }, []);

  return (
    <Box sx={gameContainerProps(isSingleRow)}>
      <Slide in timeout={gameChipsLength * 100}>
        <GameContainerHeader title={title} buttonProps={buttonProps} isSingleRow={isSingleRow} />
      </Slide>
      {isSingleRow ? (
        <SingleLineArrowContainer>
          <GamesSection gameChipsLength={gameChipsLength} games={games} isSingleRow={isSingleRow} />
        </SingleLineArrowContainer>
      ) : (
        <MultiLineGameContainer itemsContainerClass={itemsContainerClass}>
          <GamesSection gameChipsLength={gameChipsLength} games={games} isSingleRow={isSingleRow} />
        </MultiLineGameContainer>
      )}
    </Box>
  );
};

export default HomeGameSection;
