import React, { useContext } from 'react';
import { Box, Dialog, IconButton } from '@mui/material';
import { Transition } from './Dialogs/DialogBox';
import { Close } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import diamonds from '../../assets/gifs/diamonds.gif';
import PrimaryButton from './Buttons/PrimaryButton/PrimaryButton';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from './Buttons/SecondaryButton';

const Popup = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const closePopUp = () => {
    dispatch({
      type: Actions.HidePopup
    });
  };

  const closeWheelPopUp = () => {
    dispatch({
      type: Actions.HideWheelPopup
    });
  };

  const handlePrimaryButton = () => {
    closePopUp();
    const isWheelBonusMoneyWin =
      state.popup.redirectUrl && state.popup.redirectUrl.includes('bonuses');
    if (state.popup.redirectUrl && !isWheelBonusMoneyWin) {
      navigate(state.popup.redirectUrl as string);
    }
  };

  const handleSecondaryButton = () => {
    closePopUp();
    closeWheelPopUp();
    navigate(state.popup.redirectUrl as string);
  };

  return (
    <Dialog
      open={state.popup.open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          margin: 0,
          width: { xs: '343px', sm: '562px', md: '562px' },
          padding: '24px',
          display: 'flex',
          flexFlow: 'column',
          position: 'absolute',
          top: '120px',
          borderRadius: '8px'
        }
      }}
      sx={{
        '.MuiDialog-container': {
          alignItems: 'center'
        }
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            component="img"
            src={diamonds}
            alt={'diamonds'}
            sx={{ width: '120px', height: '100px' }}
          />
        </Box>
        <IconButton
          aria-label="close"
          onClick={closePopUp}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '8px'
        }}
      >
        {state.popup.isCongrat && (
          <Box
            component="p"
            sx={{
              height: '25px',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontWeight: 600,
              fontSize: '18px',
              color: (t) => t.palette.primary.main
            }}
          >
            {t('common.congratulations')}
          </Box>
        )}
        <Box
          component="p"
          sx={{
            textAlign: 'justify',
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: '16px',
            color: (t) => t.palette.grey[300],
            lineHeight: '140%',
            m: 0
          }}
        >
          {state.popup.state}
        </Box>
      </Box>
      <Box sx={{ mt: '24px', display: 'flex', gap: '10px' }}>
        {state.popup.redirectUrl && state.popup.redirectUrl.includes('bonuses') && (
          <SecondaryButton text={t('account.claimNow')} onClick={handleSecondaryButton} />
        )}
        <PrimaryButton
          text={state.popup.buttonText ? state.popup.buttonText : t('account.continue')}
          onClick={handlePrimaryButton}
        />
      </Box>
    </Dialog>
  );
};

export default Popup;
