import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
//* MUI
import { Box } from '@mui/material';
//* Icons
import CloseIcon from '@mui/icons-material/Close';
import { SearchOutlined } from '@mui/icons-material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
//* Components
import Loader from '../Common/Loader';
import GamesOverview from './GamesOverview';
import InputField from '../Common/Fields/InputField';
import Multiselect from '../Common/Selects/Multiselect/Multiselect';
import CategoryFilter from '../Common/Filters/CategoryFilter/CategoryFilter';
//* Enums
import { Actions } from '../../enums/ActionEnums';
import { AppConsts } from '../../enums/AppConsts';
import { GameTypesEnum } from '../../enums/GamesEnums';
//* Models
import { UserModel } from '../../models/account';
import { IGameDto, IGameKey } from '../../models/games';
//* Queries
import { AddFavoriteGameQuery, AllGamesQuery, RemoveFavoriteGameQuery } from '../../queries/games';
//* Utils
import { gameTypes, IGameProvider } from '../../utils/games';
import { decodeToken, handleError } from '../../utils/ui';
//* Styles
import './GamesPage.scss';
import CustomIconButton from '../Common/Buttons/CustomIconButton/CustomIconButton';
import FullscreenList from '../Common/FullscreenList/FullscreenList';

const GamesPage: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const { dispatch, state } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedGameType, setSelectedGameType] = useState(
    location.state?.type ?? GameTypesEnum.All
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState('All');
  const [games, setGames] = useState<IGameDto[]>(state.games);
  const [filteredGames, setFilteredGames] = useState<IGameDto[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(location.state?.pageNumber ?? 1);
  const [filtersAreVisible, setFiltersAreVisible] = useState(true);
  const [fullscreenListIsVisible, setFullscreenListIsVisible] = useState(false);

  useEffect(() => {
    if (state.games.length === 0) {
      allGames.mutate();
    }
  }, []);

  useEffect(() => {
    filterGames(games);
  }, [selectedGameType, selectedSubCategory, selectedProviders, searchValue]);

  useEffect(() => {
    if (selectedProviders.length === 0) {
      filterGames(games);
    }
  }, [selectedProviders]);

  useEffect(() => {
    setFiltersVisibility();

    window.addEventListener('resize', setFiltersVisibility);

    return () => {
      window.removeEventListener('resize', setFiltersVisibility);
    };
  }, []);

  const allGames = useMutation(AllGamesQuery, {
    onSuccess: ({ data }) => {
      const currentGames = data as IGameDto[];

      dispatch({
        type: Actions.SetGames,
        payload: currentGames
      });
      setGames(currentGames);
      filterGames(currentGames);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('GetGames');
    }
  });

  const getGameProviderDto = (games: IGameDto[]) => {
    const result: IGameProvider[] = [];

    for (let i = 0; i < games.length; i++) {
      const currentGame = games[i];
      const currentProvider = currentGame.sectionName;
      const providerIndex = result.findIndex((r) => r.provider === currentProvider);
      const displayName =
        currentGame.sectionFriendlyName && currentGame.sectionFriendlyName.length > 0
          ? currentGame.sectionFriendlyName
          : currentProvider;

      if (providerIndex === -1) {
        result.push({
          displayName,
          games: [currentGame],
          provider: currentProvider,
          imageUrl: currentGame.sectionImageUrl
        });
      } else {
        result[providerIndex] = {
          displayName,
          provider: currentProvider,
          games: [...result[providerIndex].games, currentGame],
          imageUrl: currentGame.sectionImageUrl
        };
      }
    }

    return result;
  };

  const getMultiselectOptions = () => {
    const gameProviderDto = getGameProviderDto(games);
    const multiselectOptions = gameProviderDto.map((gameProvider) => ({
      label: gameProvider.displayName,
      imageUrl: gameProvider.imageUrl
    }));

    return multiselectOptions;
  };

  const multiselectOptions = getMultiselectOptions();

  const updateUserInState = (token: string) => {
    const decoded = decodeToken(token);
    dispatch({
      type: Actions.SetUser,
      payload: {
        data: {
          ...(state.user.data as UserModel),
          favGames:
            decoded.groupsid && decoded.groupsid.length
              ? decoded.groupsid.map((g) => parseInt(g))
              : []
        },
        token: token
      }
    });
    localStorage.setItem(AppConsts.AccessToken, token);
  };

  const addToFavGameQuery = useMutation(AddFavoriteGameQuery, {
    onSuccess: ({ data }) => {
      const token = data.accessToken as string;
      updateUserInState(token);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.data} ${t('gamesPage.addToFavoritesPayloadText')}`
        }
      });
      setPage(page);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('AddFavoriteGameQuery');
    }
  });

  const removeFromFavGameQuery = useMutation(RemoveFavoriteGameQuery, {
    onSuccess: ({ data }) => {
      const token = data.accessToken as string;
      updateUserInState(token);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.data} ${t('gamesPage.removeFromFavoritesPayloadText')}`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('RemoveFavoriteGameQuery');
    }
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
    setPage(1);
  };

  const handleFavoriteClick = (id: number, gameId: string, name: string) => {
    const game = { gameId, name } as IGameKey;
    if (state.user.data?.favGames.includes(id)) {
      removeFromFavGameQuery.mutate(game);
    } else {
      addToFavGameQuery.mutate(game);
    }
  };

  const startGame = (game: IGameDto) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    navigate(`/games/${game.gameId}-${game.name.replaceAll(' ', '')}`, {
      state: { pageNumber: page }
    });
  };

  const handleChangeGameType = (type: string) => {
    setSelectedGameType(type);
  };

  const handleChangeSubCategoryFilter = (type: string) => {
    setSelectedSubCategory(type);
  };

  const handleSelectedProviders = (selectedProviders: string[]) => {
    setSelectedProviders(selectedProviders);
  };

  const handleFullscreenListSelectedOptions = (selectedOption: string) => {
    let selectedOptions: string[] = [...selectedProviders];
    const selectedProviderIsSelected = selectedProviders.includes(selectedOption);

    if (selectedProviderIsSelected) {
      selectedOptions = selectedOptions.filter((provider) => provider !== selectedOption);
    } else {
      selectedOptions.push(selectedOption);
    }

    setSelectedProviders(selectedOptions);
  };

  const handleClearProviders = () => {
    setSelectedProviders([]);
    filterGames(games);
  };

  // const handleApplyProviders = () => {
  //   filterGames(games);
  // };

  const subCategoryFilterArray = [
    t('gamesPage.gameCategories.all'),
    t('gamesPage.gameCategories.classic'),
    t('gamesPage.gameCategories.popular'),
    t('gamesPage.gameCategories.top'),
    t('gamesPage.gameCategories.new'),
    t('gamesPage.gameCategories.featured')
  ];

  const providersMultiselectExtraActions = [
    {
      label: 'Clear',
      variant: 'outlined',
      icon: <CloseIcon />,
      action: () => handleClearProviders()
    }
    // {
    //   label: 'Apply',
    //   action: () => handleApplyProviders()
    // }
  ];

  const filterGames = (games: IGameDto[]) => {
    let gamesWithAppliedFilters;

    switch (selectedGameType) {
      case GameTypesEnum.Slots:
        gamesWithAppliedFilters = games.filter((game) => game.gameType === 'Slot');
        break;
      case GameTypesEnum.Live:
        gamesWithAppliedFilters = games.filter((game) => game.gameType === 'Live');
        break;
      case GameTypesEnum.Table:
        gamesWithAppliedFilters = games.filter((game) => game.gameType === 'Table');
        break;
      case GameTypesEnum.Jackpots:
        gamesWithAppliedFilters = games.filter((game) => game.isJackpot);
        break;
      case GameTypesEnum.Favorite:
        gamesWithAppliedFilters = state.user?.data
          ? games.filter((game) => state.user?.data?.favGames.includes(game.id))
          : [];
        break;
      default:
        gamesWithAppliedFilters = games;
        break;
    }

    if (selectedSubCategory !== 'All') {
      const subCategoryFilter = `is${selectedSubCategory}` as keyof IGameDto;

      gamesWithAppliedFilters = gamesWithAppliedFilters.filter(
        (game: IGameDto) => game[subCategoryFilter]
      );
    }

    if (selectedProviders.length > 0) {
      const gamesWithAppliedProviders = gamesWithAppliedFilters.filter((game: IGameDto) => {
        const gameHasSelectedProviders = selectedProviders.some((provider) =>
          game.sectionFriendlyName.includes(provider)
        );

        if (gameHasSelectedProviders) {
          return game;
        }
      });

      gamesWithAppliedFilters = gamesWithAppliedProviders;
    }

    if (searchValue.length > 0) {
      const gamesWithAppliedSearch = gamesWithAppliedFilters.filter((g) =>
        selectedGameType === GameTypesEnum.Provider
          ? g.sectionName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
          : g.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      );

      gamesWithAppliedFilters = gamesWithAppliedSearch;
    }

    setFilteredGames(gamesWithAppliedFilters);
  };

  const setFiltersVisibility = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200) {
      setFiltersAreVisible(true);
    } else {
      setFiltersAreVisible(false);
    }
  };

  const toggleFullscreenList = () => {
    setFullscreenListIsVisible(!fullscreenListIsVisible);
  };

  const renderFullscreenListExtraActions = () => {
    return (
      <div className="fullscreen-list__extra-actions">
        {subCategoryFilterArray.map((subCategoryFilter) => (
          <CategoryFilter
            size="xxs"
            key={subCategoryFilter}
            text={subCategoryFilter}
            filterType={subCategoryFilter}
            selectedType={selectedSubCategory}
            onClick={handleChangeSubCategoryFilter}
          />
        ))}
      </div>
    );
  };

  const fullscreenListExtraActions = renderFullscreenListExtraActions();

  const handleFullscreenListClearFilters = () => {
    toggleFullscreenList();
    handleClearProviders();
    setSelectedSubCategory('All');
    filterGames(games);
  };

  const handleApplyFullscreenListFilters = () => {
    toggleFullscreenList();
    filterGames(games);
  };

  const fullscreenListActions = [
    {
      label: 'Clear',
      variant: 'outlined',
      icon: <CloseIcon />,
      action: () => handleFullscreenListClearFilters()
    },
    {
      label: 'Apply',
      action: () => handleApplyFullscreenListFilters()
    }
  ];

  return (
    <Box className="nav-page">
      <Box className="category-filter-row">
        {gameTypes.map((gameType, index) => (
          <CategoryFilter
            key={index}
            image={gameType.img}
            filterType={gameType.type}
            text={t(gameType.displayText)}
            onClick={handleChangeGameType}
            selectedType={selectedGameType}
          />
        ))}
      </Box>
      {filtersAreVisible && (
        <Box className="category-filter-row">
          {subCategoryFilterArray.map((subCategoryFilter) => (
            <CategoryFilter
              size="xs"
              key={subCategoryFilter}
              text={subCategoryFilter}
              filterType={subCategoryFilter}
              selectedType={selectedSubCategory}
              onClick={handleChangeSubCategoryFilter}
            />
          ))}
        </Box>
      )}
      <Box className="additional-filter-row">
        <InputField
          id="searchGames"
          value={searchValue}
          onChange={handleSearchChange}
          placeholder={
            selectedGameType === GameTypesEnum.Provider
              ? (t('gamesPage.searchPlaceholderForProvider') as string)
              : (t('gamesPage.searchPlaceholder') as string)
          }
          isSecondaryStyle={true}
          endIcon={<SearchOutlined sx={{ color: '#7CACFD', width: '20px', height: '20px' }} />}
        />
        {filtersAreVisible && (
          <Multiselect
            options={multiselectOptions}
            selectedOptions={selectedProviders}
            getSelectedOptions={handleSelectedProviders}
            extraActions={providersMultiselectExtraActions}
          />
        )}
        {!filtersAreVisible && (
          <CustomIconButton
            icon={<FilterAltOutlinedIcon />}
            onClick={toggleFullscreenList}
            badgeContent={selectedProviders.length}
          />
        )}
        {fullscreenListIsVisible && (
          <FullscreenList
            searchable
            title="Filter"
            options={multiselectOptions}
            actions={fullscreenListActions}
            isOpen={fullscreenListIsVisible}
            handleClose={toggleFullscreenList}
            selectedOptions={selectedProviders}
            extraControls={fullscreenListExtraActions}
            getSelectedOptions={handleFullscreenListSelectedOptions}
          />
        )}
      </Box>
      <Box sx={{ marginTop: '32px' }}>
        <GamesOverview
          page={page}
          setPage={setPage}
          startGame={startGame}
          games={filteredGames}
          handleFavoriteClick={handleFavoriteClick}
        />
      </Box>
      <Loader loading={allGames.isLoading} />
    </Box>
  );
};

export default GamesPage;
