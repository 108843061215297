import React, { useState } from 'react';
import {
  Box,
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Tooltip
} from '@mui/material';
import { IErrors, getBorderColor, inputPropsStyle, getIconColor } from '../SignupPage';
import { labelStyle } from '../SigninPage';
import { useTranslation } from 'react-i18next';
import { AccountCircle, Lock, VisibilityOff, Visibility, Payments } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { SxProps, Theme } from '@mui/material/styles';
import { currencies, CurrencyType } from '../../../utils/currencies';

interface IProps {
  formErrors: IErrors;
  username: string;
  handleUsernameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  password: string;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  confirmPassword: string;
  handleConfirmPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currency: CurrencyType | null;
  handleCurrencyChange: (_: any, value: CurrencyType | null) => void;
  termsAndCond: boolean;
  handleTermsAndCondChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  smsPromo: boolean;
  handleSmsPromoChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  emailPromo: boolean;
  handleEmailPromoChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const checkBoxStyle: React.CSSProperties = {
  padding: 0
};

const formControlLabelStyle: SxProps<Theme> = {
  marginTop: {
    xs: '5px',
    sm: '5px',
    md: '15px'
  }
};

const checkBoxLabel = (label: string) => {
  return (
    <Box
      sx={{
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: {
          xs: '12px',
          sm: '12px',
          md: '15px'
        },
        lineHeight: {
          xs: '15px',
          sm: '15px',
          md: '22px'
        },
        color: '#838A99'
      }}
    >
      {label}
    </Box>
  );
};

const Step3: React.FunctionComponent<IProps> = ({
  formErrors,
  username,
  handleUsernameChange,
  password,
  handlePasswordChange,
  confirmPassword,
  handleConfirmPasswordChange,
  currency,
  handleCurrencyChange,
  termsAndCond,
  handleTermsAndCondChange,
  smsPromo,
  handleSmsPromoChange,
  emailPromo,
  handleEmailPromoChange
}: IProps) => {
  const { t } = useTranslation();
  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleConfirmPass, setVisibleConfirmPass] = useState(false);
  return (
    <Box>
      <Box>
        <InputLabel htmlFor="username" style={labelStyle}>
          {t('account.username')}
        </InputLabel>
        <TextField
          id="username"
          name="username"
          margin="normal"
          variant="outlined"
          fullWidth
          required
          value={username}
          onChange={handleUsernameChange}
          error={formErrors.username !== null}
          helperText={formErrors.username ? formErrors.username : ''}
          sx={{
            height: '46px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: getBorderColor(username)
              }
            }
          }}
          inputProps={{ sx: inputPropsStyle }}
          placeholder={t('account.usernamePlaceHolder')}
          color="info"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle sx={{ fill: getIconColor(username, formErrors.username) }} />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box>
        <InputLabel htmlFor="password" style={labelStyle}>
          {t('account.password')}
        </InputLabel>
        <Tooltip title={t('account.passwordRequirements') as string} placement="top">
          <TextField
            id="password"
            name="password"
            margin="normal"
            variant="outlined"
            type={visiblePass ? 'text' : 'password'}
            fullWidth
            required
            value={password}
            onChange={handlePasswordChange}
            error={formErrors.password !== null}
            helperText={formErrors.password ? formErrors.password : ''}
            sx={{
              height: '46px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: getBorderColor(password)
                }
              }
            }}
            inputProps={{ sx: inputPropsStyle }}
            placeholder={t('account.passPlaceholder')}
            color="info"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock sx={{ fill: getIconColor(password, formErrors.password) }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setVisiblePass(!visiblePass)}
                  >
                    {visiblePass ? (
                      <VisibilityOff sx={{ fill: getIconColor(password, formErrors.password) }} />
                    ) : (
                      <Visibility sx={{ fill: getIconColor(password, formErrors.password) }} />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Tooltip>
      </Box>
      <Box>
        <InputLabel htmlFor="confirmPassword" style={labelStyle}>
          {t('account.confirmPasswordLabel')}
        </InputLabel>
        <Tooltip title={t('account.passwordRequirements') as string} placement="top">
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            margin="normal"
            variant="outlined"
            type={visibleConfirmPass ? 'text' : 'password'}
            fullWidth
            required
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={formErrors.confirmPassword !== null}
            helperText={formErrors.confirmPassword ? formErrors.confirmPassword : ''}
            sx={{
              height: '46px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: getBorderColor(confirmPassword)
                }
              }
            }}
            inputProps={{ sx: inputPropsStyle }}
            placeholder={t('account.confirmPasswordPlaceHolder')}
            color="info"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock sx={{ fill: getIconColor(confirmPassword, formErrors.confirmPassword) }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={() => setVisibleConfirmPass(!visibleConfirmPass)}
                  >
                    {visibleConfirmPass ? (
                      <VisibilityOff
                        sx={{ fill: getIconColor(confirmPassword, formErrors.confirmPassword) }}
                      />
                    ) : (
                      <Visibility
                        sx={{ fill: getIconColor(confirmPassword, formErrors.confirmPassword) }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Tooltip>
      </Box>
      <Box>
        <InputLabel htmlFor="currency" style={labelStyle}>
          {t('account.currencyLabel')}
        </InputLabel>
        <Autocomplete
          id="currencyAutocomplate"
          fullWidth
          value={currency}
          onChange={handleCurrencyChange}
          options={currencies}
          getOptionLabel={(option) => option.code}
          renderInput={(params) => (
            <TextField
              {...params}
              id="currencyInput"
              name="currencyInput"
              margin="normal"
              variant="outlined"
              error={formErrors.currency !== null}
              helperText={formErrors.currency ? formErrors.currency : ''}
              sx={{
                height: '46px',
                '& .MuiOutlinedInput-root': {
                  height: '46px',
                  padding: '0 !important',
                  '& fieldset': {
                    borderColor: getBorderColor(currency?.code)
                  }
                }
              }}
              placeholder={t('account.currencyPlaceHolder')}
              color="info"
              inputProps={{
                ...params.inputProps,
                sx: inputPropsStyle
                // autoComplete: 'new-password' // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start" style={{ paddingLeft: '13px' }}>
                    <Payments sx={{ fill: getIconColor(currency?.code, formErrors.country) }} />
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          mt: { xs: '4px', sm: '4px', md: '20px' },
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center'
        }}
      >
        <Box sx={{ width: '310px' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAndCond}
                  onChange={handleTermsAndCondChange}
                  size="small"
                  color="info"
                  sx={checkBoxStyle}
                  required
                />
              }
              label={checkBoxLabel(t('account.agreeTermsAndConditions'))}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={smsPromo}
                  onChange={handleSmsPromoChange}
                  size="small"
                  color="info"
                  sx={checkBoxStyle}
                />
              }
              label={checkBoxLabel(t('account.agreeSmsPromotions'))}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailPromo}
                  onChange={handleEmailPromoChange}
                  size="small"
                  color="info"
                  sx={checkBoxStyle}
                />
              }
              label={checkBoxLabel(t('account.agreeEmailPromotions'))}
              sx={formControlLabelStyle}
            />
          </FormGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default Step3;
