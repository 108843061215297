import React from 'react';
import { t } from 'i18next';
//* MUI
import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
//* Components
import { MobileMenuItem } from '../../../models/navigation';
//* Styles
import './MenuItem.scss';

interface IProps {
  item: MobileMenuItem;
  onClick: (path: string) => void;
}

const MenuItem: React.FunctionComponent<IProps> = ({ item, onClick }) => {
  return (
    <div className="menu-item-container" onClick={() => onClick(item.path)}>
      <SvgIcon component={item.icon as SvgIconComponent} />
      {t(item.text)}
    </div>
  );
};

export default MenuItem;
