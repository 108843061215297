import React from 'react';
import { SxProps } from '@mui/system';
import { Button } from '@mui/material';
import { colors } from '../../../utils/theme';

interface IProps {
  text: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  additionalStyle?: SxProps;
}

const CancelButton: React.FunctionComponent<IProps> = ({
  text,
  onClick,
  disabled,
  additionalStyle
}: IProps) => {
  return (
    <Button
      fullWidth
      variant="outlined"
      disabled={disabled ?? false}
      sx={{
        color: 'primary.main',
        // boxShadow: '0px 0px 5px rgba(37, 117, 252, 0.5)',
        filter: 'drop-shadow(0px 0px 10px rgba(255, 212, 27, 0.5))',
        height: '46px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '22px',
        '&:hover': {
          background: colors.white,
          border: `2px solid ${colors.white}`,
          color: 'primary.main'
        },
        border: `2px solid ${colors.gradients.yellowRgb}`,
        ...additionalStyle
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CancelButton;
