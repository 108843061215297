import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import React, { JSXElementConstructor } from 'react';
import { colors } from '../../../utils/theme';
import { getBorderColor } from '../../Account/SignupPage';
import Label from './Label';

interface IProps {
  id: string;
  label?: string;
  value: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (_: unknown, value: any | null) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionLabel: (value: any) => string;
  renderOption?: (props: React.HTMLAttributes<HTMLElement>, option: unknown) => JSX.Element;
  errorMsg?: string | null;
  startIcon?: JSX.Element;
  placeholder?: string;
  disableClearable?: boolean;
  PaperComponent?: JSXElementConstructor<React.HTMLAttributes<HTMLElement>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterOptions?: (options: any, state: any) => any[];
  noPaddingX?: boolean;
  borderRight?: boolean;
  disabled?: boolean;
}

const AutocompleteField: React.FunctionComponent<IProps> = ({
  id,
  label,
  value,
  onChange,
  options,
  optionLabel,
  renderOption,
  errorMsg,
  startIcon,
  placeholder,
  disableClearable,
  PaperComponent,
  filterOptions,
  noPaddingX,
  borderRight,
  disabled = false
}: IProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      {label && <Label id={id} label={label} />}
      <Autocomplete
        id={`${id}Autocomplete`}
        fullWidth
        color="info"
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={optionLabel}
        disabled={disabled}
        disableClearable={disableClearable ?? false}
        {...(PaperComponent && { PaperComponent })}
        {...(filterOptions && { filterOptions })}
        {...(renderOption && { renderOption })}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            name={id}
            {...(errorMsg !== undefined && {
              required: true,
              error: errorMsg !== null,
              helperText: errorMsg ?? ''
            })}
            sx={{
              height: '46px',
              '& .MuiOutlinedInput-root': {
                height: '46px',
                padding: '0 !important',
                '& fieldset': {
                  background: colors.white,
                  borderColor: getBorderColor(value)
                }
              }
            }}
            {...(placeholder && { placeholder })}
            inputProps={{
              ...params.inputProps,
              sx: {
                borderRight: borderRight ? `1px solid ${colors.info.main}` : null,
                fontSize: '16px',
                lineHeight: '22px',
                padding: '12px 16px !important',
                paddingLeft: startIcon || noPaddingX ? '0px !important' : '16px !important',
                paddingRight: noPaddingX ? '0px !important' : '16px !important',
                zIndex: 2
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end" sx={{ zIndex: 2 }}>
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
              startAdornment: startIcon ? (
                <InputAdornment position="start">{startIcon}</InputAdornment>
              ) : null
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutocompleteField;
