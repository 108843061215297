import React, { forwardRef } from 'react';
import { Box } from '@mui/material';

export const TooltipComponent = forwardRef(function TooltipComponent(props, ref) {
  return (
    <Box
      {...props}
      ref={ref}
      sx={{
        border: '2px solid white',
        borderRadius: '50px',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '14px',
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
      }}
    >
      i
    </Box>
  );
});
