import React from 'react';
import { Box, SxProps } from '@mui/material';
import { colors } from '../../../utils/theme';
import CancelButton from '../../Common/Buttons/CancelButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../../enums/RouteEnums';
import badge from '../../../assets/homePage/banners/badge.svg';
import island from '../../../assets/homePage/banners/island.svg';
import { HomeBanner } from '../../../models/banner';

export const parentContainerProps: SxProps = {
  width: { xs: '343px', md: '590px' },
  height: '336px',
  border: `2px solid ${colors.info.main}`,
  borderRadius: '16px',
  boxShadow: '0px 0px 10px rgba(72, 140, 254, 0.5)',
  padding: '24px',
  display: 'flex',
  flexFlow: 'column',
  flexShrink: 0,
  position: 'relative',
  zIndex: 3
};

export const contentContainerProps: SxProps = {
  display: 'flex',
  flexFlow: 'column',
  gap: '8px',
  textAlign: 'left',
  flexGrow: 1
};

export const childContainerProps: SxProps = {
  display: 'flex',
  flexFlow: 'column',
  width: '250px',
  height: '100%'
};

export const titleProps: SxProps = {
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '34px'
};

export const buttonContainerProps: SxProps = {
  flexGrow: 0,
  width: 'max-content',
  padding: '10px 30px',
  color: 'common.white'
};

export const imgProps: SxProps = {
  position: 'absolute',
  zIndex: 1,
  opacity: { xs: 0.5, md: 1 }
};

interface IProps {
  banner: HomeBanner;
}

const ChallengesBanner: React.FunctionComponent<IProps> = ({ banner }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const translation = banner.translations.find(
    (t) => t.language.toUpperCase() === i18n.language.toUpperCase()
  );

  return (
    <Box
      sx={{
        ...parentContainerProps,
        background: 'linear-gradient(180deg, #2575FC 0%, #2575FC 0.01%, #0D1365 100%), #0D1365'
      }}
      className="promotionBanner"
    >
      <Box sx={childContainerProps}>
        <Box sx={contentContainerProps}>
          <Box sx={titleProps}>{translation?.title}</Box>
          <Box>{translation?.body}</Box>
        </Box>
        <CancelButton
          text={translation?.buttonText as string}
          onClick={() => navigate(PublicRoutes.Challenges)}
          additionalStyle={buttonContainerProps}
        />
      </Box>
      <Box
        component="img"
        src={badge}
        sx={{
          ...imgProps,
          top: '1px',
          right: '45px',
          zIndex: 2
        }}
      />
      <Box
        component="img"
        src={island}
        sx={{
          ...imgProps,
          top: '15px',
          right: 0
        }}
      />
    </Box>
  );
};

export default ChallengesBanner;
