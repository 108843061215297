import React from 'react';
import logo from '../../../assets/logo.svg';
import bubble from '../../../assets/bubble.svg';
import Bubbles from '../Bubbles';
import './AccessDenied.scss';

const AccessDenied: React.FunctionComponent = () => {
  return (
    <div className="access-container">
      <div className="content-container">
        <img className="content-container__logo" src={logo} />
        <div className="content-container__code">
          <div className="content-container__code__num">4</div>
          <img className="content-container__code__bubble" src={bubble} />
          <div className="content-container__code__num">3</div>
        </div>
        <div className="content-container__text">
          Sorry, we are not accepting visitors from your country. If you have any questions, please
          contact us support@bubblesbet.com
        </div>
      </div>
      <Bubbles />
    </div>
  );
};

export default AccessDenied;
