import React, { useState } from 'react';
import { Box, FormControl, MenuItem, Select, SvgIcon } from '@mui/material';
import { GroupButtonOption } from '../../../models/buttons';
import { SvgIconComponent } from '@mui/icons-material';
import './DropDownSelect.scss';

interface IProps {
  selected: number;
  handleChange: (index: number) => void;
  options: GroupButtonOption[];
}

const DropDownSelect: React.FunctionComponent<IProps> = ({
  selected,
  handleChange,
  options
}: IProps) => {
  const [selectOpened, setSelectOpened] = useState(false);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Select
        value={selected}
        onChange={(e) => handleChange(e.target.value as number)}
        inputProps={{ 'aria-label': 'Without label' }}
        className={`custom-select${selectOpened ? '--open' : ''}`}
        variant="standard"
        disableUnderline
        MenuProps={{
          PaperProps: {
            className: 'custom-menu-paper'
          }
        }}
        onOpen={() => setSelectOpened(true)}
        onClose={() => setSelectOpened(false)}
      >
        {options.map((item, index) => (
          <MenuItem key={`${item}-${index}`} value={index}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {item.icon && (
                <SvgIcon component={item.icon as SvgIconComponent} sx={{ mr: '8px' }} />
              )}
              {item.label}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDownSelect;
