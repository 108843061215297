import React from 'react';
import { Promotion, InfoPair, PromoInfo } from '../models/bonus';
import { IPack } from '../models/pack';

export const getPromoInfo = (promo: Promotion, lang: string): PromoInfo => {
  const info = {
    title:
      promo?.promoDetails.find((d) => d.language.toUpperCase() === lang)?.overline ??
      promo?.promoDetails[0]?.overline,
    subtitle:
      promo.promoDetails.find((d) => d.language.toUpperCase() === lang)?.title ??
      promo.promoDetails[0]?.title,
    items:
      promo.termsAndConditions.length > 0
        ? promo.termsAndConditions
        : getInfoItems(
            promo.promoDetails.find((d) => d.language.toUpperCase() === lang)?.termsAndConditions ??
              (promo.promoDetails[0]?.termsAndConditions as string)
          ),
    state: promo.state ?? 'Default',
    promotion: promo
  } as PromoInfo;
  return info;
};

const getInfoItems = (terms: string): InfoPair[] => {
  const regex = /<[^>]+>([^<]+)<\/[^>]+>/g;
  const extractedTexts = [];

  let match;
  while ((match = regex.exec(terms)) !== null) {
    extractedTexts.push(match[1].trim());
  }

  const items = [] as InfoPair[];
  extractedTexts.forEach((text) => {
    if (text.includes(':')) {
      const textItems = text.split(':');
      if (textItems.length === 2) {
        items.push({ key: textItems[0], value: textItems[1] });
      }
    }
  });

  return items;
};

export const getSplittedPromoTitle = (promoTitle: string | undefined) => {
  if (promoTitle) {
    const splitPromoTitleArray = promoTitle.split('+');
    return splitPromoTitleArray.map((splitPromoTitle, index) => {
      let promoTitlePart = splitPromoTitle;

      if (splitPromoTitleArray.length > 1 && index === splitPromoTitleArray.length - 1) {
        promoTitlePart = `+ ${splitPromoTitle}`;
      }

      return <section key={`${promoTitlePart}-${index}`}>{promoTitlePart}</section>;
    });
  }
};

export const getPacksFromPromos = (promotions: Promotion[]): IPack[] => {
  const packMap = {};

  // Iterate through promotions
  promotions.forEach((promotion) => {
    // Check if packId already exists in packMap
    if (promotion.packId) {
      if (!packMap[promotion.packId]) {
        // If packId doesn't exist, add it to packMap
        packMap[promotion.packId] = {
          id: promotion.packId,
          name: promotion.packName
        };
      }
    }
  });

  // Convert packMap values to array of Packs
  const uniquePacks = Object.values(packMap);
  uniquePacks.sort((a, b) => a.id - b.id);
  return uniquePacks as IPack[];
};
