import React, { useState, useEffect, useRef, useContext } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useQueries } from 'react-query';
import { AppContext } from '../../AppContext';
import axios from 'axios';
//* MUI
import { Box } from '@mui/material';
//* Models
import { ITournamentRanking } from '../../models/tournaments';
//* Queries
import { AllTournamentsQuery, PassedTournamentsQuery } from '../../queries/tournaments';
//* Components
import Loader from '../Common/Loader';
import TournamentCard from './TournamentCard/TournamentCard';
//* Utils
import { colors } from '../../utils/theme';
import { handleError } from '../../utils/ui';
import { Query } from '../../enums/RequestEnums';

const AllTournaments: React.FunctionComponent = () => {
  const { dispatch } = useContext(AppContext);
  const [tournamentRanking, setTournamentRanking] = useState<ITournamentRanking[]>([]);
  const [rankingConnection, setRankingConnection] = useState<HubConnection | null>(null);

  const tournamentQueries = useQueries([
    {
      queryKey: Query.AllTournaments,
      queryFn: AllTournamentsQuery,
      onError: (error: unknown) => {
        handleError(error, dispatch);
      }
    },
    {
      queryKey: Query.PassedTournaments,
      queryFn: PassedTournamentsQuery,
      onError: (error: unknown) => {
        handleError(error, dispatch);
      }
    }
  ]);

  const tournamentData = tournamentQueries.flatMap(
    (tournamentQueryResponse) => tournamentQueryResponse.data?.data?.tournaments
  );

  const userHasJoinedTournament = tournamentQueries.some(
    (tournamentData) => tournamentData.data?.data.playerJoined
  );

  const latestRanking = useRef<ITournamentRanking[]>([]);
  latestRanking.current = tournamentRanking;

  useEffect(() => {
    axios
      .get(process.env.TOURNAMENTS_ADDRESS as string)
      .then((res) => {
        if (res.status === 200) {
          const newConn = new HubConnectionBuilder()
            .withUrl(res.data.url, {
              accessTokenFactory: () => res.data.accessToken
            })
            .withAutomaticReconnect()
            .build();
          setRankingConnection(newConn);
        }
      })
      .catch((e) => console.log(e));

    return () => {
      if (rankingConnection) {
        rankingConnection.stop();
        setRankingConnection(null);
      }
    };
  }, []);

  useEffect(() => {
    if (rankingConnection) {
      rankingConnection
        .start()
        .then(() => {
          console.log('rankingConnection Connected!');
          rankingConnection.on('notify', (data: unknown) => {
            console.log('received updated ranking: ', data);
            const newData = data as ITournamentRanking[];
            setTournamentRanking(newData);
          });
        })
        .catch((e) => console.log(e));
    }
  }, [rankingConnection]);

  return (
    <Box sx={{ paddingX: { xs: '2%', md: '5%', xl: '7.5%' } }}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          gap: '16px',
          background: colors.background.blue,
          alignItems: 'center'
        }}
      >
        {tournamentData?.map((tournament, index) => {
          if (tournament) {
            return (
              <TournamentCard
                key={index}
                index={index}
                tournament={tournament}
                isCompact={!tournament?.startDate}
                hasJoinedTournament={userHasJoinedTournament}
                ranking={
                  tournamentRanking.length > 0 &&
                  tournamentRanking[0].tournamentId === tournament.id
                    ? tournamentRanking
                    : undefined
                }
              />
            );
          }
        })}
      </Box>
      <Loader
        loading={tournamentQueries.some((tournamentResponse) => tournamentResponse.isLoading)}
      />
    </Box>
  );
};

export default AllTournaments;
