import React from 'react';
import { oneOf } from 'prop-types';
//* MUI
import { Badge, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
//* Styles
import './CustomIconButton.scss';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white
  }
}));

type CustomIconButtonProps = {
  color?: string;
  tooltip?: string;
  icon: JSX.Element;
  disabled?: boolean;
  additionalClassName?: string;
  badgeContent?: number | string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const CustomIconButton = ({
  icon,
  color,
  tooltip,
  onClick,
  disabled,
  badgeContent = 0,
  additionalClassName
}: CustomIconButtonProps) => {
  return (
    <LightTooltip title={tooltip}>
      <Badge className="custom-icon-button__badge" badgeContent={badgeContent}>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          className={`custom-icon-button custom-icon-button--${color} ${
            additionalClassName ? additionalClassName : ''
          }`}
        >
          {icon}
        </IconButton>
      </Badge>
    </LightTooltip>
  );
};

CustomIconButton.propTypes = {
  color: oneOf(['gold', 'white'])
};

CustomIconButton.defaultProps = {
  color: 'white'
};

export default CustomIconButton;
