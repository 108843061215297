import React, { useState } from 'react';
import { BannerModel } from '../../../models/banner';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HomePageBanner from './HomePageBanner';

interface IProps {
  banners: BannerModel[];
  isShop?: boolean;
}

const BannerContainerNew: React.FunctionComponent<IProps> = ({ banners }: IProps) => {
  const [boxHeight, setBoxHeight] = useState(465);

  return (
    <Carousel
      className="banner_container"
      NextIcon={<ArrowForwardIosIcon style={{ color: 'white' }} />}
      PrevIcon={<ArrowBackIosNewIcon style={{ color: 'white' }} />}
      indicators={false}
      duration={800}
      height={boxHeight}
      interval={5700}
    >
      {banners.map((b, i) => (
        <HomePageBanner key={`banner-${i}`} banner={b} setContainer={setBoxHeight} />
      ))}
    </Carousel>
  );
};

export default BannerContainerNew;
