import React from 'react';
import {
  Box,
  Autocomplete,
  InputLabel,
  TextField,
  InputAdornment,
  Grid,
  Paper,
  Theme,
  FilterOptionsState
} from '@mui/material';
import { IErrors, getBorderColor, getIconColor, inputPropsStyle } from '../SignupPage';
import { MarkunreadMailbox, Map, Place, PhoneAndroid, Language } from '@mui/icons-material';
import { labelStyle } from '../SigninPage';
import { useTranslation } from 'react-i18next';
import { countries, CountryType } from '../../../utils/countries';

interface IProps {
  formErrors: IErrors;
  country: CountryType | null;
  handleCountryChange: (_: unknown, value: CountryType | null) => void;
  phone: string;
  handlePhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  phoneCountry: CountryType | null;
  handlePhoneCodeChange: (_: unknown, value: CountryType | null) => void;
  address: string;
  handleAddressChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  city: string;
  handleCityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  postCode: string;
  handlePostCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} style={{ width: 300 }} />;
};

const Step2: React.FunctionComponent<IProps> = ({
  formErrors,
  country,
  handleCountryChange,
  phone,
  phoneCountry,
  handlePhoneCodeChange,
  handlePhoneChange,
  address,
  handleAddressChange,
  city,
  handleCityChange,
  postCode,
  handlePostCodeChange
}: IProps) => {
  const { t } = useTranslation();

  const filterOptions = (options: CountryType[], state: FilterOptionsState<CountryType>) => {
    let searchValue = state.inputValue;
    if (searchValue === '') {
      return options;
    } else if (searchValue.length > 0 && searchValue[0] === '+') {
      searchValue = state.inputValue.substring(1);
    }
    const result = options.filter(
      (opt) =>
        opt.label.toLowerCase().indexOf(searchValue.toLowerCase()) === 0 ||
        opt.phone.indexOf(searchValue) === 0
    );
    return result;
  };

  return (
    <Box>
      <Box>
        <InputLabel htmlFor="countryAutocomplate" style={labelStyle}>
          {t('account.countryLabel')}
        </InputLabel>
        <Autocomplete
          id="countryAutocomplate"
          fullWidth
          value={country}
          onChange={handleCountryChange}
          options={countries}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                height: '46px',
                '& > img': { mr: 2, flexShrink: 0 },
                '& .MuiInputBase - input': {
                  height: '46px'
                }
              }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt="countryFlag"
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              id="countryInput"
              name="countryInput"
              margin="normal"
              variant="outlined"
              error={formErrors.country !== null}
              helperText={formErrors.country ? formErrors.country : ''}
              sx={{
                height: '46px',
                '& .MuiOutlinedInput-root': {
                  height: '46px',
                  padding: '0 !important',
                  '& fieldset': {
                    borderColor: getBorderColor(country?.label)
                  }
                }
              }}
              placeholder={t('account.countryPlaceHolder') as string}
              color="info"
              inputProps={{
                ...params.inputProps,
                sx: inputPropsStyle
                // autoComplete: 'new-password' // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start" style={{ paddingLeft: '13px' }}>
                    <Language sx={{ fill: getIconColor(country?.label, formErrors.country) }} />
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </Box>
      <Box>
        <InputLabel htmlFor="phone" style={labelStyle}>
          {t('account.phoneLabel')}
        </InputLabel>
        <TextField
          id="phone"
          name="phone"
          margin="normal"
          variant="outlined"
          fullWidth
          required
          value={phone}
          onChange={handlePhoneChange}
          error={formErrors.phoneCode !== null}
          helperText={formErrors.phoneCode ? formErrors.phoneCode : ''}
          sx={{
            height: '46px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: getBorderColor(phone)
              }
            }
          }}
          inputProps={{ sx: inputPropsStyle }}
          placeholder={t('account.phonePlaceHolder') as string}
          color="info"
          InputProps={{
            startAdornment: (
              <Autocomplete
                id="phoneCodeAutocomplate"
                filterOptions={filterOptions}
                style={{
                  position: 'relative',
                  height: 30,
                  top: '-24px',
                  left: '-14px',
                  width: '43%'
                }}
                disableClearable
                PaperComponent={CustomPaper}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                value={phoneCountry}
                onChange={handlePhoneCodeChange}
                options={countries}
                getOptionLabel={(option) => `+${option.phone}`}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      height: '46px',
                      '& > img': { mr: 2, flexShrink: 0 },
                      '& .MuiInputBase - input': {
                        height: '46px'
                      }
                    }}
                    {...props}
                    key={option.label}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt="countryFlag"
                    />
                    {option.label} +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="phoneCodeInput"
                    name="phoneCodeInput"
                    margin="normal"
                    variant="standard"
                    error={formErrors.phoneCode !== null}
                    helperText={formErrors.phoneCode ? formErrors.phoneCode : ''}
                    sx={{
                      borderRight: (t) => `1px solid ${t.palette.grey[50]}`,
                      height: '46px',
                      '& .MuiOutlinedInput-root': {
                        height: '46px',
                        padding: '0 !important',
                        '& fieldset': {
                          borderColor: getBorderColor(country?.label)
                        }
                      }
                    }}
                    placeholder={t('account.countryPlaceHolder') as string}
                    color="info"
                    inputProps={{
                      ...params.inputProps,
                      sx: {
                        ...inputPropsStyle,
                        textAlign: 'right',
                        marginRight: '3%',
                        color: (t: Theme) => t.palette.info.main
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ display: { xs: 'none', md: 'flex' } }}
                        >
                          <PhoneAndroid
                            sx={{
                              fill: getIconColor(phoneCountry?.label, formErrors.phoneCode),
                              marginLeft: '13px'
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            )
          }}
        />
      </Box>
      <Box>
        <InputLabel htmlFor="address" style={labelStyle}>
          {t('account.addressLabel')}
        </InputLabel>
        <TextField
          id="address"
          name="address"
          margin="normal"
          variant="outlined"
          fullWidth
          required
          value={address}
          onChange={handleAddressChange}
          error={formErrors.address !== null}
          helperText={formErrors.address ? formErrors.address : ''}
          sx={{
            height: '46px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: getBorderColor(address)
              }
            }
          }}
          inputProps={{ sx: inputPropsStyle }}
          placeholder={t('account.addressPlaceHolder') as string}
          color="info"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Place sx={{ fill: getIconColor(address, formErrors.address) }} />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Grid container>
        <Grid item xs={6} style={{ paddingRight: '2.43%' }}>
          <InputLabel htmlFor="city" style={labelStyle}>
            {t('account.cityLabel')}
          </InputLabel>
          <TextField
            id="city"
            name="city"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            value={city}
            onChange={handleCityChange}
            error={formErrors.city !== null}
            helperText={formErrors.city ? formErrors.city : ''}
            sx={{
              height: '46px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: getBorderColor(city)
                }
              }
            }}
            inputProps={{ sx: inputPropsStyle }}
            placeholder={t('account.cityPlaceHolder') as string}
            color="info"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Map sx={{ fill: getIconColor(city, formErrors.city) }} />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '2.43%' }}>
          <InputLabel htmlFor="postCode" style={labelStyle}>
            {t('account.postCodeLabel')}
          </InputLabel>
          <TextField
            id="postCode"
            name="postCode"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            value={postCode}
            onChange={handlePostCodeChange}
            error={formErrors.postCode !== null}
            helperText={formErrors.postCode ? formErrors.postCode : ''}
            sx={{
              height: '46px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: getBorderColor(postCode)
                }
              }
            }}
            inputProps={{ sx: inputPropsStyle }}
            placeholder={t('account.postCodePlaceHolder') as string}
            color="info"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MarkunreadMailbox sx={{ fill: getIconColor(postCode, formErrors.postCode) }} />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step2;
