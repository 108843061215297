import React, { useEffect, useRef, useState, useContext } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { containerPaddingX } from './HomeGameSection';
import { handleError } from '../../utils/ui';
import PromotionsBanner from './SubBanners/PromotionsBanner';
import LuckyWheelBanner from './SubBanners/LuckyWheelBanner';
import ChallengesBanner from './SubBanners/ChallengesBanner';
import TournamentsBanner from './SubBanners/TournamentsBanner';
import ShopBanner from './SubBanners/ShopBanner';
import { useMutation } from 'react-query';
import { AppContext } from '../../AppContext';
import { HomeBannersQuery } from '../../queries/banner';
import { HomeBanner } from '../../models/banner';
import { BannerType } from '../../enums/BannerEnums';
import SingleLineArrowContainer from '../Common/SingleLineCointainer';

const BottomBanner: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const [banners, setBanners] = useState<HomeBanner[]>([]);

  useEffect(() => {
    bannersQuery.mutate();
  }, []);

  const bannersQuery = useMutation(HomeBannersQuery, {
    onSuccess: ({ data }) => {
      if (data.length) {
        setBanners(data);
      }
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const ref = useRef(null);

  return !bannersQuery.isLoading && banners.length > 0 ? (
    <>
      <Box
        sx={{
          paddingTop: '110px',
          paddingBottom: '230px'
        }}
      >
        <Box sx={{ display: 'flex', flexFlow: 'column', gap: '32px', position: 'relative' }}>
          <Box
            sx={{
              ...containerPaddingX,
              fontSize: { xs: '24px', md: '64px' },
              fontWeight: { xs: 700, md: 600 },
              lineHeight: { xs: '34px', md: '90px' }
            }}
          >
            {t('homePage.bannerContainerTitles.earnMore')}
          </Box>
          <Box>
            <Box
              sx={{
                overflow: 'hidden',
                position: 'relative'
                //   '&::before,&::after': {
                //     content: '"*"',
                //     position: 'absolute',
                //     zIndex: 9,
                //     width: { xs: '90px', md: '180px' },
                //     height: '100%'
                //   },
                //   '&::before': {
                //     top: 0,
                //     left: 0,
                //     background:
                //       'linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
                //   },
                //   '&::after': {
                //     top: 0,
                //     right: 0,
                //     background:
                //       'linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
                //   }
              }}
            >
              <Box
                sx={{
                  overflow: 'hidden',
                  display: 'flex',
                  gap: '16px',
                  flexWrap: 'nowrap',
                  scrollBehavior: 'smooth',
                  scrollbarWidth: 'thin'
                  // '&::-webkit-scrollbar': {
                  //   display: 'none'
                  // },
                  // '@keyframes translateInfiniteLarge': {
                  //   '100%': {
                  //     transform: 'translateX(calc(-606px * 5))'
                  //   }
                  // },
                  // '@keyframes translateInfiniteSmall': {
                  //   '100%': {
                  //     transform: 'translateX(calc(-359px * 5))'
                  //   }
                  // },
                  // '&:hover': {
                  //   'div.promotionBanner': {
                  //     animationPlayState: 'paused'
                  //   }
                  // },
                  // 'div.promotionBanner': {
                  //   animation: `${
                  //     isMobileScreen ? 'translateInfiniteSmall' : 'translateInfiniteLarge'
                  //   } 25s linear infinite`
                  // }
                }}
                ref={ref}
              >
                <SingleLineArrowContainer
                  additionalStyle={{ mt: { xs: '20px', md: '80px', lg: '80px' } }}
                >
                  <PromotionsBanner
                    banner={banners.find((b) => b.type === BannerType.Promotion) as HomeBanner}
                  />
                  <LuckyWheelBanner
                    banner={banners.find((b) => b.type === BannerType.LuckyWheel) as HomeBanner}
                  />
                  <ChallengesBanner
                    banner={banners.find((b) => b.type === BannerType.Challenges) as HomeBanner}
                  />
                  <ShopBanner
                    banner={banners.find((b) => b.type === BannerType.Shop) as HomeBanner}
                  />
                  <TournamentsBanner
                    banner={banners.find((b) => b.type === BannerType.Tournaments) as HomeBanner}
                  />

                  <PromotionsBanner
                    banner={banners.find((b) => b.type === BannerType.Promotion) as HomeBanner}
                  />
                  <LuckyWheelBanner
                    banner={banners.find((b) => b.type === BannerType.LuckyWheel) as HomeBanner}
                  />
                  <ChallengesBanner
                    banner={banners.find((b) => b.type === BannerType.Challenges) as HomeBanner}
                  />
                  <ShopBanner
                    banner={banners.find((b) => b.type === BannerType.Shop) as HomeBanner}
                  />
                  <TournamentsBanner
                    banner={banners.find((b) => b.type === BannerType.Tournaments) as HomeBanner}
                  />
                </SingleLineArrowContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          background:
            'linear-gradient(89.97deg, rgba(13, 19, 101, 0.5) 0.02%, rgba(36, 115, 249, 0.5) 52.22%, rgba(13, 19, 101, 0.5) 99.5%), rgba(37, 117, 252, 0.4)',
          backdropFilter: 'blur(5px)',
          paddingY: '80px',
          textAlign: 'left',
          ...containerPaddingX
        }}
      >
        <Box sx={{ fontSize: '40px', fontWeight: 600, lineHeight: '56px' }}>
          {
            banners
              .find((b) => b.type === BannerType.Bottom)
              ?.translations.find((tr) => tr.language.toUpperCase() === i18n.language.toUpperCase())
              ?.title
          }
        </Box>
        <Box sx={{ lineHeight: '22px' }}>
          {
            banners
              .find((b) => b.type === BannerType.Bottom)
              ?.translations.find((tr) => tr.language.toUpperCase() === i18n.language.toUpperCase())
              ?.body
          }
        </Box>
      </Box> */}
    </>
  ) : null;
};

export default BottomBanner;
