import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { colors } from '../../utils/theme';
import { IPassedTournament } from '../../models/tournaments';
import TournamentCard from './TournamentCard/TournamentCard';
import { useQuery } from 'react-query';
import { PassedTournamentsQuery } from '../../queries/tournaments';
import { AppContext } from '../../AppContext';
import Loader from '../Common/Loader';
import { handleError } from '../../utils/ui';
import { Query } from '../../enums/RequestEnums';

const ArchivedTournaments: React.FunctionComponent = () => {
  const { dispatch } = useContext(AppContext);

  const passedTournamentsQuery = useQuery(Query.PassedTournaments, PassedTournamentsQuery, {
    onSuccess: ({ data }) => {
      const tournamentsData = data as IPassedTournament[];
      setPassTournaments(tournamentsData);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const [passTournaments, setPassTournaments] = useState<IPassedTournament[]>(
    passedTournamentsQuery.data?.data?.tournaments ?? []
  );

  return (
    <Box sx={{ paddingX: { xs: '2%', md: '5%', xl: '7.5%' } }}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          gap: '16px',
          background: colors.background.blue,
          alignItems: 'center'
        }}
      >
        {passTournaments.map((t, i) => (
          <TournamentCard key={i} tournament={t} index={i} isCompact />
        ))}
      </Box>
      <Loader loading={passedTournamentsQuery.isLoading} />
    </Box>
  );
};

export default ArchivedTournaments;
