import axios from 'axios';
import { AppConsts } from '../enums/AppConsts';
import { IDeposit, IWithdrawModel } from '../models/wallet';

const baseUrl = process.env.BASE_SERVER_ADDRESS;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);
const getHeaders = () => ({ headers: { Authorization: `Bearer ${getToken()}` } });

export const Deposit = async (model: IDeposit) => {
  return await axios.post(`${baseUrl}/wallet/DepositNew`, model, getHeaders());
};

export const Withdraw = async (data: IWithdrawModel) => {
  return await axios.post(`${baseUrl}/wallet/withdraw`, data, getHeaders());
};

export const Balance = async (order?: string | (string | null)[] | null) => {
  if (order) {
    return await axios.get(`${baseUrl}/wallet/Balance/${order}`, getHeaders());
  }
  return await axios.get(`${baseUrl}/wallet/Balance`, getHeaders());
};
export const DepositBalance = async (order: string) => {
  return await axios.get(`${baseUrl}/wallet/Balance/${order}`, getHeaders());
};

export const GetTransactions = async () => {
  return await axios.get(`${baseUrl}/wallet/Transactions`, getHeaders());
};

export const CancelTransaction = async (id: number) => {
  return await axios.delete(`${baseUrl}/wallet/CancelWithdraw?id=${id}`, getHeaders());
};

export const PlayerBonuses = async () => {
  return await axios.get(`${baseUrl}/bonus/GetPlayerBonuses`, getHeaders());
};

export const GetLoyaltyPoints = async () => {
  return await axios.get(`${baseUrl}/wallet/PlayerLoyaltyPoints`, getHeaders());
};

export const GetPlayerCards = async () => {
  return await axios.get(`${baseUrl}/wallet/GetPlayerCards`, getHeaders());
};

export const GetPaymentProviders = async () => {
  return await axios.get(`${baseUrl}/payments/ActiveProviders`, getHeaders());
};
