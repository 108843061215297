import React, { useContext, useEffect, useState } from 'react';
import { Box, FilterOptionsState, Paper } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { colors } from '../../utils/theme';
import Secondary from '../Common/Buttons/SecondaryButton';
import InputField from '../Common/Fields/InputField';
import { radius } from '../Wallet/DepositPage';
import { isValidEmail } from '../../utils/validators';
import DateField from '../Common/Fields/DateField';
import moment from 'moment';
import AutocompleteField from '../Common/Fields/AutocompleteField';
import { currencies, CurrencyType } from '../../utils/currencies';
import { countries, CountryType } from '../../utils/countries';
import PrimaryButton from '../Common/Buttons/PrimaryButton/PrimaryButton';
import CancelButton from '../Common/Buttons/CancelButton';
import PasswordChange from './PasswordChange';
import { AppContext } from '../../AppContext';
import { GetAccountDetailsQuery, UpdateAccountDetailsQuery } from '../../queries/profile';
import { useMutation, useQueryClient } from 'react-query';
import { Actions } from '../../enums/ActionEnums';
import Loader from '../Common/Loader';
import { AccountDetailsModel } from '../../models/profile';
import { PublicRoutes } from '../../enums/RouteEnums';
import { useNavigate } from 'react-router-dom';
import { UserModel } from '../../models/account';
import { AppConsts } from '../../enums/AppConsts';
import BalanceOverview from '../Common/BalanceOverview';
import Badge from '../Challenges/Badge';
import { handleError } from '../../utils/ui';

type IErrors = {
  username: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneCode: string | null;
  phone: string | null;
  birthday: string | null;
  currency: string | null;
  country: string | null;
  address: string | null;
  city: string | null;
  postCode: string | null;
};

const defaultErrorsObj: IErrors = {
  username: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneCode: null,
  phone: null,
  birthday: null,
  currency: null,
  country: null,
  address: null,
  city: null,
  postCode: null
};

const backgroundProps: SxProps = {
  background: colors.background.blue,
  borderRadius: radius
};

const rowBoxStyle: SxProps = {
  display: 'flex',
  flexFlow: { xs: 'column', md: 'row' },
  gap: { xs: '24px', md: '16px' }
};

const getMinBirthdayDate = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  now.setFullYear(now.getFullYear() - 18);
  return moment(now);
};

const AccountPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCountry, setPhoneCountry] = useState<CountryType | null>(null);
  const [birthday, setBirthday] = useState<moment.Moment | null>(null);
  const [currency, setCurrency] = useState<CurrencyType | null>(currencies[0]);
  const [country, setCountry] = useState<CountryType | null>(null);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');
  const [openPasswordForm, setOpenPasswordForm] = useState(false);
  const { mutate, isLoading } = useMutation(GetAccountDetailsQuery, {
    onSuccess: ({ data }) => {
      updateFields(data as AccountDetailsModel);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('GetAccountDetailsQuery');
    }
  });
  const updateAccount = useMutation(UpdateAccountDetailsQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('myAccount.verificationPage.accountDetailsChangedSuccessfully')
        }
      });
      const updatedAccount = data.data as AccountDetailsModel;
      dispatch({
        type: Actions.SetUser,
        payload: {
          data: {
            ...(state.user.data as UserModel),
            email: updatedAccount.email,
            firstName: updatedAccount.firstName,
            lastName: updatedAccount.lastName
          },
          token: data.accessToken as string
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('UpdateAccountDetailsQuery');
    }
  });

  useEffect(() => {
    mutate();
  }, []);

  const updateFields = (data: AccountDetailsModel) => {
    setUsername(data.username ?? '');
    setEmail(data.email ?? '');
    setFirstName(data.firstName ?? '');
    setLastName(data.lastName ?? '');
    const phoneCountry =
      countries.find(
        (c) => c.label.toLocaleLowerCase().replace(/\s/g, '') === data.country.toLocaleLowerCase()
      ) ?? null;
    setPhone(
      phoneCountry && data.phoneNumber
        ? data.phoneNumber.replace(`+${phoneCountry.phone}`, '')
        : data.phoneNumber ?? ''
    );
    setPhoneCountry(phoneCountry);
    setBirthday(data.birthDate ? moment(data.birthDate) : null);
    setCurrency(
      currencies.find((c) => c.code.toLowerCase() === data.currency.toLocaleLowerCase()) ?? null
    );
    setCountry(
      countries.find(
        (c) => c.label.toLocaleLowerCase().replace(/\s/g, '') === data.country.toLocaleLowerCase()
      ) ?? null
    );
    setAddress(data.address ?? '');
    setCity(data.city ?? '');
    setPostCode(data.postCode ?? '');
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, username: t('errors.signup.username') });
    } else {
      setFormErrors({ ...formErrors, username: null });
    }
    setUsername(value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isValidEmail(value)) {
      setFormErrors({ ...formErrors, email: t('errors.signup.email') });
    } else {
      setFormErrors({ ...formErrors, email: null });
    }
    setEmail(value);
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, firstName: t('errors.signup.firstName') });
    } else {
      setFormErrors({ ...formErrors, firstName: null });
    }
    setFirstName(value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, lastName: t('errors.signup.lastName') });
    } else {
      setFormErrors({ ...formErrors, lastName: null });
    }
    setLastName(value);
  };

  const handlePhoneCodeChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, phoneCode: t('errors.signup.phoneCode') });
    } else {
      setFormErrors({ ...formErrors, phoneCode: null });
    }
    setPhoneCountry(value);
  };

  const filterOptions = (options: CountryType[], state: FilterOptionsState<CountryType>) => {
    let searchValue = state.inputValue;
    if (searchValue === '') {
      return options;
    } else if (searchValue.length > 0 && searchValue[0] === '+') {
      searchValue = state.inputValue.substring(1);
    }
    const result = options.filter((opt) => opt.phone.indexOf(searchValue) === 0);
    return result;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    //TODO Adds better phone validation
    if (value.length < 4) {
      setFormErrors({ ...formErrors, phone: t('errors.signup.phone') });
    } else {
      setFormErrors({ ...formErrors, phone: null });
    }
    setPhone(value);
  };

  const handleBirthdayChange = (value: moment.Moment | null) => {
    if (value && value.isValid()) {
      value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      if (value > getMinBirthdayDate()) {
        setFormErrors({ ...formErrors, birthday: t('errors.signup.under18') });
      } else {
        setFormErrors({ ...formErrors, birthday: null });
      }
    } else {
      setFormErrors({ ...formErrors, birthday: t('errors.signup.birthday') });
    }
    setBirthday(value);
  };

  const handleCurrencyChange = (_: unknown, value: CurrencyType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, currency: t('errors.signup.currency') });
    } else {
      setFormErrors({ ...formErrors, currency: null });
    }
    setCurrency(value);
  };

  const handleCountryChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, country: t('errors.signup.country') });
    } else {
      setFormErrors({ ...formErrors, country: null });
    }
    setCountry(value);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, address: t('errors.signup.address') });
    } else {
      setFormErrors({ ...formErrors, address: null });
    }
    setAddress(value);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, city: t('errors.signup.city') });
    } else {
      setFormErrors({ ...formErrors, city: null });
    }
    setCity(value);
  };

  const handlePostCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, postCode: t('errors.signup.postCode') });
    } else {
      setFormErrors({ ...formErrors, postCode: null });
    }
    setPostCode(value);
  };

  const handleSave = () => {
    let hasErrors = false;
    const errors: IErrors = {
      username: null,
      email: null,
      firstName: null,
      lastName: null,
      phoneCode: null,
      phone: null,
      birthday: null,
      currency: null,
      country: null,
      address: null,
      city: null,
      postCode: null
    };

    if (username.length < 4) {
      hasErrors = true;
      errors.username = t('errors.signup.username');
    }
    if (!isValidEmail(email)) {
      hasErrors = true;
      errors.email = t('errors.signup.email');
    }
    if (firstName.length < 2) {
      hasErrors = true;
      errors.firstName = t('errors.signup.firstName');
    }
    if (lastName.length < 2) {
      hasErrors = true;
      errors.lastName = t('errors.signup.lastName');
    }
    if (!phoneCountry) {
      hasErrors = true;
      errors.phoneCode = t('errors.signup.phoneCode');
    }
    if (phone.length < 4) {
      hasErrors = true;
      errors.phone = t('errors.signup.phone');
    }
    if (!birthday) {
      hasErrors = true;
      errors.birthday = t('errors.signup.birthday');
    }
    if (birthday && birthday > getMinBirthdayDate()) {
      hasErrors = true;
      errors.birthday = t('errors.signup.under18');
    }
    if (!currency) {
      hasErrors = true;
      errors.currency = t('errors.signup.currency');
    }
    if (!country) {
      hasErrors = true;
      errors.country = t('errors.signup.country');
    }
    if (address.length < 2) {
      hasErrors = true;
      errors.address = t('errors.signup.address');
    }
    if (city.length < 2) {
      hasErrors = true;
      errors.city = t('errors.signup.city');
    }
    if (postCode.length < 2) {
      hasErrors = true;
      errors.postCode = t('errors.signup.postCode');
    }

    if (hasErrors) {
      setFormErrors(errors);
    } else {
      const model: AccountDetailsModel = {
        firstName,
        lastName,
        birthDate: birthday?.format('YYYY-MM-DD') as string,
        email,
        country: (country?.label as string).replace(/\s/g, ''),
        phoneNumber: `+${phoneCountry?.phone as string}${phone}`,
        address,
        city,
        postCode,
        username,
        currency: currency?.code as string
      };
      updateAccount.mutate(model);
    }
  };

  return (
    <Box sx={{ mb: '35px' }}>
      <BalanceOverview />
      <Box
        sx={{
          marginTop: { xs: '32px', md: '16px' },
          display: 'flex',
          flexFlow: { xs: 'column', md: 'row' },
          gap: { xs: '32px', md: '16px' },
          textAlign: 'left'
        }}
      >
        <Box
          sx={{
            width: { xs: 'auto', md: '636px' },
            ...backgroundProps,
            padding: { xs: '24px', md: '32px' },
            display: 'flex',
            flexFlow: 'column',
            gap: { xs: '24px', md: '32px' }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: { xs: 'column', md: 'row' },
              gap: { xs: '8px', md: '18px ' }
            }}
          >
            <Box sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '34px' }}>
              {t('myAccount.accountPage.accountDetails')}
            </Box>
            <Badge
              showName
              withBackground
              name={state.user.data?.challengeLevel?.name}
              rank={state.user.data?.challengeLevel?.rank ?? 0}
            />
          </Box>
          <Box sx={rowBoxStyle}>
            <InputField
              id="username"
              value={username}
              onChange={handleUsernameChange}
              label={t('account.username') as string}
              errorMsg={formErrors.username}
              placeholder={t('account.usernamePlaceHolder') as string}
            />
            <InputField
              id="email"
              value={email}
              onChange={handleEmailChange}
              label={t('account.emailLabel') as string}
              errorMsg={formErrors.email}
              placeholder={t('account.emailPlaceHolder') as string}
            />
          </Box>
          <Box sx={rowBoxStyle}>
            <InputField
              id="firstName"
              value={firstName}
              onChange={handleFirstNameChange}
              label={t('account.firstNameLabel') as string}
              errorMsg={formErrors.firstName}
              placeholder={t('account.namePlaceHolder') as string}
            />
            <InputField
              id="lastName"
              value={lastName}
              onChange={handleLastNameChange}
              label={t('account.lastNameLabel') as string}
              errorMsg={formErrors.lastName}
              placeholder={t('account.namePlaceHolder') as string}
            />
          </Box>
          <Box sx={rowBoxStyle}>
            <InputField
              id="phone"
              value={phone}
              onChange={handlePhoneChange}
              label={t('account.phoneLabel') as string}
              errorMsg={formErrors.phone}
              placeholder={t('account.phonePlaceHolder') as string}
              startIcon={
                <Box sx={{ width: '81px' }}>
                  <AutocompleteField
                    id="phoneCode"
                    value={phoneCountry}
                    onChange={handlePhoneCodeChange}
                    options={countries as unknown[]}
                    optionLabel={(option) => `+${option.phone}`}
                    placeholder={t('account.countryPlaceHolder') as string}
                    errorMsg={formErrors.phoneCode}
                    disableClearable={true}
                    PaperComponent={(props) => (
                      <Paper elevation={8} {...props} style={{ width: '130px' }} />
                    )}
                    filterOptions={filterOptions}
                    noPaddingX={true}
                    borderRight={true}
                  />
                </Box>
              }
            />
            <DateField
              id="birthday"
              maxDate={getMinBirthdayDate()}
              label={t('account.birthdayLabel') as string}
              onChange={handleBirthdayChange}
              value={birthday}
              errorMsg={formErrors.birthday}
            />
          </Box>
          <Box sx={rowBoxStyle}>
            <AutocompleteField
              id="currency"
              label={t('account.currencyLabel') as string}
              value={currency}
              onChange={handleCurrencyChange}
              options={currencies as unknown[]}
              optionLabel={(option) => option.code}
              placeholder={t('account.currencyPlaceHolder') as string}
              errorMsg={formErrors.currency}
              disabled
            />
            <AutocompleteField
              id="country"
              label={t('account.countryLabel') as string}
              value={country}
              onChange={handleCountryChange}
              options={countries as unknown[]}
              optionLabel={(option) => option.label}
              placeholder={t('account.countryPlaceHolder') as string}
              errorMsg={formErrors.country}
            />
          </Box>
          <Box sx={rowBoxStyle}>
            <InputField
              id="address"
              value={address}
              onChange={handleAddressChange}
              label={t('account.addressLabel') as string}
              errorMsg={formErrors.address}
              placeholder={t('account.addressPlaceHolder') as string}
            />
          </Box>
          <Box sx={rowBoxStyle}>
            <InputField
              id="city"
              value={city}
              onChange={handleCityChange}
              label={t('account.cityLabel') as string}
              errorMsg={formErrors.city}
              placeholder={t('account.cityPlaceHolder') as string}
            />
            <InputField
              id="postCode"
              value={postCode}
              onChange={handlePostCodeChange}
              label={t('account.postCodeLabel') as string}
              errorMsg={formErrors.postCode}
              placeholder={t('account.postCodePlaceHolder') as string}
            />
          </Box>
          <Box>
            <PrimaryButton
              onClick={() => handleSave()}
              text={t('myAccount.accountPage.saveButton')}
              additionalStyle={{ mb: '8px' }}
            />
            <CancelButton
              onClick={() => navigate(PublicRoutes.Home)}
              text={t('myAccount.accountPage.cancelButton') as string}
              additionalStyle={{ color: colors.white }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: 'auto', md: '319px' },
            display: 'flex',
            flexFlow: 'column',
            gap: { xs: '32px', md: '16px' }
          }}
        >
          <Box
            sx={{
              ...backgroundProps,
              padding: { xs: '24px', md: '40px' }
            }}
          >
            <Box sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '34px' }}>
              {t('myAccount.accountPage.passwordLabel')}
            </Box>
            <Box sx={{ marginTop: '8px', fontSize: '16px', fontWeight: 400, lineHeight: '22px' }}>
              XXXXXXXX
            </Box>
            <Box sx={{ marginTop: '32px' }}>
              <Secondary
                onClick={() => setOpenPasswordForm(true)}
                text={t('myAccount.accountPage.changePasswordButton')}
                additionalStyle={{ color: colors.white }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              border: `2px solid ${colors.info.main}`,
              height: '192px',
              borderRadius: radius,
              display: 'none'
            }}
          >
            1
          </Box>
          <Box
            sx={{
              border: `2px solid ${colors.info.main}`,
              height: '192px',
              borderRadius: radius,
              display: 'none'
            }}
          >
            2
          </Box>
        </Box>
        {openPasswordForm && <PasswordChange handleClose={() => setOpenPasswordForm(false)} />}
      </Box>
      <Loader loading={isLoading || updateAccount.isLoading} />
    </Box>
  );
};

export default AccountPage;
