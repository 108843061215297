import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box, IconButton } from '@mui/material';
//* Icons
import { CheckCircle } from '@mui/icons-material';
//* Components
import InnerLoader from '../../Common/InnerLoader';
import CancelButton from '../../Common/Buttons/CancelButton';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
//* Models
import { IGameDto } from '../../../models/games';
import { IFreeSpinOfferViewModel } from '../../../models/shop';
//* Styles
import './FreeSpinsGamesList.scss';

interface IProps {
  freeSpinOffers: IFreeSpinOfferViewModel[];
  selectedFreeSpin: IFreeSpinOfferViewModel | null;
  handleSelect: (input: IFreeSpinOfferViewModel | null) => void;
}

const itemsPerPage = 20;

const FreeSpinsGamesList: React.FunctionComponent<IProps> = ({
  freeSpinOffers,
  selectedFreeSpin,
  handleSelect
}: IProps) => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const getVisibleData = () =>
    freeSpinOffers.slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage);

  return state.games.length === 0 ? (
    <InnerLoader />
  ) : (
    <Box className="free-spins__games">
      <span className="free-spins__games__title">{t('shop.freeSpins.selectGame')}</span>
      <Box className="free-spins__games-container">
        {getVisibleData().map((freeSpin, i) => {
          const game = state.games.find((gs) => gs.id === freeSpin.gameId) as IGameDto;
          const isSelected = game.id === selectedFreeSpin?.gameId;

          return (
            <Box
              key={i}
              className={`free-spins__games-container__game ${
                isSelected && 'free-spins__games-container__game--is-selected'
              }`}
              onClick={() => handleSelect(isSelected ? null : freeSpin)}
            >
              <Box
                component="img"
                src={game.thumbnailSmall}
                className="free-spins__games-container__game-image"
              />
              <Box className="free-spins__games-container__game-overlay">
                <IconButton onClick={() => handleSelect(isSelected ? null : freeSpin)}>
                  <CheckCircle sx={{ color: 'common.white', pr: '5px' }} />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </Box>
      {(page > 1 || page * itemsPerPage < freeSpinOffers.length) && (
        <Box sx={{ marginTop: '40px', display: 'flex', gap: '16px', justifyContent: 'center' }}>
          {page > 1 && (
            <CancelButton
              text={t('gamesPage.viewLessButton') as string}
              onClick={() => setPage(page - 1)}
              additionalStyle={{ color: 'common.white', width: '206px' }}
            />
          )}
          {page * itemsPerPage < freeSpinOffers.length && (
            <PrimaryButton
              text={t('gamesPage.viewMoreButton')}
              onClick={() => setPage(page + 1)}
              additionalStyle={{ width: '206px' }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default FreeSpinsGamesList;
