import React, { useContext, useEffect, useState } from 'react';
import { Box, Grow } from '@mui/material';
import { AppContext } from '../../AppContext';
import { IGameDto } from '../../models/games';
import GameCard from './GameCard';
import { useTheme } from '@mui/material/styles';
import { reOrderGames } from '../../utils/games';
import InfiniteScroll from 'react-infinite-scroll-component';
import InnerLoader from '../Common/InnerLoader';

interface IProps {
  games: IGameDto[];
  page: number;
  setPage: (value: number) => void;
  handleFavoriteClick: (id: number, gameId: string, name: string) => void;
  startGame: (game: IGameDto) => void;
}

const GamesOverview: React.FunctionComponent<IProps> = ({
  games,
  page,
  setPage,
  handleFavoriteClick,
  startGame
}) => {
  const { state } = useContext(AppContext);
  const theme = useTheme();
  const [rowItems, setRowItems] = useState(6);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const setItemsBasedOnScreenWidth = () => {
    const screenWidth = window.innerWidth;
    let newValue = 6;
    if (screenWidth <= 500) {
      newValue = 2;
      setItemsPerPage(31);
    } else if (screenWidth <= 570) {
      newValue = 3;
      setItemsPerPage(33);
    } else if (screenWidth <= 670) {
      newValue = 3;
      setItemsPerPage(33);
    } else if (screenWidth <= 1225) {
      newValue = 4;
      setItemsPerPage(35);
    } else if (screenWidth <= 1300) {
      newValue = 6;
      setItemsPerPage(33);
    } else if (screenWidth <= 1975) {
      newValue = 7;
      setItemsPerPage(40);
    } else {
      newValue = 9;
      setItemsPerPage(36);
    }
    setRowItems(newValue);
    setWindowWidth(screenWidth);
  };

  useEffect(() => {
    setItemsBasedOnScreenWidth();
    window.addEventListener('resize', setItemsBasedOnScreenWidth);
    return () => window.removeEventListener('resize', setItemsBasedOnScreenWidth);
  }, [window.innerWidth]);

  const getVisibleData = () => games.slice(0, (page - 1) * itemsPerPage + itemsPerPage);

  return (
    <Box id="games-overview" sx={{ paddingX: { xs: '6%', md: '10%', xl: '15.5%' } }}>
      <InfiniteScroll
        dataLength={getVisibleData().length}
        next={() => setPage(page + 1)}
        hasMore={getVisibleData().length !== games.length}
        loader={<InnerLoader />}
        style={{ overflow: 'hidden' }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${rowItems}, minmax(0, 1fr))`,
            gridAutoRows: {
              xs: 'minmax(127px, max-content)',
              sm: 'minmax(155px, max-content)'
            },
            gap: { xs: '8px', md: '16px' }
          }}
        >
          {reOrderGames(getVisibleData(), windowWidth, theme).map((g, i) => (
            <Grow in timeout={i * 100} key={`transition-${i}`}>
              <GameCard
                key={i}
                data={g}
                setGame={startGame}
                isSpecial={g.isPromoted}
                isFavorite={state.user.data?.favGames.includes(g.id) ?? false}
                handleFavorite={() => handleFavoriteClick(g.id, g.gameId, g.name)}
              />
            </Grow>
          ))}
        </Box>
      </InfiniteScroll>
    </Box>
  );
};

export default GamesOverview;
