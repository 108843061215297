import React from 'react';
import { t } from 'i18next';
//* MUI
import { Box } from '@mui/material';
//* Components
import ButtonTabs from '../../Common/Buttons/ButtonTabs';
//* Models
//* Enums
//* Queries
//* Utils
//* Images
//* Styles
import './ShopBanner.scss';

interface IProps {
  page: number;
  handlePageChange: (param: number) => void;
}

const ShopBanner: React.FunctionComponent<IProps> = ({ page, handlePageChange }) => {
  return (
    <Box className="shop-banner">
      <Box className="shop-banner__content">
        <Box className="shop-banner__content-title">{t('navigation.shop')}</Box>
        <Box className="shop-banner__content-message">{t('shop.banner.message')}</Box>
        <ButtonTabs
          selected={page}
          handleChange={handlePageChange}
          options={[
            { label: t('shop.navigation.wheel') },
            { label: t('shop.navigation.bonus') },
            { label: t('shop.navigation.freeSpins') }
          ]}
        />
      </Box>
    </Box>
  );
};

export default ShopBanner;
