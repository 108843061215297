import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* Styles
import './TournamentCardClockBox.scss';

interface IProps {
  title: string;
  value: string;
}

const TournamentCardClockBox = ({ title, value }: IProps) => {
  return (
    <Box
      className={`tournament-card__clock-box ${
        title === 'Hours' || title === 'Min' ? 'tournament-card__clock-box--extra-content' : ''
      }`}
    >
      <Box className="tournament-card__clock-box__value">{value}</Box>
      {title}
    </Box>
  );
};

export default TournamentCardClockBox;
