import axios from 'axios';
import { AppConsts } from '../enums/AppConsts';
import {
  AccountDetailsModel,
  ChangePasswordModel,
  IdentityVerificationModel
} from '../models/profile';

const baseUrl = `${process.env.BASE_SERVER_ADDRESS}/profile`;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);
const getHeaders = () => ({ headers: { Authorization: `Bearer ${getToken()}` } });

export const GetAccountDetailsQuery = async () => {
  return await axios.get(`${baseUrl}/AccountDetails`, getHeaders());
};

export const UpdateAccountDetailsQuery = async (data: AccountDetailsModel) => {
  return await axios.put(`${baseUrl}/UpdateDetails`, data, getHeaders());
};

export const ChangePasswordQuery = async (data: ChangePasswordModel) => {
  return await axios.patch(`${baseUrl}/ChangePassword`, data, getHeaders());
};

export const VerifyIdentityQuery = async (data: IdentityVerificationModel) => {
  return await axios.post(`${baseUrl}/VerifyIdentity`, data, getHeaders());
};

export const GetKycTokenQuery = async () => {
  return await axios.get(`${baseUrl}/GetKycToken`, getHeaders());
};

export const GetVerificationStatusQuery = async () => {
  return await axios.get(`${baseUrl}/VerificationStatus`, getHeaders());
};
