import React from 'react';
// import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Pagination, PaginationItem, Stack, SxProps } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
// import { colors } from '../../utils/theme';

interface IProps {
  itemsPerPage: number;
  totalItems: number;
  page: number;
  setPage: (value: number) => void;
  sx?: SxProps;
}

const CustomPagination: React.FunctionComponent<IProps> = ({
  itemsPerPage,
  totalItems,
  page,
  setPage,
  sx
}: IProps) => {
  const numberOfPages = Math.trunc((totalItems - 1) / itemsPerPage) + 1;
  return (
    <Stack spacing={2} sx={{ alignItems: 'end', ...sx }}>
      <Pagination
        renderItem={(item) => (
          <PaginationItem
            components={{
              previous: ArrowBack,
              next: ArrowForward
            }}
            {...item}
          />
        )}
        count={numberOfPages}
        page={page}
        color="info"
        onChange={(_, value) => setPage(value)}
        sx={{ button: { color: 'common.white' } }}
      />
    </Stack>
  );
};

export default CustomPagination;
