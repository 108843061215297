import React, { useState, useEffect, useContext } from 'react';
import { Box, SxProps } from '@mui/material';
import { gameContainerProps } from './HomeGameSection';
import treasure from '../../assets/homePage/treasure.svg';
import PrimaryButton from '../Common/Buttons/PrimaryButton/PrimaryButton';
import ArrowButton from '../Common/Buttons/ArrowButton';
import { HomeScreenPromotions } from '../../queries/bonus';
import { useMutation } from 'react-query';
import { handleError } from '../../utils/ui';
import { AppContext } from '../../AppContext';
import { IBonus } from '../../models/bonus';
import { useTranslation } from 'react-i18next';
import { isUserLoggedIn } from '../../utils/validators';
import { AuthRoutes } from '../../enums/RouteEnums';
import { useNavigate } from 'react-router-dom';
import { Actions } from '../../enums/ActionEnums';
import backGround from '../../assets/homePage/promo_back.svg';

const getArrowButtonStyle = (isRight?: boolean) => {
  return {
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    ...(isRight && { right: { xs: 0, md: '40px' } }),
    ...(!isRight && { left: { xs: 0, md: '40px' } }),
    zIndex: 4,
    height: '40px',
    opacity: { xs: 0.6, md: 1 },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .25)'
    }
  } as SxProps;
};

const PromoBannerContainer: React.FunctionComponent = () => {
  const { i18n, t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const [index, setIndex] = useState(0);
  const [promotions, setPromotions] = useState<IBonus[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    promosQuery.mutate();
  }, []);

  const promosQuery = useMutation(HomeScreenPromotions, {
    onSuccess: ({ data }) => {
      if (data.length) {
        setPromotions(data);
      }
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const handleDeposit = () => {
    const promo = promotions[index];
    if (isUserLoggedIn()) {
      navigate(AuthRoutes.Wallet, { state: { promo } });
    } else {
      dispatch({
        type: Actions.ShowLoginPopup,
        payload: { direction: AuthRoutes.Wallet, state: { promo } }
      });
    }
  };

  return (
    <>
      {!promosQuery.isLoading && promotions.length > 0 && (
        <Box sx={gameContainerProps()}>
          <Box
            sx={{
              display: 'flex',
              flexFlow: { xs: 'column', md: 'column', lg: 'row' },
              borderRadius: '8px',
              backgroundImage: `url(${backGround})`,
              backgroundSize: 'cover',
              padding: { xs: '8px 16px', md: '24px 111px' },
              position: 'relative',
              alignItems: 'center',
              gap: { xs: '4px', md: '16px' }
            }}
          >
            {promotions.length > 1 && (
              <ArrowButton
                handleClick={() => setIndex(index === 0 ? promotions.length - 1 : index - 1)}
                sx={getArrowButtonStyle()}
              />
            )}
            <Box
              component="img"
              src={treasure}
              sx={{ width: { md: '541px', xs: '343px' }, height: { md: '360px', xs: '230px' } }}
            />
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'start',
                paddingLeft: { xs: 0, lg: '40px' }
              }}
            >
              <Box sx={{ fontWeight: 600, fontSize: '40px' }}>
                {
                  promotions[index]?.promoDetails.find(
                    (d) => d.language.toUpperCase() === i18n.language.toUpperCase()
                  )?.title
                }
              </Box>
              <Box sx={{ fontWeight: 400, fontSize: '16  px' }}>
                {
                  promotions[index]?.promoDetails.find(
                    (d) => d.language.toUpperCase() === i18n.language.toUpperCase()
                  )?.description
                }
              </Box>
              <PrimaryButton
                text={t('promotionsPage.deposit')}
                onClick={handleDeposit}
                additionalStyle={{
                  marginTop: '32px',
                  width: { xs: '100%', lg: 'auto' },
                  padding: '12px 32px'
                }}
              />
            </Box>
            {promotions.length > 1 && (
              <ArrowButton
                isRight
                handleClick={() => setIndex(index + 1 === promotions.length ? 0 : index + 1)}
                sx={getArrowButtonStyle(true)}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default PromoBannerContainer;
