import React from 'react';
import { t } from 'i18next';
//* MUI
import { Box } from '@mui/material';
//* Icons
import FlagIcon from '@mui/icons-material/Flag';
import StarsIcon from '@mui/icons-material/Stars';
//* Enums
import { PublicRoutes } from '../../../../enums/RouteEnums';
//* Styles
import './LevelInformation.scss';

interface IProps {
  loyaltyPoints: number;
  onLinkClick: (path: string) => void;
  completedChallengesPercentage: number;
}

const LevelInformation = ({
  onLinkClick,
  loyaltyPoints,
  completedChallengesPercentage
}: IProps) => {
  return (
    <Box className="level-information">
      <Box className="level-information__row">
        <Box className="level-information__title">{t('navigation.nextLevel')}</Box>
        <Box
          className="level-information__link"
          onClick={() => onLinkClick(PublicRoutes.Challenges)}
        >
          <FlagIcon className="level-information__link-icon" />
          {t('navigation.viewMap')}
        </Box>
      </Box>
      <Box className="level-information__row">
        <Box className="level-information__bar">
          <Box
            className="level-information__bar-progress"
            sx={{ width: `${completedChallengesPercentage}%` }}
          ></Box>
        </Box>
      </Box>
      <Box className="level-information__row">
        <Box className="level-information__progress-title">{t('myAccount.coins')}</Box>
        <Box className="level-information__progress-title">
          {loyaltyPoints} <StarsIcon className="level-information__progress-title-icon" />
        </Box>
      </Box>
    </Box>
  );
};

export default LevelInformation;
