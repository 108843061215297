import React, { useContext, forwardRef, useEffect, useState } from 'react';
import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { IGameDto } from '../../models/games';
import { colors } from '../../utils/theme';
import { Favorite, FavoriteBorder, PeopleAlt, PlayArrowOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import { isUserLoggedIn } from '../../utils/validators';
import { Actions } from '../../enums/ActionEnums';
import { PublicRoutes } from '../../enums/RouteEnums';
import './index.css';

interface IProps {
  data: IGameDto;
  setGame: (game: IGameDto) => void;
  isSpecial?: boolean;
  isFavorite: boolean;
  handleFavorite: () => void;
  sx?: SxProps;
  showCount?: boolean;
  isHome?: boolean;
}
const randomIntFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const borderRadius = '16px';

const GameCard: React.FunctionComponent<IProps> = forwardRef(function GameCard(
  {
    data,
    setGame,
    isSpecial = false,
    isFavorite,
    handleFavorite,
    sx,
    showCount = false,
    isHome = false
  }: IProps,
  ref
) {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const [randomValue, setRandomValue] = useState(randomIntFromInterval(500, 2500));
  const theme = useTheme<Theme>();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const updateRandomValue = () => {
      setRandomValue(randomIntFromInterval(500, 2500));
    };

    updateRandomValue();

    const intervalId = setInterval(updateRandomValue, 15000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSetGame = (data: IGameDto) => {
    if (isUserLoggedIn()) {
      setGame(data);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const endpoint = `/games/${data.gameId}-${data.name.replaceAll(' ', '')}`;

      dispatch({
        type: Actions.ShowLoginPopup,
        payload: {
          direction: endpoint,
          state: { path: PublicRoutes.Games }
        }
      });
    }
  };

  const getTumbnail = (): string => {
    if (isSpecial) {
      return data.thumbnailLarge;
    } else if (isHome) {
      if (data.thumbnailMedium && data.thumbnailMedium.length > 0) {
        return data.thumbnailMedium;
      } else {
        return data.thumbnailSmall;
      }
    } else {
      return data.thumbnailSmall;
    }
  };

  return (
    <Box
      onClick={() => handleSetGame(data)}
      ref={ref}
      sx={{
        cursor: 'pointer',
        display: 'grid',
        position: 'relative',
        borderRadius,
        border: `1px solid ${colors.info.main}`,
        ...(isSpecial && { gridRow: 'span 2', gridColumn: 'span 2' }),
        '&:hover': {
          borderWidth: '2px',
          background: '#0D1365',
          boxShadow: '0px 0px 15px 0px rgba(37, 117, 252, 0.70)',
          '.new_label, .count_label, .image_container': {
            filter: 'blur(4px)',
            transition: 'filter 0.5s ease'
          },
          div: {
            display: 'flex'
          }
        },
        ...sx
      }}
    >
      {data.isNew && (
        <Box
          className="new_label"
          sx={{
            transition: 'filter 0.5s ease'
          }}
        >
          {t('gamesPage.newLabel').toUpperCase()}
        </Box>
      )}
      {showCount && (
        <Box
          className="count_label border-blink"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
            padding: '0 6px',
            position: 'absolute',
            top: '6px',
            right: '6px',
            background: colors.background.blue,
            borderRadius: '16px',
            boxShadow:
              '4px 4px 16px rgba(182, 238, 173, 0.5), -4px -4px 16px rgba(182, 238, 173, 0.5)',
            zIndex: 1,
            transition: 'filter 0.5s ease'
          }}
        >
          <Box
            sx={{
              border: `1px solid ${colors.white}`,
              width: '6px',
              height: '6px',
              borderRadius: '100px',
              backgroundColor: colors.statuses.success
            }}
          />
          <PeopleAlt
            sx={{
              colors: 'common.white',
              width: '16px',
              height: '16px'
            }}
          />
          <Box sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '20px' }}>
            {randomValue.toLocaleString('en-US')}{' '}
          </Box>
        </Box>
      )}
      <Box
        className="image_container"
        sx={{
          backgroundImage: `url(${getTumbnail()})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          borderRadius,
          gridRow: '1/2',
          gridColumn: '1/2',
          width: '100%',
          height: '100%',
          transition: 'filter 0.5s ease'
        }}
      />
      <Box
        sx={{
          position: 'relative',
          gridRow: '1/2',
          gridColumn: '1/2',
          width: '100%',
          height: '100%',
          zIndex: 2,
          borderRadius,
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {!isMobileScreen ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              bottom: '50px',
              alignItems: 'center',
              transition: 'background 0.3s ease',
              fontFamily: 'Poppins',
              fontSize: { xs: '12px', sm: '12px', md: '16px' },
              fontWeight: 700,
              textAlign: 'center'
            }}
          >
            {data.name}
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Box
        sx={{
          position: 'relative',
          gridRow: '1/2',
          gridColumn: '1/2',
          width: '100%',
          height: '100%',
          zIndex: 2,
          borderRadius,
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          onClick={() => handleSetGame(data)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
            padding: '6px',
            paddingRight: '14px',
            cursor: 'pointer',
            // p: 0,
            // width: isSpecial ? '88px' : '44px',
            // height: isSpecial ? '88px' : '44px',
            border: `2px solid ${colors.info.main}`,
            borderRadius: '50px',
            background: 'rgba(0, 0, 0, 0.3)',
            boxShadow: '0px 0px 5px rgba(37, 117, 252, 0.5)',
            '&:hover': {
              background: colors.info.main
            },
            '&:active': {
              background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2575FC'
            },
            transition: 'background 0.3s ease'
          }}
        >
          <PlayArrowOutlined sx={{ color: 'common.white', width: '24px', height: '24px' }} />
          <Box sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '22px' }}>
            {t('gamesPage.playButtonLabel')}
          </Box>
        </Box>
        {state.user.data && (
          <>
            {isFavorite ? (
              <Favorite
                sx={{
                  position: 'absolute',
                  left: 10,
                  bottom: 10,
                  color: colors.gradients.yellowRgb,
                  cursor: 'pointer',
                  width: isSpecial ? '48px' : '24px',
                  height: isSpecial ? '48px' : '24px'
                }}
                onClick={() => handleFavorite()}
              />
            ) : (
              <FavoriteBorder
                sx={{
                  position: 'absolute',
                  left: 10,
                  bottom: 10,
                  color: colors.info.main,
                  cursor: 'pointer',
                  width: isSpecial ? '48px' : '24px',
                  height: isSpecial ? '48px' : '24px'
                }}
                onClick={() => handleFavorite()}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
});

export default GameCard;
