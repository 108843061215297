import React, { useRef } from 'react';
import { oneOf } from 'prop-types';
//* MUI
import { Box } from '@mui/material';
//* Styles
import './CategoryFilter.scss';

interface ICategoryFilter {
  text: string;
  size?: string;
  image?: unknown;
  filterType: string;
  selectedType: string;
  onClick: (filterType: string) => void;
}

const CategoryFilter = ({
  text,
  size,
  image,
  onClick,
  filterType,
  selectedType
}: ICategoryFilter) => {
  const categoryFilterTileRef = useRef<HTMLElement | null>(null);

  const isSelected = selectedType === filterType;

  const handleOnClick = () => {
    onClick(filterType);
    categoryFilterTileRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    });
  };

  return (
    <Box
      ref={categoryFilterTileRef}
      onClick={handleOnClick}
      className={`category category--${size} ${isSelected ? 'category--selected' : ''}`}
      sx={{
        backgroundImage: `url(${image})`
      }}
    >
      {text}
    </Box>
  );
};

CategoryFilter.propTypes = {
  size: oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl'])
};

CategoryFilter.defaultProps = {
  size: 'm'
};

export default CategoryFilter;
