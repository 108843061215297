import { AppConsts } from '../enums/AppConsts';
import { BannerModel, BannerTranslation } from '../models/banner';

export const getCorrectTranslation = (item: BannerModel, i18n, state) => {
  const defaultLang = 'en';
  const transWithCurrentLang = item.translations.find(
    (t) => t.language.toLocaleLowerCase() === i18n.language.toLocaleLowerCase()
  );
  const resultTransWithCurrentLang =
    transWithCurrentLang ??
    item.translations.find(
      (t) => t.language.toLocaleLowerCase() === defaultLang.toLocaleLowerCase()
    );
  if (resultTransWithCurrentLang) {
    const lines = Object.keys(resultTransWithCurrentLang).filter((key) => {
      return ~key.indexOf('line');
    });

    lines.map((line) => {
      const lineKey = line as keyof BannerTranslation;
      const resultTransLineWithCurrentLang = resultTransWithCurrentLang[lineKey] || '';

      if (resultTransLineWithCurrentLang.indexOf(AppConsts.CurrencyPlaceholder) > -1) {
        resultTransWithCurrentLang[lineKey] = resultTransLineWithCurrentLang.replace(
          AppConsts.CurrencyPlaceholder,
          `${item.userCurrency?.value ?? ''} ${item.userCurrency?.name ?? ''}`
        );
      }

      if (resultTransLineWithCurrentLang.indexOf(AppConsts.FullNamePlaceholder) > -1) {
        let fullName = '';
        if (state.user.data) {
          fullName = `${state.user.data.firstName} ${state.user.data.lastName}`;
        }
        resultTransWithCurrentLang[lineKey] = resultTransLineWithCurrentLang.replace(
          AppConsts.FullNamePlaceholder,
          fullName
        );
      }
    });
  }

  if (resultTransWithCurrentLang?.buttonText.toLowerCase() === 'register' && state.user.data) {
    resultTransWithCurrentLang.buttonText = 'Deposit';
  }

  return resultTransWithCurrentLang;
};
