import React from 'react';
import { Box } from '@mui/material';
import { InfoPair } from '../../../models/bonus';
import './InfoPairs.scss';

interface IProps {
  items: InfoPair[];
}

const InfoPairs: React.FunctionComponent<IProps> = ({ items }: IProps) => {
  return (
    <Box className="info-pairs-container">
      {items.map((item, index) => (
        <Box key={index} className="info-pairs-container__row">
          <Box className="info-pairs-container__row__key">{item.key}</Box>
          <Box className="info-pairs-container__row__value">{item.value}</Box>
        </Box>
      ))}
    </Box>
  );
};

export default InfoPairs;
