import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  InputAdornment,
  InputLabel,
  TextField
} from '@mui/material';
import { colors } from '../../utils/theme';
import { ArrowForwardIosSharp, Check, ConfirmationNumber, Payments } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { IPaymentMethodDto, paymentAmountOptions } from '../../utils/paymentConstants';
import { useTranslation } from 'react-i18next';
import { labelStyle } from '../Account/SigninPage';
import { getBorderColor } from '../Account/SignupPage';
import {
  Deposit,
  DepositBalance,
  GetPaymentProviders,
  GetPlayerCards,
  PlayerBonuses
} from '../../queries/wallet';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import Loader from '../Common/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../enums/RouteEnums';
import {
  IDeposit,
  IDepositResult,
  IPaymentProviderData,
  IPlayerCard,
  IUpdateBalance
} from '../../models/wallet';
import { IBonus } from '../../models/bonus';
import { getUserCurrencySymbol, handleError } from '../../utils/ui';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import InnerLoader from '../Common/InnerLoader';
import { GetBonusDetails } from '../../queries/bonus';
import PaymentModal from './PaymentModal';
import { isMobile } from 'react-device-detect';
import { Payment } from '../../enums/RequestEnums';
import axios from 'axios';
import CardForm from './CardForm';
import CustomRadioButton from '../Common/Buttons/CustomRadioGroup';

export const radius = '8px';

export const accordionProps: SxProps = {
  backgroundColor: 'transparent',
  color: 'white'
};

export const dropDownIconProps: SxProps = {
  width: '15px',
  height: '15px',
  color: 'common.white'
};

export const titleLabelProps: SxProps = {
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '25px',
  color: 'common.white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const accordionDetailsProps: SxProps = {
  padding: { xs: '11px', md: '11px 23px 23px 23px' },
  border: '1px solid white',
  borderTop: 'none',
  borderBottomLeftRadius: radius,
  borderBottomRightRadius: radius
};

export const summaryTitleProps: SxProps = {
  marginTop: '16px',
  display: 'flex',
  alignItems: 'center'
};

const summaryTitleLeftSideProps: SxProps = {
  flexGrow: 1,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px'
};

const summaryTitleRightSideProps: SxProps = {
  flexGrow: 0,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '22px'
};

export const containerProps: SxProps = {
  background: colors.background.blue,
  borderRadius: radius,
  padding: { xs: '24px', md: '56px' },
  paddingTop: { xs: '8px', md: '40px' }
};

export const getAccordionSummaryProps = (step: number, expanded: number) => {
  return {
    p: { xs: '11px', md: '11px 23px' },
    m: 0,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    },
    border: '1px solid white',
    borderBottom: expanded === step ? 'none' : '1px solid white',
    borderTopLeftRadius: radius,
    borderTopRightRadius: radius,
    borderBottomLeftRadius: expanded === step ? '0px' : radius,
    borderBottomRightRadius: expanded === step ? '0px' : radius,
    marginBottom: '0px',
    marginTop: '16px',
    '& .MuiAccordionSummary-contentGutters': {
      m: 0
    },
    '& .Mui-expanded': {
      m: '0 !important'
    }
  } as SxProps;
};

export const getStepStyles = (step: number, expanded: number) => {
  const isActiveStep = step === expanded;
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    lineHeight: '24px',
    minWidth: '32px',
    height: '32px',
    backgroundColor: isActiveStep ? 'common.white' : 'transparent',
    border: `2px solid ${isActiveStep ? colors.info.main : colors.white}`,
    color: isActiveStep ? colors.info.main : colors.white,
    padding: '4px',
    borderRadius: '100px',
    marginRight: '16px'
  } as SxProps;
};

export const getCheckIcon = () => (
  <Box
    sx={{
      borderRadius: '100px',
      backgroundColor: colors.info.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '32px',
      height: '32px',
      marginRight: '16px'
    }}
  >
    <Check sx={{ color: colors.white, width: '30px', height: '30px' }} />
  </Box>
);

const DepositPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { success, order, order_id, result, customer_order_id, status, Reply, Order } = parsed;
  const appliedBonus = location.state?.bonus as IBonus | undefined;
  const { dispatch, state } = useContext(AppContext);
  const [expanded, setExpanded] = useState(0);
  const [cards, setCards] = useState<IPlayerCard[]>([]);
  const [providersData, setProvidersData] = useState<IPaymentProviderData>({
    playerHasDeposited: false,
    providers: []
  });
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<IPaymentMethodDto | null>(null);
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const [selectedCoupon, setSelectedCoupon] = useState<IBonus | null>(appliedBonus ?? null);
  const [selectedCouponCode, setSelectedCouponCode] = useState(
    appliedBonus ? appliedBonus.code : ''
  );

  const [availableBonuses, setAvailableBonuses] = useState<IBonus[]>([]);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [openCardForm, setOpenCardForm] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [expiration, setExpiration] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const userCurrency = getUserCurrencySymbol(state.user.data?.currency);
  const add_new_card = t('wallet.depositPage.addNewCard');

  const queryClient = useQueryClient();
  const bonusQuery = useMutation(PlayerBonuses, {
    onSuccess: ({ data }) => {
      const bonuses = data as IBonus[];
      setAvailableBonuses(bonuses.filter((b) => b.type === 'Deposit'));
    },
    onSettled: () => {
      queryClient.invalidateQueries('PlayerBonuses');
    }
  });

  const playerCardsQuery = useMutation(GetPlayerCards, {
    onSuccess: ({ data }) => {
      const playerCards = data as IPlayerCard[];
      setCards(playerCards);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const paymentProvidersQuery = useMutation(GetPaymentProviders, {
    onSuccess: ({ data }) => {
      const providers = data as IPaymentProviderData;
      setProvidersData(providers);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  useEffect(() => {
    playerCardsQuery.mutate();
    paymentProvidersQuery.mutate();
  }, []);

  const { mutate, isLoading } = useMutation(Deposit, {
    onSuccess: ({ data }) => {
      setOpenCardForm(false);
      const depositResult = data as IDepositResult;
      if (depositResult.redirectUrl && depositResult.redirectUrl.length > 0) {
        setPaymentUrl(data.redirectUrl);
        if (data.shouldRedirect === true) {
          window.location.href = data.redirectUrl;
        } else {
          setOpenPaymentModal(true);
        }
      } else if (depositResult.applied && depositResult.balance) {
        dispatch({
          type: Actions.UpdateBalance,
          payload: depositResult.balance
        });
        dispatch({
          type: Actions.ShowMessage,
          payload: {
            severity: 'success',
            text: t('wallet.depositPage.successfull')
          }
        });
        navigate(PublicRoutes.Games);
      } else {
        dispatch({
          type: Actions.ShowMessage,
          payload: {
            severity: 'error',
            text: t('wallet.depositPage.unsuccessfull')
          }
        });
      }
    },
    onError: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'error',
          text: t('wallet.depositPage.unsuccessfullInvalidBonusCode')
        }
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries('deposit');
    }
  });

  const balanceUpdate = useMutation(DepositBalance, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.UpdateBalance,
        payload: data as IUpdateBalance
      });
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('wallet.depositPage.successfull')
        }
      });

      navigate(PublicRoutes.Games);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('deposit');
    }
  });

  const bonusDetails = useMutation(GetBonusDetails, {
    onSuccess: ({ data }) => {
      setSelectedCoupon(data);
      setSelectedCouponCode(data.code);
      setExpanded(3);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('deposit');
    }
  });

  useEffect(() => {
    bonusQuery.mutate();
    if (order && order.length > 0) {
      setPaymentUrl('');
      setOpenPaymentModal(false);

      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('wallet.depositPage.processingYourDepositPayloadMessage')
        }
      });
    } else if (
      (order_id && order_id.length > 0 && result && result === 'success') ||
      (customer_order_id && customer_order_id.length > 0 && status === 'success') ||
      (Reply && Reply === '000' && Order && Order.length > 0)
    ) {
      let message = `${t('wallet.depositPage.processingYourDepositPayloadMessage')}. `;
      if (Reply && Reply === '000' && Order && Order.length > 0) {
        message += `${t('wallet.depositPage.itMayTakeUpFiveMinutes')}.`;
      }
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: message
        }
      });
      //const order = order_id && order_id.length > 0 ? order_id : customer_order_id;
      let order;
      if (order_id && order_id.length > 0) {
        order = order_id;
      } else if (customer_order_id && customer_order_id.length > 0) {
        order = customer_order_id;
      } else {
        order = Order;
      }
      balanceUpdate.mutate(order as string);
    } else if (
      (success && success === 'false') ||
      (result && result === 'failed') ||
      (status && status === 'fail') ||
      (Reply && Reply === '002')
    ) {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'error',
          text: t('wallet.depositPage.depositFailedPayloadMessage')
        }
      });
    }
  }, []);

  const handleChange = (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : -1);
  };

  const isAmountValid = () =>
    selectedAmount !== null && selectedAmount >= paymentAmountOptions[0] && selectedAmount <= 1000;

  const getDepositTotal = () => (selectedAmount ?? 0) + getBonusAmount();

  const makeDeposit = async () => {
    if (selectedPayment?.name === Payment.Payneteasy) {
      const input = await getPayneteasyData();
      mutate(input);
    } else if (selectedPayment?.name === Payment.SkyChain) {
      const input = getSkyChainData();
      mutate(input);
    } else {
      if (selectedPayment === null) {
        setSelectedPayment(providersData.providers.find((p) => p.isForFtd === false) ?? null);
      }
      setOpenCardForm(true);
    }
  };

  const getPayneteasyData = async () => {
    const res = await axios.get('https://api.ipify.org/?format=json');
    const input = {
      amount: selectedAmount as number,
      bonusCode: selectedCouponCode,
      orderId: '',
      payneteasy: Payment.Payneteasy,
      ipAddress: res.data.ip
    } as IDeposit;
    return input;
  };

  const makeGumballDeposit = async () => {
    const input = await getGumbleData();
    mutate(input);
  };

  const getAmaldData = async () => {
    const client_orderid = uuidv4();
    const res = await axios.get('https://api.ipify.org/?format=json');

    const input = {
      amount: selectedAmount,
      bonusCode: selectedCouponCode,
      orderId: client_orderid,
      amald: 'Amald',
      ipAddress: res.data.ip,
      cardNumber: cardNumber.trim(),
      cvv,
      cardHolderName,
      expiration: expiration.trim()
    } as IDeposit;
    return input;
  };

  const getGumbleData = async () => {
    const endpointid = process.env.GUMBALL_ENDPOINT_ID;
    const client_orderid = uuidv4();
    const email = state.user.data?.email;
    const merchant_control = process.env.GUMBALL_MERCHANT_CONTROL;
    const controlInput =
      endpointid + client_orderid + (selectedAmount ?? 0) * 100 + email + merchant_control;
    const control = CryptoJS.SHA1(controlInput).toString();
    const res = await axios.get('https://api.ipify.org/?format=json');

    const input = {
      amount: selectedAmount,
      bonusCode: selectedCouponCode,
      orderId: client_orderid,
      gumballHash: control,
      ipAddress: res.data.ip,
      cardNumber,
      cvv,
      cardHolderName,
      expiration: expiration.trim()
    } as IDeposit;
    return input;
  };

  const getSkyChainData = (): IDeposit => {
    const orderId = uuidv4();
    const input = {
      amount: selectedAmount as number,
      bonusCode: selectedCouponCode,
      orderId,
      skyChain: Payment.SkyChain
    } as IDeposit;
    return input;
  };

  const handleCouponCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const coupon = availableBonuses.find((b) => b.code.toUpperCase() === value.toUpperCase());
    setSelectedCoupon(coupon ?? null);
    setSelectedCouponCode(value);
  };

  const getBonusAmount = () => {
    const rations = selectedCoupon?.rationSteps ?? [];
    const sortedRations = rations.sort((a, b) => b.minAmount - a.minAmount);
    let bonusAmount = 0;
    for (let i = 0; i < sortedRations.length; i++) {
      const currentRation = sortedRations[i];
      if (selectedAmount && selectedAmount >= currentRation.minAmount) {
        const multiplier =
          selectedAmount > currentRation.maxAmount ? currentRation.maxAmount : selectedAmount;
        if (selectedCoupon && selectedCoupon.currencies[0]) {
          bonusAmount =
            selectedCoupon.currencies[0].ratioType === 'Percent'
              ? multiplier * (currentRation.ratio / 100)
              : currentRation.ratio;
        }
        break;
      }
    }
    return bonusAmount;
  };

  const handleCouponButtonClicked = () => {
    selectedCouponCode.length > 0 ? bonusDetails.mutate(selectedCouponCode) : setExpanded(4);
  };

  const handleBonusClicked = (bonus: IBonus) => {
    setSelectedCoupon(selectedCoupon?.code === bonus.code ? null : bonus);
    setSelectedCouponCode(selectedCouponCode === bonus.code ? '' : bonus.code);
    setExpanded(4);
  };

  const getPaymentMethods = () => {
    if (providersData.playerHasDeposited && selectedCard !== add_new_card) {
      return providersData.providers.filter((pm) => pm.isForStd === true);
    } else {
      return providersData.providers.filter((pm) => pm.isForFtd === true);
    }
  };

  const submitDeposit = async () => {
    if (selectedPayment?.name === Payment.Gumball) {
      await makeGumballDeposit();
    } else if (selectedPayment?.name === Payment.Amald) {
      const input = await getAmaldData();
      mutate(input);
    } else if (selectedPayment?.name === Payment.Finateco) {
      const res = await axios.get('https://api.ipify.org/?format=json');
      const input = {
        finateco: Payment.Finateco,
        ipAddress: res.data.ip,
        amount: selectedAmount,
        bonusCode: selectedCouponCode,
        cardNumber,
        cvv,
        cardHolderName,
        expiration: expiration.trim()
      } as IDeposit;
      mutate(input);
    }
  };

  const handleCardChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const value = (e.target as HTMLInputElement).value;
    if (value !== undefined) {
      setSelectedCard(value);
    }
  };

  const resizePanNumber = (panNumber: string) => {
    return panNumber.substring(8, 16);
  };

  const options = [
    ...cards.map((card) => ({
      value: resizePanNumber(card.panNumber),
      label: resizePanNumber(card.panNumber)
    })),
    { value: add_new_card, label: add_new_card }
  ];

  return (
    <Box sx={containerProps}>
      {balanceUpdate.isLoading ? (
        <InnerLoader />
      ) : (
        <>
          <Accordion expanded={expanded === 0} onChange={handleChange(0)} sx={accordionProps}>
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharp sx={dropDownIconProps} />}
              aria-controls={`panel-payment-card-content`}
              id={`panel-payment-card-header`}
              sx={getAccordionSummaryProps(0, expanded)}
            >
              <Box sx={titleLabelProps}>
                {selectedCard ? getCheckIcon() : <Box sx={getStepStyles(0, expanded)}>1</Box>}
                {t('wallet.depositPage.cardSelection')}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailsProps}>
              <Divider style={{ background: 'white', marginTop: '-8px', marginBottom: 12 }} />
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  gap: { xs: '8px', md: '16px' },
                  flexDirection: 'column'
                }}
              >
                {options.map((option, index) => (
                  <Box
                    key={index}
                    onClick={() => {
                      setExpanded(1);
                    }}
                  >
                    <CustomRadioButton
                      id="bonus-promo-show-on-home"
                      value={selectedCard}
                      handleChange={handleCardChange}
                      options={[{ value: option.value, label: option.label }]}
                      containerStyles={{ height: '69px' }}
                    />
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 1} onChange={handleChange(1)} sx={accordionProps}>
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharp sx={dropDownIconProps} />}
              aria-controls={`panel-payment-method-content`}
              id={`panel-payment-method-header`}
              sx={getAccordionSummaryProps(1, expanded)}
            >
              <Box sx={titleLabelProps}>
                {selectedPayment ? getCheckIcon() : <Box sx={getStepStyles(1, expanded)}>2</Box>}
                {t('wallet.depositPage.paymentMethod')}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailsProps}>
              <Divider style={{ background: 'white', marginTop: '-8px', marginBottom: 12 }} />
              <Box sx={{ display: 'flex', flexFlow: 'row wrap', gap: { xs: '8px', md: '16px' } }}>
                {getPaymentMethods().map((p, index) => (
                  <Box
                    key={index}
                    sx={{
                      // borderRadius: radius,
                      // background: selectedPayment === p ? colors.white : colors.background.grey,
                      width: { md: '227px', xs: '131.5px' },
                      height: '80px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      // border: `2px solid ${colors.white}`,
                      '&:hover': {
                        // background: `${colors.white}`,
                        '& img': {
                          filter: 'invert(100%)'
                        }
                      },
                      '& img': {
                        filter: selectedPayment === p ? 'invert(100%)' : 'invert(0)'
                      }
                    }}
                    onClick={() => {
                      setSelectedPayment(p);
                      setExpanded(2);
                    }}
                  >
                    <Box
                      component="img"
                      src={p.img as string}
                      alt={'logo'}
                      sx={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 2} onChange={handleChange(2)} sx={accordionProps}>
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharp sx={dropDownIconProps} />}
              aria-controls={`panel-payment-amount-content`}
              id={`panel-payment-amount-header`}
              sx={getAccordionSummaryProps(2, expanded)}
            >
              <Box sx={titleLabelProps}>
                {isAmountValid() ? getCheckIcon() : <Box sx={getStepStyles(2, expanded)}>3</Box>}
                {t('wallet.depositPage.paymentAmount')}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailsProps}>
              <Divider style={{ background: 'white', marginTop: '-8px', marginBottom: 12 }} />
              <Box sx={{ display: 'flex', flexFlow: 'row wrap', gap: { xs: '8px', md: '16px' } }}>
                {paymentAmountOptions.map((p, index) => (
                  <Box
                    key={index}
                    sx={{
                      borderRadius: radius,
                      background: selectedAmount === p ? colors.white : colors.background.grey,
                      width: { md: '166.5px', xs: '131.5px' },
                      height: '66px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      border: `2px solid ${colors.white}`,
                      '&:hover': {
                        background: `${colors.white}`,
                        '& span': {
                          color: 'common.black'
                        }
                      },
                      '& span': {
                        color: selectedAmount === p ? 'common.black' : 'common.white'
                      }
                    }}
                    onClick={() => {
                      setSelectedAmount(p);
                      setExpanded(3);
                    }}
                  >
                    <Box
                      component="span"
                      sx={{ fontSize: '24px', lineHeight: '34px', fontWeight: 600 }}
                    >
                      {userCurrency}
                      {p}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                <InputLabel
                  htmlFor="otherAmount"
                  style={{ ...labelStyle, color: colors.white, textAlign: 'left' }}
                >
                  {t('wallet.depositPage.otherAmount')}
                </InputLabel>
                <TextField
                  margin="normal"
                  fullWidth
                  id="otherAmount"
                  name="otherAmount"
                  type="number"
                  color="info"
                  placeholder={t('wallet.depositPage.amountPlaceholder') as string}
                  value={selectedAmount || ''}
                  onChange={(e) =>
                    setSelectedAmount(e.currentTarget.value ? Number(e.currentTarget.value) : null)
                  }
                  error={!isAmountValid()}
                  helperText={t('wallet.depositPage.amountHelperText')
                    .toString()
                    .replace(/\€/g, userCurrency)}
                  FormHelperTextProps={{
                    sx: { fontSize: '14px', lineHeight: '20px', color: colors.grey.placeholder }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Payments
                          sx={{ color: selectedAmount ? colors.info.main : colors.white }}
                        />
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    marginBottom: 0,
                    '& .MuiOutlinedInput-root': {
                      height: '46px',
                      backgroundColor: selectedAmount ? colors.white : colors.background.grey,
                      '& fieldset': {
                        borderColor: getBorderColor(selectedAmount?.toString())
                      }
                    }
                  }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 3} onChange={handleChange(3)} sx={accordionProps}>
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharp sx={dropDownIconProps} />}
              aria-controls={`panel-payment-coupon-content`}
              id={`panel-payment-coupon-header`}
              sx={getAccordionSummaryProps(3, expanded)}
            >
              <Box sx={titleLabelProps}>
                {selectedCouponCode.length > 0 || expanded === 4 ? (
                  getCheckIcon()
                ) : (
                  <Box sx={getStepStyles(3, expanded)}>4</Box>
                )}
                {t('wallet.depositPage.paymentCoupon')}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailsProps}>
              <Divider style={{ background: 'white', marginTop: '-8px', marginBottom: 12 }} />
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '24px', textAlign: 'left' }}>
                {availableBonuses.map((b) => (
                  <Box
                    key={b.id}
                    sx={{
                      borderRadius: radius,
                      background:
                        selectedCoupon?.code === b.code ? colors.white : colors.background.grey,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      border: `2px solid ${colors.white}`,
                      p: '14px',
                      '&:hover': {
                        background: `${colors.white}`,
                        '& div': {
                          color: 'common.black'
                        },
                        '& .dot': {
                          borderColor: colors.info.main,
                          boxShadow: '0px 0px 5px rgba(37, 117, 252, 0.5)'
                        }
                      },
                      '& div': {
                        color: selectedCoupon?.code === b.code ? 'common.black' : 'common.white'
                      },
                      '& .dot': {
                        borderColor:
                          selectedCoupon?.code === b.code ? colors.info.main : colors.grey.main,
                        boxShadow:
                          selectedCoupon?.code === b.code
                            ? '0px 0px 5px rgba(37, 117, 252, 0.5)'
                            : 'none'
                      }
                    }}
                    onClick={() => handleBonusClicked(b)}
                  >
                    <Box
                      sx={{ display: 'flex', flexFlow: 'row', gap: '16px', alignItems: 'center' }}
                    >
                      <Box
                        className="dot"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minWidth: '16px',
                          height: '16px',
                          borderRadius: '100px',
                          border: `1px solid ${colors.grey.main}`,
                          backgroundColor: colors.white
                        }}
                      >
                        {selectedCoupon?.id === b.id && (
                          <Box
                            component="span"
                            sx={{
                              minWidth: '8px',
                              height: '8px',
                              backgroundColor: colors.info.main,
                              borderRadius: '100px'
                            }}
                          />
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', flexFlow: 'column' }}>
                        <Box sx={{ fontSize: '16px', lineHeight: '22px', fontWeight: 500 }}>
                          {b.code}
                        </Box>
                        <Box sx={{ fontSize: '14px', lineHeight: '20px', marginTop: '4px' }}>
                          {b.promoDetails.find(
                            (d) => d.language.toUpperCase() === i18n.language.toUpperCase()
                          )?.description ?? b.promoDetails[0]?.description}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box sx={{ marginTop: '10px', display: 'flex', gap: '14px', alignItems: 'flex-end' }}>
                <Box sx={{ flexGrow: 2 }}>
                  <InputLabel
                    htmlFor="couponCode"
                    style={{ ...labelStyle, color: colors.white, textAlign: 'left' }}
                  >
                    {t('wallet.depositPage.couponLabel')}
                  </InputLabel>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="couponCode"
                    name="couponCode"
                    color="info"
                    placeholder={t('wallet.depositPage.couponPlaceholder') as string}
                    value={selectedCouponCode || ''}
                    onChange={handleCouponCodeChange}
                    // error={!isCouponValid()}
                    // helperText={!isCouponValid() ? t('wallet.depositPage.couponHelperText') : ''}
                    // FormHelperTextProps={{
                    //   sx: { fontSize: '14px', lineHeight: '20px', color: colors.grey.placeholder }
                    // }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ConfirmationNumber
                            sx={{ color: selectedCouponCode ? colors.info.main : colors.white }}
                          />
                        </InputAdornment>
                      )
                    }}
                    sx={{
                      marginBottom: 0,
                      '& .MuiOutlinedInput-root': {
                        height: '46px',
                        backgroundColor: selectedCouponCode ? colors.white : colors.background.grey,
                        '& fieldset': {
                          borderColor: getBorderColor(selectedCouponCode)
                        }
                      }
                    }}
                  />
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleCouponButtonClicked}
                  sx={{
                    width: '65px',
                    height: '46px',
                    background: colors.gradients.yellow,
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    '&:hover': {
                      background: (t) => t.palette.common.white,
                      boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
                    },
                    color: 'primary.main'
                  }}
                >
                  {selectedCouponCode.length > 0 ? 'apply' : 'skip'}
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 4} onChange={handleChange(4)} sx={accordionProps}>
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharp sx={dropDownIconProps} />}
              aria-controls={`panel-payment-summary-content`}
              id={`panel-payment-summary-header`}
              sx={getAccordionSummaryProps(4, expanded)}
            >
              <Box sx={titleLabelProps}>
                <Box sx={getStepStyles(4, expanded)}>5</Box>
                {t('wallet.depositPage.paymentSummary')}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ ...accordionDetailsProps, textAlign: 'left' }}>
              <Divider style={{ background: 'white', marginTop: '-8px', marginBottom: 12 }} />
              {/* <Box sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}>
                {selectedPayment?.name}
              </Box> */}
              <Box sx={summaryTitleProps}>
                <Box sx={summaryTitleLeftSideProps}>{t('wallet.depositPage.yourDeposit')}</Box>
                <Box sx={summaryTitleRightSideProps}>
                  {userCurrency}
                  {selectedAmount}
                </Box>
              </Box>
              {selectedCouponCode.length > 0 && (
                <Box sx={summaryTitleProps}>
                  <Box sx={summaryTitleLeftSideProps}>
                    {`${t('wallet.depositPage.yourCoupon')}: ${selectedCouponCode}`}
                  </Box>
                  <Box sx={summaryTitleRightSideProps}>
                    {userCurrency}
                    {getBonusAmount()}
                  </Box>
                </Box>
              )}
              {selectedPayment?.fee && (
                <Box sx={summaryTitleProps}>
                  <Box sx={summaryTitleLeftSideProps}>{t('wallet.depositPage.fee')}</Box>
                  <Box sx={summaryTitleRightSideProps}>
                    {userCurrency}
                    {selectedPayment.fee}
                  </Box>
                </Box>
              )}
              <Divider style={{ background: 'white', marginTop: '16px' }} />
              <Box sx={summaryTitleProps}>
                <Box
                  sx={{ ...summaryTitleLeftSideProps, fontWeight: 600, letterSpacing: '0.05em' }}
                >
                  {t('wallet.depositPage.total')}
                </Box>
                <Box
                  sx={{
                    ...summaryTitleRightSideProps,
                    fontSize: '24px',
                    fontWeight: 600,
                    lineHeight: '34px'
                  }}
                >
                  {userCurrency}
                  {getDepositTotal()}
                </Box>
              </Box>
              <Box sx={summaryTitleProps}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={selectedPayment === null || !isAmountValid()}
                  onClick={() => makeDeposit()}
                  sx={{
                    background: colors.gradients.yellow,
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    '&:hover': {
                      background: (t) => t.palette.common.white,
                      boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
                    },
                    color: 'primary.main'
                  }}
                >
                  {t('wallet.depositPage.makeDeposit')}
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </>
      )}
      <Loader loading={isLoading || bonusDetails.isLoading} />
      {openPaymentModal && <PaymentModal url={paymentUrl} fullscreen={isMobile} />}
      {openCardForm && (
        <CardForm
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          cvv={cvv}
          setCvv={setCvv}
          onClose={() => setOpenCardForm(false)}
          submit={submitDeposit}
          cardHolder={cardHolderName}
          setHolder={setCardHolderName}
          expiration={expiration}
          setExpiration={setExpiration}
          paymentProvider={selectedPayment?.name as string}
        />
      )}
    </Box>
  );
};

export default DepositPage;
